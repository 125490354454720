export interface PostalCodeSelectorConfig {
  narrowFields?: boolean;
}

/** 
 * Model postal-code-selector
 */
class PostalCodeSelectorModel {
  public zipCode: string = '';

  public city: string = '';

  public provinceCode: string = '';

  public province: string = '';
}

export default PostalCodeSelectorModel;
