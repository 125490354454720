import {
  FeatureControl
} from '../features/featureControl';

/**
 * Returns the cookies id for the current branding
 * @returns {string | undefined}
 */
export default function getCoverageInitialDefaultDateOffset(): number | undefined {
  return FeatureControl.getBrandingSettings
    .bind(FeatureControl)
    .call(FeatureControl, 'coverageDefaultInitialDateOffset') as number | undefined;
}
