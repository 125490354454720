/**
 * Returns a given default value for the proposed argument
 * Note: This is supposed to be a patch to replace ?? temporarily until we migrate to the new arch version
 * @param {T} proposedVal
 * @param {T} defaultVal
 * @returns {T}
 */
export default function getDefaultValue<T>(
  proposedVal: T | undefined | null,
  defaultVal: T
): T {
  if (proposedVal !== null && proposedVal !== undefined) {
    return proposedVal;
  }
  return defaultVal;
}
