/* eslint-disable max-lines */
import {
  BrandingOptions
} from '@/locales/branding';

export interface CoverSummaryItem {
  text: string;
  value: string | CoverValues;
  franchiseValue?: string;
  hintText?: string;
  coverElements?: CoverElement[];
  styleMetadata?: {[key: string]: boolean | undefined};
}
export interface CoverSummaryBlock {
  title: string;
  covers: CoverSummaryItem[];
}
export interface CoversSummaryData {
  title: string;
  code: string;
  blocks: CoverSummaryBlock[];
}

export interface CoverDynamicTitle {
  mctCode: string;
  propertyName: string;
  possibleValues: [string, string][]; //[WSoption, Returned value]
}

export enum CoverStructureType {
  Amount = 'amount', // Given amount by ws (web service)
  CorpTableAmount = 'corpTableAmount', // Consult the amount to a corporateTable 
  CorpTableValue = 'corpTableValue', // Consult the literal value to a corporateTable
  Included = 'included', // 'Included' fixed value if given code
  Fixed = 'fixed', // Fixed value if given code
  AlwaysFixed = 'alwaysFixed', // ALWAYS show cover
  ConditionFixed = 'conditionFixed', // Fixed value by explicit condition
  Condition = 'condition', // Fully conditioned
  SpecificFranchiseValue = 'specificFranchiseValue' //Only shows a special franchise value
}

export enum CoverElementCondition {
  ContentCapital = 'contentCapital',
  ContinentCapital = 'continentCapital',
  CorporateTableValue = 'corpTableVal',
  WSValue = 'wsValue'
}

export enum ReturnedValueSource {
  directWS = 'directWS',
  corpTable = 'corpTable',
  revealExistance = 'revealExistance'
}
export interface FixedCoverElements {
  name: string | CoverDynamicTitle;
  fixedContinentValue: boolean;
  fixedContentValue: boolean;
  conditionDependant?: CoverElementCondition;
  returnedValue?: [string, string, ReturnedValueSource]; //[MCT code, property name, obtain value from WS]
  fixedValue?: number | string;
  containedInTwoColumns?: boolean;
  WSPropName?: [string, string];
}

export interface CoverElement {
  name: string;
  contentValue: string | number | null;
  continentValue: string | number | null;
  hintText?: string;
  styleMetadata?: {[key: string]: boolean | undefined};
}

export interface CoverValues {
  content?: CoverValueTypes | string;
  continent?: CoverValueTypes | string;
}
export interface CoversDataItem {
  id: string;
  type: CoverStructureType;
  code: string | string[];
  // Value: string;
  value: CoverValues | string;
  WSProperty?: string;
  corporateTable?: string;
  fixedElements?: FixedCoverElements[];
  brandings?: BrandingOptions[]; // If informed only apply to indicated brandings. If not, apply to all brandings
  specialFormatting?: string;
  visibilityCondition?: VisibilityCondition;
  containedInTwoColumns?: boolean;
  specialFranchiseValue?: [string, string];
}

/*
 * NOTE: If 'value' property starts with '$t:' means that must be translated when displayed to the user
 * In practice, only apply to fixed types: Fixed, AlwaysFixed and ConditionFixed
 */

export enum VisibilityCondition {
  AddonWorkFromHome = 'addOnWorkFromHome',
  AddonDuo = 'addonDuo',
  AddonDelivery = 'addonDelivery'
}
export interface CoversDataStructure {
  title: string;
  visibilityCondition?: VisibilityCondition;
  covers: CoversDataItem[];
}

export interface CorpTableFieldsRelationship {
  codeField: string;
  valueField: string;
  CDDIVISA: string;
}

export enum CoverValueTypes {
  FixedContentCapitalValue = 'FixedContentCapitalValue',
  FixedContinentCapitalValue = 'FixedContinentCapitalValue',
  WSPropertyValue = 'wsPropertyValue',
  IncludedAddon = 'IcludedAddon'
}

export const CorpTableRelationships: { [key: string]: CorpTableFieldsRelationship } = {
  KT5F31S: {
    codeField: 'CDLIMRCG',
    valueField: 'DSDESCR1',
    CDDIVISA: 'EUR'
  },
  KT0106S: {
    codeField: 'CDFRANQU',
    valueField: 'DSDESCR2',
    CDDIVISA: 'EUR'
  }
};

export const availableCoverElements: string[] = [
  'CDLIVIPA'
];

export const CoversShopsStructure: CoversDataStructure[] = [
  { // Coberturas principales
    title: 'getPrice.coversSummaryStep.coversSummary.mainCovers',
    covers: [
      {
        id: 'shops-incendio',
        type: CoverStructureType.Fixed,
        code: ['0000000607', '0000000608'],
        fixedElements: [
          {
            name: 'fire',
            fixedContentValue: false,
            fixedContinentValue: false
          },
          {
            name: 'explosion',
            fixedContentValue: false,
            fixedContinentValue: false
          },
          {
            name: 'thunder',
            fixedContentValue: false,
            fixedContinentValue: false
          }
        ],
        value: {
          content: CoverValueTypes.FixedContentCapitalValue,
          continent: CoverValueTypes.FixedContinentCapitalValue
        }
      },
      {
        id: 'shops-extension-garantias',
        type: CoverStructureType.Fixed,
        code: ['0000000607', '0000000608'],
        fixedElements: [
          {
            name: 'smoke',
            fixedContentValue: false,
            fixedContinentValue: false
          },
          {
            name: 'weather',
            fixedContentValue: false,
            fixedContinentValue: false
          },
          {
            name: 'vandalism',
            fixedContentValue: false,
            fixedContinentValue: false
          },
          {
            name: 'floods',
            fixedContentValue: false,
            fixedContinentValue: false
          },
          {
            name: 'crash',
            fixedContentValue: false,
            fixedContinentValue: false
          }
        ],
        value: {
          content: CoverValueTypes.FixedContentCapitalValue,
          continent: CoverValueTypes.FixedContinentCapitalValue
        }
      },
      {
        id: 'shops-ruina-total',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          content: CoverValueTypes.FixedContentCapitalValue,
          continent: CoverValueTypes.FixedContinentCapitalValue
        }
      },
      {
        id: 'shops-danyos-agua',
        type: CoverStructureType.Fixed,
        code: '0000000609',
        value: {
          content: CoverValueTypes.FixedContentCapitalValue,
          continent: CoverValueTypes.FixedContinentCapitalValue
        },
        fixedElements: [
          {
            name: 'continent-water-damages',
            fixedContentValue: false,
            fixedContinentValue: false,
            conditionDependant: CoverElementCondition.ContinentCapital
          },
          {
            name: 'content-water-damages',
            fixedContentValue: false,
            fixedContinentValue: false,
            conditionDependant: CoverElementCondition.ContentCapital
          }
        ]
      }, {
        id: 'shops-danyos-esteticos',
        type: CoverStructureType.Fixed,
        code: '0000000610',
        WSProperty: 'CPASEGUR',
        value: {
          content: '',
          continent: CoverValueTypes.WSPropertyValue
        }
      }, {
        id: 'shops-rotura-cristales',
        type: CoverStructureType.Fixed,
        code: '0000000611',
        value: {
          continent: CoverValueTypes.FixedContinentCapitalValue,
          content:  CoverValueTypes.FixedContentCapitalValue
        },
      }, {
        id: 'shops-danyos-electricos',
        type: CoverStructureType.Fixed,
        code: '0000000617',
        value: {
          content: CoverValueTypes.FixedContentCapitalValue,
          continent: CoverValueTypes.FixedContinentCapitalValue
        }
      },
      {
        id: 'shops-coberturas-interesantes',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          content: '',
          continent: ''
        },
        fixedElements: [
          {
            name: 'ensured-goods-for-personal-use',
            fixedValue: 500,
            fixedContentValue: true,
            fixedContinentValue: false,
          },
          {
            name: 'temporarily-moved-goods',
            fixedContentValue: true,
            fixedContinentValue: false,
          },
          {
            name: 'content-in-terrace',
            conditionDependant: CoverElementCondition.WSValue,
            returnedValue: ['0000000608', 'CDJARTER', ReturnedValueSource.revealExistance],
            fixedValue: 3000,
            fixedContentValue: true,
            fixedContinentValue: false,
          },
          {
            name: 'file-reposition',
            conditionDependant: CoverElementCondition.CorporateTableValue,
            returnedValue: ['0000000608', 'CDREPARC', ReturnedValueSource.revealExistance],
            fixedValue: '10%',
            fixedContentValue: false,
            fixedContinentValue: false,
          },
          {
            name: 'rescue-crew',
            fixedContentValue: true,
            fixedContinentValue: true,
          },
          {
            name: 'forced-eviction',
            fixedValue: '10% max 60.000 €',
            fixedContentValue: true,
            fixedContinentValue: false,
            containedInTwoColumns: true
          },
          {
            name: 'fire-system-scape',
            fixedContentValue: true,
            fixedContinentValue: true,
          },
          {
            name: 'capital-compensation',
            fixedContentValue: true,
            fixedContinentValue: true,
          }
        ]
      }
    ]
  },
  {
    title: 'Paquete de Robo',
    covers: [
      {
        id: 'shops-robo',
        type: CoverStructureType.Fixed,
        code: '0000000613',
        value: {
          content: CoverValueTypes.FixedContentCapitalValue,
          continent: ''
        },
        fixedElements: [
          {
            name: 'money-robbery-in-metalic',
            conditionDependant: CoverElementCondition.WSValue,
            returnedValue: ['0000000613', 'IMMETMUE', ReturnedValueSource.directWS],
            fixedContentValue: true,
            fixedContinentValue: false
          },
          {
            name: 'money-expoliation-in-metalic',
            conditionDependant: CoverElementCondition.WSValue,
            returnedValue: ['0000000613', 'IMMETEXP', ReturnedValueSource.directWS],
            fixedContentValue: true,
            fixedContinentValue: false
          },
          {
            name: 'clients-expoliation',
            conditionDependant: CoverElementCondition.WSValue,
            returnedValue: ['0000000613', 'IMPINFE', ReturnedValueSource.revealExistance],
            fixedValue: '300 €/persona',
            fixedContentValue: true,
            fixedContinentValue: false,
            containedInTwoColumns: true
          },
          {
            name: 'keys-and-locks-reposition',
            fixedValue: 600,
            fixedContentValue: true,
            fixedContinentValue: false
          },
          {
            name: 'vigilance-expenses',
            fixedValue: 3000,
            fixedContentValue: true,
            fixedContinentValue: false
          }
        ]
      },
      {
        id: 'shops-continent-malfunction-robbery',
        type: CoverStructureType.Fixed,
        code: '0000000723',
        WSProperty: 'CPASEGUR',
        value: {
          content: '',
          continent: CoverValueTypes.WSPropertyValue
        }
      }
    ]
  },
  {
    title: 'Paquete de responsabilidades',
    covers: [
      {
        id: 'shops-continent-rc',
        code: '0000003047',
        type: CoverStructureType.CorpTableAmount,
        value: 'CDLIMRCI',
        corporateTable: 'KT5F31S',
        specialFormatting: 'por siniestro y año',
        containedInTwoColumns: true,
      },
      {
        id: 'shops-rc-patronal',
        type: CoverStructureType.CorpTableAmount,
        code: '0000003045',
        value: 'CDLRCPAT',
        corporateTable: 'KT5F31S',
        specialFormatting: 'por siniestro y año',
        containedInTwoColumns: true,
        fixedElements: [
          {
            name: 'patronal-victim-limit',
            conditionDependant: CoverElementCondition.CorporateTableValue,
            WSPropName: ['0000003045', 'CDLIVIPA'],
            returnedValue: ['KT5F31S', 'CDLIMRCG', ReturnedValueSource.corpTable],
            fixedContentValue: true,
            fixedContinentValue: false,
            containedInTwoColumns: true
          }
        ]
      },
      {
        id: 'shops-rc-explotation',
        type: CoverStructureType.CorpTableAmount,
        code: '0000003045',
        value: 'CDLRCPAT',
        corporateTable: 'KT5F31S',
        specialFormatting: 'por siniestro y año',
        containedInTwoColumns: true,
      },
      {
        id: 'shops-rc-private-duo',
        type: CoverStructureType.CorpTableAmount,
        visibilityCondition: VisibilityCondition.AddonDuo,
        code: '0000003045',
        value: 'CDLRCPAT',
        corporateTable: 'KT5F31S',
        specialFormatting: 'por siniestro y año',
        containedInTwoColumns: true,
      },
      {
        id: 'shops-rc-outside',
        code: '0000000612',
        type: CoverStructureType.SpecificFranchiseValue,
        specialFranchiseValue: ['INTRFULO', 'CDFRQTRF'],
        value: {
          content: '',
          continent: '',
        }
      }
    ]
  },
  {
    title: 'Paquete de Averías',
    covers: [
      {
        id: 'shops-broken-computers',
        type: CoverStructureType.Fixed,
        code: '0000000721',
        value: {
          content: '',
          continent: ''
        },
        fixedElements: [
          {
            name: {
              mctCode: '0000000721',
              propertyName: 'TIFORASG',
              possibleValues: [['B', 'at-first-risk'], ['1', 'at-total-value']]
            },
            fixedContentValue: true,
            fixedContinentValue: false,
            conditionDependant: CoverElementCondition.WSValue,
            returnedValue: ['0000000721', 'CPASEGUR', ReturnedValueSource.directWS],
          },
          {
            name: 'franchise',
            fixedContentValue: true,
            fixedContinentValue: false,
            containedInTwoColumns: true,
            conditionDependant: CoverElementCondition.CorporateTableValue,
            WSPropName: ['0000000721', 'CDFRANQA'],
            returnedValue: ['KT0106S', 'CDFRANQU', ReturnedValueSource.corpTable],
          }
        ]
      },
    ],
  },
  {
    title: 'Teletrabajo',
    visibilityCondition: VisibilityCondition.AddonWorkFromHome,
    covers: [
      {
        id: 'shops-ofimatic',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-rc-patronal-work-from-home',
        type: CoverStructureType.AlwaysFixed,
        code: '0000003045',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-document-reposition',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      }
    ]
  },
  {
    title: 'Dúo (Comercio + Hogar)',
    visibilityCondition: VisibilityCondition.AddonDuo,
    covers: [
      {
        id: 'shops-rc-private',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-street-robery',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-personal-items',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      }
    ]
  },
  {
    title: 'Servicio a domicilio',
    visibilityCondition: VisibilityCondition.AddonDelivery,
    covers: [
      {
        id: 'shops-material-damage-and-personal',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-robery-to-delivery',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      }
    ]
  },
  {
    title: 'Coberturas y servicios adicionales',
    covers: [
      {
        id: 'shops-defensa-juridica',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-24h-assitence',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-ensured-ensured-return-grave',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        value: {
          continent: CoverValueTypes.IncludedAddon,
          content: CoverValueTypes.IncludedAddon
        }
      },
      {
        id: 'shops-free-tech-support',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        brandings: [BrandingOptions.Orange],
        value: {
          continent: '',
          content: ''
        }
      },
      {
        id: 'shops-manitas-service',
        type: CoverStructureType.AlwaysFixed,
        code: '',
        brandings: [BrandingOptions.Orange],
        value: {
          continent: '',
          content: ''
        }
      }
    ]
  }
];

export const CoversAccidentsStructure: CoversDataStructure[] = [
  {
    title: 'getPrice.coversSummaryStep.coversSummary.mainCovers',
    covers: [
      {
        id: 'accidents-muerte-accidente',
        type: CoverStructureType.Amount,
        code: '0000000683',
        value: 'CPASEGUR'
      }, {
        id: 'accidents-muerte-accidente-circulacion',
        type: CoverStructureType.Amount,
        code: '0000000685',
        value: 'CPASEGUR'
      }, {
        id: 'accidents-incap-perm-accidente',
        type: CoverStructureType.Amount,
        code: '0000000688',
        value: 'CPASEGUR'
      }, {
        id: 'accidents-incap-perm-accidente-circulacion',
        type: CoverStructureType.Amount,
        code: '0000000692',
        value: 'CPASEGUR'
      }, {
        id: 'accidents-incap-total-profesion-habitual',
        type: CoverStructureType.Amount,
        code: '0000000689',
        value: 'CPASEGUR'
      }, {
        id: 'accidents-incap-absoluta',
        type: CoverStructureType.Amount,
        code: '0000000694',
        value: 'CPASEGUR'
      }, {
        id: 'accidents-gran-invalidez-accidente',
        type: CoverStructureType.Amount,
        code: '0000000696',
        value: 'CPASEGUR'
      }
    ]
  }
];

export const CoversShopsFranchisesStructure: CoversDataItem[] = [
  {
    id: 'shops-danyos-agua',
    type: CoverStructureType.CorpTableAmount,
    code: '0000000609',
    value: 'CDFRANQA',
    corporateTable: 'KT0106S'
  }, {
    id: 'shops-danyos-electricos',
    type: CoverStructureType.CorpTableValue,
    code: '0000000617',
    value: 'CDFRANQA',
    corporateTable: 'KT0106S'
  }, {
    id: 'shops-rotura-cristales',
    type: CoverStructureType.CorpTableAmount,
    code: '0000000611',
    value: 'CDFRANQA',
    corporateTable: 'KT0106S'
  }, {
    id: 'shops-robo',
    type: CoverStructureType.CorpTableAmount,
    code: '0000000613',
    value: 'CDFRANQA',
    corporateTable: 'KT0106S'
  }, {
    id: 'shops-continent-malfunction-robbery',
    type: CoverStructureType.CorpTableAmount,
    code: '0000000723',
    value: 'CDFRANQA',
    corporateTable: 'KT0106S'
  }
];
