import {
  GetPriceModelProperties,
  InputSizes,
  OptionalCRMProperties
} from '@/flows/flows-views/get-price/get-price-model';
import {
  BusinessActivities,
  ProductCode, ProductId, ProductOptionConditionType, ZeVersion
} from '@/utils/enums';
import {
  BrandingConfig
} from '@/utils/interfaces';

export const defaultNaturalOpticsBrandingConfig: BrandingConfig = {
  cookies: '09fb6c92-0b10-4e5f-8913-1d3a428eb476',
  phoneNumbers: {
    contactCenter: '973 72 70 72',
  },
  logos: {
    primary: {
      src: require('../../../assets/images/naturaloptics/logo-zurich.svg'),
      className: '',
      removeDivider: false,
    },
    secondary: {
      src: require('../../../assets/images/naturaloptics/logo-naturaloptics.svg'),
      className: '',
      removeDivider: true,
    },
  },
  links: {
    mainSite: 'https://www.naturaloptics.com/',
    cookiesPolicy: 'https://zurichempresas.es/politica-de-cookies',
    privacyPolicy: 'https://zurichempresas.es/politica-de-privacidad',
    hiringPolicy: 'https://zurichempresas.es/politica-de-contratacion',
    informativeNote: '',
  },
  infoDetails: {
    specificalBusinessActivity: BusinessActivities.OpticaYProtesis
  },
  coverageDefaultInitialDateOffset: 3,
  //Products that can exist in the app
  products: [
    {
      id: ProductId.Commerce,
      code: ProductCode.Commerce,
      options: {
        productCard: {
          partVisible: 'both',
        },
        isRecommended: {
          condition: [
            {
              conditionType: ProductOptionConditionType.GuideMe,
            },
          ],
        },
        showPrice: {
          condition: [
            {
              conditionType: ProductOptionConditionType.CompetenceManagement,
              activityCode: '0202450', //Activity Code
            },
          ],
        },
      },
    },
  ],
  flow: {
    views: {
      productSelection:{
        viewVersion: ZeVersion.Default,
      },
      billingAndEmployees: {
        viewVersion: {
          version: ZeVersion.Alpha,
          enabledByProduct: [ProductId.RC, ProductId.RcPro],
        },
      },
      selectedProducts: {
        sections: {
          heroSection: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                  showProductDetailsButton: {
                    enabled: false,
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          selectedProductsCoverages: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce],
          },
          tempImgSection: {
            enabled: false,
          },
          modifyCapitals: {
            enabled: true,
          },
          additionalProducts: {
            enabled: true,
          },
          faqs: {
            enabled: true,
          },
          meetinglawyersPromotion: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce]
          },
        },
        features:{
          hasToWaitForDocumentationCreation: {
            enabled: false
          }
        }
      },
      emailAndPhone: {
        sections: {
          meetinglawyersPromotion: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce]
          },
          header: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
            components: {
              customHeader: {
                features: {
                  showReturnButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
                  }
                }
              }
            }
          }
        },
        features: {
          isPhoneEditable: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          showLoadingSpinner: {
            enabled: true
          },
          doCreateModificationsArray: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          doCRMRequest: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          waitForCompleteQueue: {
            enabled: false
          }
        }
      },
      coversSummary: {
        viewVersion: {
          version: ZeVersion.Beta,
          enabledByProduct: [ProductId.RC, ProductId.RcPro],
        },
        sections: {
          priceInfo: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                  showProductDetailsButton: {
                    enabled: false,
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              },
              selectedCoverageCardsSection: {
                constants: {
                  forceShowPriceWrapper: false,
                  forceShowProductsTitle: false
                }
              }
            }
          },
          coveragesTable: {
            enabled: false,
          },
          productsCards: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce],
          },
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [],
          },
          documentationCard: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
          },
        },
        features: {
          showNotaMediador: {
            enabled: false,
          },
        },
        constants: {
          checkItemDocumentation: false
        }
      },
      onlinePaymentUserData: {
        viewVersion: {
          version: ZeVersion.Alpha,
          enabledByProduct: [ProductId.RC, ProductId.RcPro, ProductId.Commerce],
        },
        sections: {
          personTypeSelection: {
            enabled: false,
          },
          userDataForm: {
            enabled: true,
            components: {
              companyDataCapture: {
                constants: {
                  showManagerFirstName: true,
                  showManagerSurname: true
                }
              }
            }
          },
          budgetAlert: {
            enabled: true,
            enabledByProduct: [ProductId.RC, ProductId.RcPro],
          },
          documentationRowSection: {
            enabled: true,
            enabledByProduct: [ProductId.RC, ProductId.RcPro],
          },
        },
        constants: {
          preventRequotePriceOnTransition: true
        }
      },
      holderAndRiskResume:{
        constants:{
          valuesToPersistInModel: [
            GetPriceModelProperties.Name,
            GetPriceModelProperties.Email
          ],
          onlyCommerceProductBranding: true
        },
        sections:{
          businessInformation: {
            enabled: true,
            enabledByProduct: [
              ProductId.RC,
              ProductId.RcPro,
              ProductId.Commerce
            ],
            components: {
              form: {
                constants: {
                  customBusinessActivity: true
                }
              }
            },
          },
          buildingInformation: {
            enabled: true,
            enabledByProduct: [
              ProductId.Commerce
            ],
            components: {
              form: {
                constants: {
                  buildingFieldsSize: InputSizes.Medium
                }
              }
            }
          },
          risksAndSafetyMeasures: {
            enabled: true,
            enabledByProduct: [
              ProductId.Commerce
            ],
            components: {}
          }
        }
      },
      finishedOnlinePayment: {
        sections: {
          emissionMainMessage: {
            enabled: true,
            version: ZeVersion.Alpha,
          },
          policyNumberCopy: {
            enabled: true,
            version: ZeVersion.Default,
          },
          hiredProducts: {
            enabled: false,
            version: ZeVersion.Default,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                  showProductDetailsButton: {
                    enabled: false,
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          productPromotion: {
            enabled: true,
            version: ZeVersion.Default,
          },
          buttonsSection: {
            enabled: true,
            version: ZeVersion.Alpha,
          },
        },
        constants:{
          optionalCRMProperties:[
            OptionalCRMProperties.Holder
          ],
          generateUrlLocationParams: false
        }
      },
      businessActivitySelection:{
        viewVersion: {
          version: ZeVersion.Default,
        },
        sections: {
          cardsSelector: {
            enabled: false,
            version: ZeVersion.Default,
          }
        }
      },
      telephonePaymentConfirmation: {
        constants: {
          hideAvatar: false,
          showGoToHomesiteButton: true
        },
        features: {
          redirectWithParams: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          }
        }
      },
      preparingPrice: {
        constants: {
          hideAvatar: false
        },
        features: {
          checkCompetenceManagement: {
            enabled: false
          }
        }
      }
    },
    paymentType: {
      telephone: {
        enabled: false,
      },
      online: {
        enabled: true,
      },
    },
    settings: {
      callMeBack: {
        buttons: {
          hideButtonCallMe: true
        }
      }
    }
  },
};
