import getCookiesId from '@/helpers/feature-control/methods/getCookiesId';

/**
 * Creates a script tag on the document, importing the cookies consent widget script,
 * depending on branding
 *
 * @export
 */
export function importCookiesScript(): void {
  const id = getCookiesId();
  if (id) {
    const scriptElem = document.createElement('script');
    scriptElem.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    scriptElem.type = 'text/javascript';
    scriptElem.setAttribute('data-domain-script', id);
    document.head.insertBefore(scriptElem, document.head.firstChild);
  }
}
