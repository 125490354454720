import {
  steps, StepTransition
} from './stepsConfig';


const naturalOpticsProdNavigationTransitions: StepTransition<typeof steps>[] = [
  //App policies
  {
    name: 'AppPolicies',
    from: '*',
    to: 'AppPolicies'
  },
  //Welcome Step
  {
    name: 'Welcome',
    from: 'init',
    to: 'PostalAddress',
    option: 1
  },
  //PostalAddress Step
  {
    name: 'PostalAddress',
    from: 'Welcome',
    to: 'PersonTypeSelection'
  },
  //PersonTypeSelection Step
  {
    name: 'PersonTypeSelection',
    from: 'PostalAddress',
    to: 'LocalOwnership',
  },
  //LocalOwnership Step
  {
    name: 'LocalOwnership',
    from: 'PersonTypeSelection',
    to: 'BillingAndEmployees',
    option: 1
  },
  {
    name: 'LocalOwnership',
    from: 'PersonTypeSelection',
    to: 'PartInsured',
    option: 2
  },
  //Partinsured Step
  {
    name: 'PartInsured',
    from: 'LocalOwnership',
    to: 'PropertyReform',
    option: 1
  },
  {
    name: 'PartInsured',
    from: 'LocalOwnership',
    to: 'BillingAndEmployees',
    option: 2
  },
  //BillingAndEmployees Step
  {
    name: 'BillingAndEmployees',
    from: 'LocalOwnership',
    to: 'BuildingDetails'
  },
  //PropertyReform Step
  {
    name: 'PropertyReform',
    from: 'PartInsured',
    to: 'BillingAndEmployees'
  },
  //Building Details Step
  {
    name: 'BuildingDetails',
    from: 'BillingAndEmployees',
    to: 'BuildingType'
  },
  //Building Type Step
  {
    name: 'BuildingType',
    from: 'BuildingDetails',
    to: 'Factors'
  },
  //Windows type Step
  {
    name: 'BusinessWindows',
    from: 'Factors',
    to: 'SafetyMeasures'
  },
  //Factors Step
  {
    name: 'Factors',
    from: 'BuildingType', //Multiple options
    to: 'LocalMaxCapacity',
    option: 1
  },
  {
    name: 'Factors',
    from: 'BuildingType', //Multiple options
    to: 'SafetyMeasures',
    option: 2
  },
  {
    name: 'Factors',
    from: 'BuildingType', //Multiple options
    to: 'BusinessWindows',
    option: 3
  },
  //Safety Measures Step
  {
    name: 'SafetyMeasures',
    from: 'BusinessWindows', //Multiple options
    to: 'HolderAndRisksResume',
  },
  //Holder Of Risk Resume
  {
    name: 'HolderAndRisksResume',
    from: 'SafetyMeasures',
    to: 'PreparingPrice',
    option: 1
  },
  {
    name: 'HolderAndRisksResume',
    from: 'SafetyMeasures',
    to: 'PostalAddress',
    option: 2
  },
  //Email and phone Step
  {
    name: 'EmailAndPhone',
    from: 'HolderAndRisksResume',
    to: 'CoversSummary',
    option: 1
  },
  {
    name: 'EmailAndPhone',
    from: 'HolderAndRisksResume',
    to: 'SelectedProducts',
    option: 2
  },
  //Preparing price step
  {
    name: 'PreparingPrice',
    from: 'HolderAndRisksResume',
    to: 'SelectedProducts',
    option: 1
  },
  {
    name: 'PreparingPrice',
    from: 'HolderAndRisksResume',
    to: 'CallToComplete',
    option: 2
  },
  //Selected products Step
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'CoversSummary',
    option: 1
  },
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'EmailAndPhone',
    option: 2
  },
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'TelephonePaymentZurichCalls',
    option: 3
  },
  //Covers Summary Step
  {
    name: 'CoversSummary',
    from: 'SelectedProducts',
    to: 'TelephonePaymentChoose',
    option: 1
  },
  {
    name: 'CoversSummary',
    from: 'SelectedProducts',
    to: 'OnlinePaymentUserData',
    option: 2
  },
  {
    name: 'CoversSummary',
    from: 'SelectedProducts',
    to: 'SelectedProducts',
    option: 3
  },
  //User Payment Data Step
  {
    name: 'OnlinePaymentUserData',
    from: 'CoversSummary',
    to: 'FullPostallAddress',
    option: 1
  },
  {
    name: 'OnlinePaymentUserData',
    from: 'CoversSummary',
    to: 'CoversSummary',
    option: 2
  },
  //Full Postal Address Step
  {
    name: 'FullPostallAddress',
    from: 'OnlinePaymentUserData',
    to: 'BankingData',
    option: 1
  },
  {
    name: 'FullPostallAddress',
    from: 'OnlinePaymentUserData',
    to: 'OnlinePaymentUserData',
    option: 2
  },
  //Banking Data Step
  {
    name: 'BankingData',
    from: 'FullPostallAddress',
    to: 'PaymentProcessing',
    option: 1
  },
  {
    name: 'BankingData',
    from: 'FullPostallAddress',
    to: 'FullPostallAddress',
    option: 2
  },
  //Payment Processing Step
  {
    name: 'PaymentProcessing',
    from: 'BankingData',
    to: 'FinishedOnlinePayment',
    option: 1
  },
  {
    name: 'PaymentProcessing',
    from: 'BankingData',
    to: 'BankingData',
    option: 2
  },
  //Telephone Payment Choose Step
  {
    name: 'TelephonePaymentChoose',
    from: 'CoversSummary',
    to: 'TelephonePaymentClientCalls',
    option: 1
  },
  {
    name: 'TelephonePaymentChoose',
    from: 'CoversSummary',
    to: 'TelephonePaymentZurichCalls',
    option: 2
  },
  {
    name: 'TelephonePaymentChoose',
    from: 'CoversSummary',
    to: 'CoversSummary',
    option: 3
  },
  //Telephone Payment Client Calls Step
  {
    name: 'TelephonePaymentClientCalls',
    from: 'TelephonePaymentChoose',
    to: 'TelephonePaymentChoose'
  },
  //Telephone Payment Zurich calls Step
  {
    name: 'TelephonePaymentZurichCalls',
    from: 'TelephonePaymentChoose',
    to: 'TelephonePaymentConfirmation',
    option: 1
  },
  {
    name: 'TelephonePaymentZurichCalls',
    from: 'TelephonePaymentChoose',
    to: 'previous',
    option: 2
  },

  /*
   * ////////////////////////////
   * Components
   * ////////////////////////////
   */
  {
    name: 'CallmebackComponent',
    from: '*',
    to: 'TelephonePaymentZurichCalls'
  }
];

export default naturalOpticsProdNavigationTransitions;
