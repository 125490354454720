export interface DynamicFieldGeneratorConfig {
  min: number;
  max: number;
  type: 'text' | 'number' | 'currency' | 'textArea' ;
  label: string;
  title?: string;
  description?: string;
  inputMaxLength?: number;
}

export type DynamicFieldGeneratorFields = (string | number | null | undefined)[];

/** 
 * Model dynamic-field-generator
 */
class DynamicFieldGeneratorModel {

  public fields: {[key: string]: string | number | null} = {};

}

export default DynamicFieldGeneratorModel;
