import {
  FlowId,
  FeatureName
} from '@/utils/enums';
import {
  FeatureControlNavigation,
  FeatureControlOptions,
  FeatureControlVariants
} from '@/utils/interfaces';
import {
  AppFeatures,
  FeatureSettings,
  InstanceOptions,
  MultiOptionControlSettings
} from '../base';
import {
  StepsControl
} from '../navigation/steps';
import {
  InAppTransitions,
  steps
} from '../navigation/stepsConfig';
import {
  FeatureControlPriorities
} from './priority';


/**
 * Class in charge of controlling different in-app features.
 * It can enable/disable features, and also configure variants of said features.
 * Its capabilities are based on environment and branding
 * If an environment setting is missing, it will use prod settings by default
 * Features must be explicitly enabled otherwise they will remain disabled
 */
export class FeatureControl extends StepsControl {

  /**
   * //////////////////////////////////////////////
   * ///// Private Methods
   * //////////////////////////////////////////////
   */
  
  /**
   * Returns a feature current state, determined by its current settings
   * @param {FeatureName} feature
   * @returns {FeatureSettings | undefined}
   */
  private static getCurrentFeatureState(feature: FeatureName): FeatureSettings | undefined {
    const appFeatures = this.getCurrentOption<AppFeatures>(InstanceOptions.Settings);
    if (appFeatures && appFeatures.length > 0) {
      return (appFeatures[0] && appFeatures[0][feature]) || undefined;
    }
  }
  
  /**
   * //////////////////////////////////////////////
   * ///// Public Methods
   * //////////////////////////////////////////////
   */

  /**
   * Returns all the feature configs for the current branding in the current environment
   * @returns {any}
   */
  public static getAllSettings(): AppFeatures | undefined {
    const currentSettings = this.getCurrentOption<AppFeatures>(InstanceOptions.Settings);
    if (currentSettings && currentSettings.length > 0 && currentSettings[0]) {
      return currentSettings[0];
    }
    return undefined;
  }

  /**
   * Determines if a feature is enabled/disabled by returning a boolean
   * @param {FeatureName} feature
   * @returns {boolean}
   */
  public static isFeatureEnabled(feature: FeatureName): boolean {
    const currentFeatureState = this.getCurrentFeatureState(feature);
    return !!(currentFeatureState && currentFeatureState.enabled);
  }

  /**
   * Determines the active variant of a feature, if set up in the config
   * @param {FeatureName} feature
   * @returns {FeatureControlVariants | undefined}
   */
  public static getVariantConfig(feature: FeatureName): FeatureControlVariants | undefined {
    const currentFeatureState = this.getCurrentFeatureState(feature);
    if (currentFeatureState && currentFeatureState.variant) {
      return currentFeatureState.variant;
    }
    return undefined;
  }

  /**
   * Returns the current config of options for a given feature
   * @param {FeatureName} feature
   * @returns {FeatureControlOptions | undefined}
   */
  public static getFeatureOptions(feature: FeatureName): FeatureControlOptions | undefined {
    const currentFeatureState = this.getCurrentFeatureState(feature);
    if (currentFeatureState && currentFeatureState.options) {
      return currentFeatureState.options;
    }
    return undefined;
  }

  /**
   * Initializes the feature control instance settings
   * @param {FlowId} flowId
   * @param {EnvironmentBasedKeyValuePair<FeatureControlSettings>} settings
   * @param {FeatureControlPriorities} priorities
   * @param {StepTransition} transitions
   * @param {object} stepsList
   * @param {FeatureControlNavigation} flowNavigation
   * @param {{ value: boolean }} isTransitionLocked
   */
  public static init(
    flowId: FlowId,
    settings: MultiOptionControlSettings,
    priorities: FeatureControlPriorities,
    transitions: InAppTransitions,
    stepsList: typeof steps,
    flowNavigation: FeatureControlNavigation,
    isTransitionLocked: { value: boolean }
  ): void {
    this.loadSettings(flowId, settings, priorities, transitions, stepsList);
    this.loadNavigation(flowNavigation, isTransitionLocked);
  }
}

/**
 * //////////////////////////////////////////////
 * ///// Standalone Methods
 * //////////////////////////////////////////////
 */

//Navigation Methods

/**
 *  Executes the current step transition
 * @param {number} option
 */
export function doTransition(option?: number): void {
  //Bind the function call to the Feature control context (It gets lost on the view classes instances)
  FeatureControl.executeTransition
    .bind(FeatureControl)
    .call(FeatureControl, option);
}

/**
 * Executes the current previous step transition
 * @param {number} option
 */
export function doPreviousTransition(): void {
  //Bind the function call to the Feature control context (It gets lost on the view classes instances)
  FeatureControl.doPreviousTransition
    .bind(FeatureControl)
    .call(FeatureControl);
}

/**
 * Executes a transition to a step explicitly indicated to this method
 * @param {string} transitionName
 * @param {number} option
 */
export function doExplicitTransition(transitionName: keyof typeof steps, option?: number): void {
  //Bind the function call to the Feature control context (It gets lost on the view classes instances)
  FeatureControl.doExplicitTransition
    .bind(FeatureControl)
    .call(FeatureControl, transitionName, option);
}
