import {
  merge,
  cloneDeep
} from 'lodash';

import {
  FeatureControlPriorities
} from './features/priority';

import {
  AppEnvironments,
  getCurrentEnvironment
} from '../environment/environment';

import {
  BrandingOptions,
  BrandingLines,
  getCurrentAppliedBranding,
  getCurrentBrandingLineMembership
} from '@/locales/branding';
import {
  steps,
  InAppTransitions
} from './navigation/stepsConfig';
import {
  BrandingBasedValueKeyPair,
  ConfigurableUnit,
  FeatureControlOptions,
  FeatureControlVariants,
  InAppBrandingConfig,
} from '@/utils/interfaces';

import {
  FlowId,
  FeatureName
} from '@/utils/enums';

import {
  brandingConfig
} from './branding/config';

export enum InstanceOptions {
  Settings = 'settings',
  Transitions = 'transitions',
  BrandingSettings = 'brandingSettings'
}

export interface FeatureSettings {
  enabled: boolean;
  variant?: FeatureControlVariants;
  options?: FeatureControlOptions;
}

export type AppFeatures = {
  [K in FeatureName]?: FeatureSettings;
}

export type FeatureControlSettingsBase = BrandingBasedValueKeyPair<AppFeatures>

export type FeatureControlSettings = Partial<FeatureControlSettingsBase>;

export type MultiOptionControlSettings = ConfigurableUnit<AppFeatures>;

/**
 * Represents the class base for feature control
 */
export class FeatureControlBase {

  protected static _flowId?: FlowId;

  protected static _settings?: MultiOptionControlSettings;

  protected static _priorities?: FeatureControlPriorities;

  protected static _stepsList?: typeof steps;

  protected static _transitions?: InAppTransitions;

  protected static _brandingSettings?: InAppBrandingConfig = brandingConfig;

  protected static _instance: FeatureControlBase;

  
  /**
   * //////////////////////////////////////////////
   * ///// INSTANCE Getters and Setters
   * //////////////////////////////////////////////
   */

  /**
   * Check if the instance already exists, if it does return it, otherwise generate and return a new instance
   */
  public static get Instance(): FeatureControlBase {
    return this._instance || (this._instance = new FeatureControlBase());
  }

  /**
   * Returns the current flow id
   */
  protected static get flowId(): FlowId | undefined {
    return this._flowId;
  }

  /**
   * Sets the value of the current flow id
   * @param {FlowId} flowId
   */
  protected static set flowId(flowId: FlowId | undefined) {
    this._flowId = flowId;
  }

  /**
   * Returns the steps list
   */
  protected static get stepsList(): typeof steps | undefined {
    return this._stepsList;
  }
  
  /**
   * Sets the value of the steps list
   * @param {object} value
   */
  protected static set stepsList(value: typeof steps | undefined) {
    this._stepsList = value;
  }
    
  
  /**
   * Returns the transitions list
   */
  protected static get transitions(): InAppTransitions | undefined {
    return this._transitions;
  }
  
  /**
   * Sets the value of the transitions list
   * @param {Array} value
   */
  protected static set transitions(value: InAppTransitions | undefined) {
    this._transitions = value;
  }

  /**
   * Returns the current settings priorities of the app if available
   */
  protected static get priorities(): FeatureControlPriorities | undefined {
    return this._priorities || undefined;
  }

  /**
   * Sets the value of the current settings priorities
   * @param {FeatureControlPriorities} priorities
   */
  protected static set priorities(priorities: FeatureControlPriorities | undefined) {
    this._priorities = priorities;
  }

  /**
   * Returns a copy of the current settings of the app if available
   */
  protected static get settings(): MultiOptionControlSettings | undefined {
    return this._settings;
  }

  /**
   * Sets the value of the current settings of the app
   * @param {MultiOptionControlSettings} settings
   */
  protected static set settings(settings: MultiOptionControlSettings | undefined) {
    if (settings) {
      this._settings = JSON.parse(JSON.stringify(settings));
    } else {
      this.settings = undefined;
    }
  }

  /**
   * Returns the in-app branding settings
   */
  protected static get brandingSettings(): InAppBrandingConfig | undefined {
    return this._brandingSettings;
  }

  /**
   * Sets the value of the in-app branding settings
   * @param {InAppBrandingConfig} settings
   */
  protected static set brandingSettings(settings: InAppBrandingConfig | undefined) {
    this._brandingSettings = settings;
  }

  /**
   * //////////////////////////////////////////////
   * ///// Environment Related Getters
   * //////////////////////////////////////////////
   */
  /**
   * Returns the current environment
   */
  protected static get currentEnvironment(): AppEnvironments {
    return getCurrentEnvironment();
  }

  /**
   * //////////////////////////////////////////////
   * ///// Branding Related Getters
   * //////////////////////////////////////////////
   */

  /**
   * Returns the current branding
   */
  protected static get currentBranding(): BrandingOptions {
    return getCurrentAppliedBranding();
  }

  /**
   * Returns the current branding line
   */
  protected static get currentBrandingLine(): BrandingLines {
    return getCurrentBrandingLineMembership();
  }

  /**
   * //////////////////////////////////////////////
   * ///// Protected Methods
   * //////////////////////////////////////////////
   */

  /**
   * Returns a configurable option from the current environment and branding
   * @param {InstanceOptions} option
   * @returns {[object, string, string] | undefined} [foundOption, env, branding, optionName]
   */
  protected static getCurrentOption<T>(option: InstanceOptions): [T | undefined, string, string, string] | undefined {
    const currentOption = cloneDeep(this[option]) as ConfigurableUnit<T>;
    if (currentOption) {
      const currentEnvOption = cloneDeep(currentOption[this.currentEnvironment]);
      const defaultOption = currentOption.default;
      const current = merge(cloneDeep(defaultOption), currentEnvOption);

      if (current) {
        const currentBrandingOption = current[this.currentBranding];
        const currentBrandingLineOption = current[this.currentBrandingLine];
        const brandingIdentifier = currentBrandingOption ? this.currentBranding : this.currentBrandingLine;
        const envIdentifier = currentEnvOption ? this.currentEnvironment : 'default';
        const optionValue = currentBrandingOption || currentBrandingLineOption;
        return [optionValue, envIdentifier, brandingIdentifier, option];
      }
    }
    return undefined;
  }

  /**
   * //////////////////////////////////////////////
   * ///// Public Methods
   * //////////////////////////////////////////////
   */

  /**
   * Loads the passed app settings into the instance
   * @param {FlowId} flowId
   * @param {EnvironmentBasedKeyValuePair<FeatureControlSettings>} settings
   * @param {FeatureControlPriorities} priorities
   * @param {StepTransition} transitions
   * @param {object} stepsList
   */
  public static loadSettings(
    flowId: FlowId,
    settings: MultiOptionControlSettings,
    priorities: FeatureControlPriorities,
    transitions: InAppTransitions,
    stepsList: typeof steps
  ): void {
    this.flowId = flowId;
    this.settings = settings;
    this.priorities = priorities;
    this.transitions = transitions;
    this.stepsList = stepsList;
  }
}
