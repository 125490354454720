/* eslint-disable */

/**
 * zurich-empresas
 * Web cotización para empresas
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface QuoteAndBuyRequest
 */
export interface QuoteAndBuyRequest {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    partnerBranding?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    tipoOperacion?: QuoteAndBuyRequestTipoOperacionEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    codigoPolizaBase?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    tipoCanalCobroSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    tipoCanalCobro1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    formaPago?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    formaPagoSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    tipoProducto: QuoteAndBuyRequestTipoProductoEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequest
     */
    clavePaisTomador?: string;
    /**
     * 
     * @type {QuoteAndBuyRequestModificaciones}
     * @memberof QuoteAndBuyRequest
     */
    modificaciones?: QuoteAndBuyRequestModificaciones;
    /**
     * 
     * @type {QuoteAndBuyRequestElementosAdicionales}
     * @memberof QuoteAndBuyRequest
     */
    elementosAdicionales?: QuoteAndBuyRequestElementosAdicionales;
    /**
     * 
     * @type {QuoteAndBuyRequestElementosObjeto}
     * @memberof QuoteAndBuyRequest
     */
    elementosObjeto?: QuoteAndBuyRequestElementosObjeto;
}

/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestTipoOperacionEnum {
    Emision = 'Emision'
}
/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestTipoProductoEnum {
    Comercios = 'Comercios',
    RC = 'RC',
    RCProfesional = 'RCProfesional',
    Accidentes = 'Accidentes',
    RCDO = 'RCD&O',
    Cyber = 'Cyber'
}
/**
 * 
 * @export
 * @interface QuoteAndBuyRequestElementosAdicionales
 */
export interface QuoteAndBuyRequestElementosAdicionales {
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestElementosAdicionales
     */
    facturacionEmpresa?: number;
}/**
 * 
 * @export
 * @interface QuoteAndBuyRequestElementosObjeto
 */
export interface QuoteAndBuyRequestElementosObjeto {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    familiaComercios?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    actividadComercios?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    tipoViaSituacionDeRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    calleSituacionDeRiesgo?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    numeroDireccionSituacionDeRiesgo?: number;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    pisoPuertaSituacionDeRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    codigoPostalRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    nombreProvincia?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    pais?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    anoConstruccion?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    superficieLocal?: number;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    numeroEmpleados?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    propiedadLocal?: QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    tipoContinente?: QuoteAndBuyRequestElementosObjetoTipoContinenteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    tipoEdificio?: QuoteAndBuyRequestElementosObjetoTipoEdificioEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    ubicacionRiesgo?: QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    codigoActividad?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    capitalContinentePoliza?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    teletrabajoAddon?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    servicioAdomicilioAddon?: boolean;
    /**
     * 
     * @type {Array<QuoteAndBuyRequestElementosObjetoMedidasProteccion>}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    medidasProteccion?: Array<QuoteAndBuyRequestElementosObjetoMedidasProteccion>;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    aforo?: number;
    /**
     * 
     * @type {Array<QuoteAndBuyRequestElementosObjetoFactoresRiesgo>}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    factoresRiesgo?: Array<QuoteAndBuyRequestElementosObjetoFactoresRiesgo>;
    /**
     * 
     * @type {Array<QuoteAndBuyRequestElementosObjetoFactoresConIndicadores>}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    factoresConIndicadores?: Array<QuoteAndBuyRequestElementosObjetoFactoresConIndicadores>;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjeto
     */
    tipoGarantiasAseguradas?: QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum {
    Propietario = 'Propietario',
    Inquilino = 'Inquilino'
}
/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestElementosObjetoTipoContinenteEnum {
    Immueble = 'Immueble',
    Obras = 'Obras'
}
/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestElementosObjetoTipoEdificioEnum {
    Industrial = 'Industrial',
    Vivienda = 'Vivienda',
    MercadoPublico = 'MercadoPublico',
    Oficina = 'Oficina',
    ViviendaOficina = 'ViviendaOficina',
    CentroComercialCerrado = 'CentroComercialCerrado',
    CentroComercialAbierto = 'CentroComercialAbierto',
    CentroComercial = 'CentroComercial',
    Quiosco = 'Quiosco'
}
/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum {
    Altura = 'Altura',
    PlantaBaja = 'PlantaBaja',
    Sotano = 'Sotano'
}
/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum {
    Contenido = 'Contenido',
    Continente = 'Continente',
    Ambos = 'Ambos'
}
/**
 * 
 * @export
 * @interface QuoteAndBuyRequestElementosObjetoFactoresConIndicadores
 */
export interface QuoteAndBuyRequestElementosObjetoFactoresConIndicadores {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjetoFactoresConIndicadores
     */
    indicadoresFactores?: QuoteAndBuyRequestElementosObjetoFactoresConIndicadoresIndicadoresFactoresEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestElementosObjetoFactoresConIndicadoresIndicadoresFactoresEnum {
    GardenIndicator = 'GardenIndicator'
}
/**
 * 
 * @export
 * @interface QuoteAndBuyRequestElementosObjetoFactoresRiesgo
 */
export interface QuoteAndBuyRequestElementosObjetoFactoresRiesgo {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjetoFactoresRiesgo
     */
    numeroFactor?: string;
}/**
 * 
 * @export
 * @interface QuoteAndBuyRequestElementosObjetoMedidasProteccion
 */
export interface QuoteAndBuyRequestElementosObjetoMedidasProteccion {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestElementosObjetoMedidasProteccion
     */
    nombreMedidaProteccion?: string;
}/**
 * 
 * @export
 * @interface QuoteAndBuyRequestModificaciones
 */
export interface QuoteAndBuyRequestModificaciones {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificaciones
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestModificaciones
     */
    capitalContenidoPoliza?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyRequestModificaciones
     */
    capitalContinentePoliza?: number;
    /**
     * 
     * @type {Array<QuoteAndBuyRequestModificacionesDatosCliente>}
     * @memberof QuoteAndBuyRequestModificaciones
     */
    datosCliente?: Array<QuoteAndBuyRequestModificacionesDatosCliente>;
}/**
 * 
 * @export
 * @interface QuoteAndBuyRequestModificacionesDatosCliente
 */
export interface QuoteAndBuyRequestModificacionesDatosCliente {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    rolCliente?: QuoteAndBuyRequestModificacionesDatosClienteRolClienteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    tipoPersona?: QuoteAndBuyRequestModificacionesDatosClienteTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    tipoDocumentoAseguradoBeneficiario?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    emailContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    telefonoContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    nombreComercial?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyRequestModificacionesDatosCliente
     */
    codigoIBAN?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestModificacionesDatosClienteRolClienteEnum {
    Tomador = 'Tomador',
    Asegurado = 'Asegurado',
    Pagador = 'Pagador'
}
/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyRequestModificacionesDatosClienteTipoPersonaEnum {
    F = 'F',
    J = 'J'
}
/**
 * 
 * @export
 * @interface QuoteAndBuyResponse
 */
export interface QuoteAndBuyResponse {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponse
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponse
     */
    versionPoliza?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteAndBuyResponse
     */
    gestionDeCompetencias?: boolean;
    /**
     * 
     * @type {Array<QuoteAndBuyResponseDatosImporteOrientativo>}
     * @memberof QuoteAndBuyResponse
     */
    datosImporteOrientativo?: Array<QuoteAndBuyResponseDatosImporteOrientativo>;
    /**
     * 
     * @type {QuoteAndBuyResponseDatosEconomicos}
     * @memberof QuoteAndBuyResponse
     */
    datosEconomicos?: QuoteAndBuyResponseDatosEconomicos;
    /**
     * 
     * @type {QuoteAndBuyResponseDatosOferta}
     * @memberof QuoteAndBuyResponse
     */
    datosOferta?: QuoteAndBuyResponseDatosOferta;
    /**
     * 
     * @type {QuoteAndBuyResponseResultadosTarificacionSinDescuento}
     * @memberof QuoteAndBuyResponse
     */
    resultadosTarificacionSinDescuento?: QuoteAndBuyResponseResultadosTarificacionSinDescuento;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof QuoteAndBuyResponse
     */
    datosCompania?: { [key: string]: object; };
    /**
     * 
     * @type {Array<QuoteAndBuyResponseDatosDocumentacion>}
     * @memberof QuoteAndBuyResponse
     */
    datosDocumentacion?: Array<QuoteAndBuyResponseDatosDocumentacion>;
    /**
     * 
     * @type {QuoteAndBuyResponseDatosEmision}
     * @memberof QuoteAndBuyResponse
     */
    datosEmision?: QuoteAndBuyResponseDatosEmision;
    /**
     * 
     * @type {QuoteAndBuyResponseAdvertencia}
     * @memberof QuoteAndBuyResponse
     */
    advertencia?: QuoteAndBuyResponseAdvertencia;
    /**
     * 
     * @type {Array<Error>}
     * @memberof QuoteAndBuyResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseAdvertencia
 */
export interface QuoteAndBuyResponseAdvertencia {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseAdvertencia
     */
    codigoAdvertencia?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseAdvertencia
     */
    descripcionAdvertencia?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseAdvertencia
     */
    severidadAdvertencia?: number;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosDocumentacion
 */
export interface QuoteAndBuyResponseDatosDocumentacion {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosDocumentacion
     */
    codigoDocumento?: string;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosEconomicos
 */
export interface QuoteAndBuyResponseDatosEconomicos {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEconomicos
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEconomicos
     */
    codigoPaquete?: string;
    /**
     * 
     * @type {QuoteAndBuyResponseDatosEconomicosResultadosTarificacion}
     * @memberof QuoteAndBuyResponseDatosEconomicos
     */
    resultadosTarificacion?: QuoteAndBuyResponseDatosEconomicosResultadosTarificacion;
    /**
     * 
     * @type {Array<QuoteAndBuyResponseDatosEconomicosValoresPorOpcion>}
     * @memberof QuoteAndBuyResponseDatosEconomicos
     */
    valoresPorOpcion?: Array<QuoteAndBuyResponseDatosEconomicosValoresPorOpcion>;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosEconomicosResultadosTarificacion
 */
export interface QuoteAndBuyResponseDatosEconomicosResultadosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEconomicosResultadosTarificacion
     */
    formaPago?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEconomicosResultadosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEconomicosResultadosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEconomicosResultadosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEconomicosResultadosTarificacion
     */
    primaTecnica?: number;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosEconomicosValoresPorOpcion
 */
export interface QuoteAndBuyResponseDatosEconomicosValoresPorOpcion {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEconomicosValoresPorOpcion
     */
    codigoMCT?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof QuoteAndBuyResponseDatosEconomicosValoresPorOpcion
     */
    datosElemento?: { [key: string]: object; };
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosEmision
 */
export interface QuoteAndBuyResponseDatosEmision {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    tipoProducto?: QuoteAndBuyResponseDatosEmisionTipoProductoEnum;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    codigoBanco?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    codigoOficinaBanco?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    numeroControlBanco?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    numeroCuentaBanco?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    codigoIBAN?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    tipoCanalCobro1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    tipoCanalCobroSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    estadoProcesoEmision?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    codigoRecibo?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    importeImpuestoOtrosAdicionales?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    importeImpuestoPrima?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    primaNetaEmision?: number;
    /**
     * 
     * @type {Array<QuoteAndBuyResponseDatosDocumentacion>}
     * @memberof QuoteAndBuyResponseDatosEmision
     */
    datosComunesEmision?: Array<QuoteAndBuyResponseDatosDocumentacion>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuoteAndBuyResponseDatosEmisionTipoProductoEnum {
    Comercios = 'Comercios',
    RC = 'RC',
    RCProfesional = 'RCProfesional',
    Accidentes = 'Accidentes',
    RCDO = 'RCD&O',
    Cyber = 'Cyber'
}
/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosImporteOrientativo
 */
export interface QuoteAndBuyResponseDatosImporteOrientativo {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosImporteOrientativo
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosImporteOrientativo
     */
    importeSugeridoMinimo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosImporteOrientativo
     */
    importeSugeridoMaximo?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosImporteOrientativo
     */
    codigoPrimaMinimaSugerida?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosImporteOrientativo
     */
    codigoPrimaMaximaSugerida?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosImporteOrientativo
     */
    codigoLimiteRc?: string;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseDatosOferta
 */
export interface QuoteAndBuyResponseDatosOferta {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosOferta
     */
    codigoActividad?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseDatosOferta
     */
    familiaComercios?: string;
}/**
 * 
 * @export
 * @interface QuoteAndBuyResponseResultadosTarificacionSinDescuento
 */
export interface QuoteAndBuyResponseResultadosTarificacionSinDescuento {
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseResultadosTarificacionSinDescuento
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAndBuyResponseResultadosTarificacionSinDescuento
     */
    formaPago?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseResultadosTarificacionSinDescuento
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseResultadosTarificacionSinDescuento
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseResultadosTarificacionSinDescuento
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteAndBuyResponseResultadosTarificacionSinDescuento
     */
    primaTecnica?: number;
}

type quoteAndBuyOperationParams = {
  quoteAndBuyRequest: QuoteAndBuyRequest,
};

/**
 * QuoteAndBuyApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const QuoteAndBuyApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Tarifica y emite una póliza
     * @param {QuoteAndBuyRequest} quoteAndBuyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    quoteAndBuyOperation(params: quoteAndBuyOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'quoteAndBuyRequest' is not null or undefined
      if (params.quoteAndBuyRequest === null || params.quoteAndBuyRequest === undefined) {
        throw new RequiredError('quoteAndBuyRequest', 'Required parameter quoteAndBuyRequest was null or undefined when calling quoteAndBuyOperation.');
      }
      const localVarPath = `/V1/getPrice/quoteAndBuyOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('QuoteAndBuyRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.quoteAndBuyRequest || {}) : params.quoteAndBuyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuoteAndBuyApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const QuoteAndBuyApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Tarifica y emite una póliza
     * @param {QuoteAndBuyRequest} quoteAndBuyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    quoteAndBuyOperation(params: quoteAndBuyOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteAndBuyResponse> {
      const localVarAxiosArgs = QuoteAndBuyApiAxiosParamCreator(configuration).quoteAndBuyOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * QuoteAndBuyApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const QuoteAndBuyApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Tarifica y emite una póliza
     * @param {QuoteAndBuyRequest} quoteAndBuyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    quoteAndBuyOperation(params: quoteAndBuyOperationParams, options?: any): any {
      return QuoteAndBuyApiFp(configuration).quoteAndBuyOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * QuoteAndBuyApi - object-oriented interface
 * @export
 * @class QuoteAndBuyApi
 * @extends {BaseAPI}
 */
export class QuoteAndBuyApi extends BaseAPI {
  
  /**
   * Tarifica y emite una póliza
   * @param {QuoteAndBuyRequest} quoteAndBuyRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof QuoteAndBuyApi
   */
  public quoteAndBuyOperation(params: quoteAndBuyOperationParams, options?: any): any {
    return QuoteAndBuyApiFp(this.configuration).quoteAndBuyOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAquoteAndBuyOperationInputMessage
 */
export class EAquoteAndBuyOperationInputMessage {
  
  /**
   * 
   * @type {QuoteAndBuyRequest}
   * @memberof EAquoteAndBuyOperationInputMessage
   */
  public quoteAndBuyRequest: QuoteAndBuyRequest;

  public constructor(quoteAndBuyRequest: QuoteAndBuyRequest, ){
  
  this.quoteAndBuyRequest=quoteAndBuyRequest;
  } 
}



/** 
 * EAQuoteAndBuyApi - Architecture client for quoteAndBuyOperation
 * @export
 * @class EAQuoteAndBuyApi
 */
export class EAQuoteAndBuyApi {

  /**
   * 
   * @param {QuoteAndBuyRequest} quoteAndBuyRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<QuoteAndBuyResponse | null>}
  */ 
  public async quoteAndBuyOperation(params: quoteAndBuyOperationParams): Promise<QuoteAndBuyResponse | null> {
    return this.quoteAndBuyOperationPromise(params);
  }

  /**
   * 
   * @param {QuoteAndBuyRequest} quoteAndBuyRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async quoteAndBuyOperationSteps(params: quoteAndBuyOperationParams, callback: (res: AxiosResponse<QuoteAndBuyResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/V1/getPrice/quoteAndBuyOperation', 'quoteAndBuy', 'quoteAndBuyOperation');
    const config: Configuration = {};
    const api = new QuoteAndBuyApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.quoteAndBuyOperation(params, { headers }) as AxiosResponse<QuoteAndBuyResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {QuoteAndBuyRequest} quoteAndBuyRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<QuoteAndBuyResponse | null>}
   */
  public async quoteAndBuyOperationPromise(params: quoteAndBuyOperationParams): Promise<QuoteAndBuyResponse | null> {
    return new Promise((resolve, reject) => {
      this.quoteAndBuyOperationSteps(params, (response: AxiosResponse<QuoteAndBuyResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


