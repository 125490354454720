import {
  FeatureControlBase,
  InstanceOptions
} from '../base';
import {
  BrandingConfig
} from '@/utils/interfaces';
import {
  cloneDeep
} from 'lodash';

/**
 * Controls the brandings in app settings
 */
export class BrandingControl extends FeatureControlBase {

  /**
   * Returns the current branding settings for the current environment
   * @param {boolean} allOptionsInformation
   * @returns {BrandingConfig | undefined}
   */
  protected static brandingOptions(
    allOptionsInformation?: boolean
  ): [BrandingConfig | undefined, string, string, string] | BrandingConfig | undefined {
    const currentOption = this.getCurrentOption<BrandingConfig>(InstanceOptions.BrandingSettings);
    if (currentOption && currentOption.length > 0) {
      return allOptionsInformation
        ? currentOption
        : currentOption[0];
    }
    return undefined;
  }
  
  /**
   * Returns a member of the branding setting
   * @param {string} settingsName
   * @returns {unknown | undefined}
   */
  public static getBrandingSettings<T = BrandingConfig[keyof BrandingConfig]>(
    settingsName: keyof BrandingConfig
  ): T | undefined {
    const currentBrandingOptions = this.brandingOptions() as BrandingConfig;
    if (currentBrandingOptions) {
      return currentBrandingOptions[settingsName] as unknown as T;
    }
    return undefined;
  }

  /**
   * Updates a member of the branding settings
   * @param {string} optionName
   * @param {any} value
   */
  public static updateBrandingSettings(optionName: keyof BrandingConfig, value: any): void {
    const currentBrandingOptions = this.brandingOptions(true) as [BrandingConfig | undefined, string, string, string];
    if (currentBrandingOptions && currentBrandingOptions.length > 0 && currentBrandingOptions[0]) {
      const [
        currentOptions,
        env,
        branding,
        instanceProperty
      ] = currentBrandingOptions;
      const self = this as any;
      currentOptions[optionName] = value;
      self[instanceProperty][env][branding] = cloneDeep(currentOptions);
    }
  }
}
