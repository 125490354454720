import DynamicFieldGeneratorModel from '@/business-components/dynamic-field-generator/dynamic-field-generator-model';
import FieldAmountGeneratorModel from '@/business-components/field-amount-generator/field-amount-generator-model';
import PostalCodeSelectorModel from '@/business-components/postal-code-selector/postal-code-selector-model';
import {
  AsegasaComplexFormFields
} from '@/utils/enums';

/**
 * Asegasa full form
 */
export default class AsegasaFullForm {

  public direccion = new PostalCodeSelectorModel();

  public unidades = new DynamicFieldGeneratorModel();

  public listaMatriculaVehiculo = new FieldAmountGeneratorModel();

  public [AsegasaComplexFormFields.ListaMatriculaCotoCaza] = new FieldAmountGeneratorModel();
  
  public codGanado = new DynamicFieldGeneratorModel();

  public actividad: string = '';

  public descripcionActividad: string = '';

  public tipoIdentificador: string = '';

  public identificadorFiscal: string = '';

  public correoElectronico: string = '';

  public pais: string = '';

  public codigoPostal: string = '';

  public tipoVia: string = '';

  public nombreVia: string = '';

  public numeroVia?: number;

  public informacionAdicional: string = '';

  public hectareas: number = 0;

  public nHectareasDehesa: number = 0;

  public nHectareasCotoCaza: number = 0;

  public nHectareasCosechable: number = 0;

  public nCabezasVacuno: number = 0;

  public nCabezasOtro: number = 0;

  public nCabezasBravo: number = 0;

  public capacidadBalsas: number = 0;

  public nEmpleados: number = 0;

  public vidaPrivadaM2: number = 0;

  public nReg: number = 0;

  public sumaAseg: string = '';

  public facturacion: number = 0;

  [key: string]:
  string | number | PostalCodeSelectorModel | FieldAmountGeneratorModel | DynamicFieldGeneratorModel | undefined;
}
