export interface FieldAmountGeneratorDependencyFields {
  id: string;
  label: string;
  dependantPropName: string;
  dependantLabel: string;
}

export interface FieldAmountGeneratorDependency {
  label: string;
  fields: FieldAmountGeneratorDependencyFields[];
}

export interface FieldAmountGeneratorDependant {
  id: string;
  label: string;
  propName: string;
  validations?: string[];
}

export interface FieldAmountGeneratorConfig {
  dependency: FieldAmountGeneratorDependency;
}

/** 
 * Model field-amount-generator
 */
class FieldAmountGeneratorModel {

  public count: number | null = null;

  public values: {[key: string]: string | number | null} = {};

  public fields: {[key: string]: string | number} = {};
}

export default FieldAmountGeneratorModel;
