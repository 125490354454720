import {
  TreeSearchGroup
} from '@/utils/interfaces/tree-search';

export interface SynonymsModel {
  [key: string]: string[];
}

export type SearchBarIconState = 'open' | 'close' | 'cancel';

export enum SearchBoxType {
  default = 'default',
  mobile = 'mobile'
}

/**
 * Auto Complete Search Model 
 */
class TreeSearchModel {

  public resultsList: TreeSearchGroup[] = [];

  public isResultsFound: boolean | undefined = undefined;

  public isDropdownEnabled: boolean = false;

  public isOptionSelected: boolean = false;

  public isSearchListIndexExpanded: boolean = false;

  public searchBarIconState: SearchBarIconState = 'open';

  public searchValue: string = '';

  public expandedIndexesList: (string | undefined)[] = [];
}

export default TreeSearchModel;
