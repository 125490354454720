import {
  IndexedObject
} from '@/utils/interfaces';

export interface EnvironmentBasedKeyValuePair<T> {
  default?: IndexedObject<T>;
  local: IndexedObject<T>;
  dev: IndexedObject<T>;
  int: IndexedObject<T>;
  uat: IndexedObject<T>;
  prod: IndexedObject<T>;
}

export enum AppEnvironments {
  Local = 'local',
  Dev = 'dev',
  Int = 'int',
  Uat = 'uat',
  Prod = 'prod'
}

const appEnvUrlsMap: IndexedObject<{[key: string]: string[]}> = {
  dev: [
    'zurichempresas.dev.zurmsv.com',
    'zurichempresas-s3.dev.zurmsv.com'
  ],
  'int': ['zurichempresas.ist.zurmsv.com'],
  uat: [
    'zurichempresas.uat.zurmsv.com',
    'contratar-zurichempresas.uat-zurich.es',
    'orangeseguros-zurichempresas.uat-zurich.es',
    'pimec-zurichempresas.uat-zurich.es',
    'cmbge-zurichempresas.uat-zurich.es',
    'segurosol-zurichempresas.uat-zurich.es',
    'asegasa-zurichempresas.uat-zurich.es',
    'estancos-zurichempresas.uat-zurich.es',
    'naturaloptics-zurichempresas.uat-zurich.es',
    'cofb-zurichempresas.uat-zurich.es'
  ],
  prod: [
    'contratar-zurichempresas.zurich.es',
    'contratar.zurichempresas.es',
    'orangeseguros-zurichempresas.zurich.es',
    'orangeseguros.zurichempresas.es',
    'pimec-zurichempresas.zurich.es',
    'pimec.zurichempresas.es',
    'cmbge.zurichempresas.es',
    'asegasa-zurichempresas.zurich.es',
    'asegasa.zurichempresas.es',
    'estancos.zurichempresas.es',
    'estancos-zurichempresas.zurich.es',
    'naturaloptics.zurichempresas.es',
    'naturaloptics-zurichempresas.zurich.es',
    'cofb.zurichempresas.es',
    'cofb-zurichempresas.zurich.es'
  ]
};

/**
 * Determines what is the current environment the app is running at,
 * and it will return an identifier for such environment
 * @returns {AppEnvironments}
 */
export function getCurrentEnvironment(): AppEnvironments {
  const hostName = window.location.hostname;
  let result: AppEnvironments = AppEnvironments.Local;

  for (const env in appEnvUrlsMap) {
    const envHostName = appEnvUrlsMap[env];

    if (envHostName && envHostName.length > 0 && envHostName.some(host => host === hostName)) {
      switch (env) {
        case AppEnvironments.Dev:
          result = AppEnvironments.Dev;
          break;

        case AppEnvironments.Int:
          result = AppEnvironments.Int;
          break;

        case AppEnvironments.Uat:
          result = AppEnvironments.Uat;
          break;

        case AppEnvironments.Prod:
          result = AppEnvironments.Prod;
          break;
      
        default:
          result = AppEnvironments.Local;
          break;
      }
    }
  }

  return result;
}
