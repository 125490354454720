import {
  EAValidationError,
  EALocaleManager,
} from '@zurich-es-npm/ea-front-web-core';
import {
  getCurrentAppliedBranding,
  BrandingOptions
} from '@/locales/branding';
import {
  debounce
} from 'lodash';
import {
  ProductId
} from '../flows/flows-views/get-price/get-price-model';
import moment from 'moment';
import {
  QuoteResponseType
} from './enums';

//Table relations with the model

export interface LocationParameter {
  flowType?: string;
  group?: string;
  activity?: string;
  products?: {techProd: string; commProd: string}[];
}

/**
 * Debounces the execution of a callback function, for a given time
 * @param {number} time
 * @param {Function} callback
 */
export const debounceFn = debounce(callback => {
  callback();
}, 500);

/**
 * Get only the name part of a full name composed of a name + surnames.
 * @param {string} fullName Full user name
 * @returns {string} only the name part
 */
export function getUserNameFromFullName(fullName: string): string {
  const fullNameTrimmed = fullName.trim();
  if (fullNameTrimmed.length) {
    return fullNameTrimmed.split(' ')[0];
  } else {
    return '';
  }
}

/**
 * Get name and surname parts from a full name. This is an approximation, it may return wrong answers.
 *
 * @export
 * @param {string} fullName
 * @returns {[string, string]}
 */
export function approximateNameAndSurname(fullName: string): [string, string] {
  const nameParts = fullName.split(' ');
  if (nameParts.length === 1) {
    return [nameParts[0], ''];
  }
  const name = nameParts.length >= 4 ? `${nameParts[0]} ${nameParts[1]}`: nameParts[0];
  const surname = nameParts.length > 2 ?
    `${nameParts[nameParts.length - 2]} ${nameParts[nameParts.length - 1]}`
    : nameParts[nameParts.length - 1];
  return [name, surname];
}

/**
 * Required validation removing whitespaces from the string value
 * eaRequiredValidation accepts whitespaces
 * @param {unknown} rule
 * @param {string} value
 * @param {Function} callback
 * @returns {any} name validation
 */
export function customRequiredValidation(rule: unknown, value: string | number, callback: Function): any {
  if ((typeof value === 'string' && value.trim().length > 0) || (typeof value === 'number' && value > 0)) {
    return callback();
  } else {
    const text = `${EALocaleManager.i18n.t('common.requiredField')}`;
    return callback(new EAValidationError(text));
  }
}

/**
 * Cuts the company name in pieces of 30 characters, every 30 characters
 * @param {string} stringToSlice
 * @returns {any}
 */
export function sliceCompanyName(stringToSlice: string): {
  slice1: string;
  slice2: string;
  slice3: string;
} {
  const slice1 = stringToSlice.slice(0, 30);
  const slice2 = stringToSlice.slice(30, 60);
  const slice3 = stringToSlice.slice(60, stringToSlice.length);

  return {
    slice1,
    slice2,
    slice3
  };
}

/**
 * Changes the favicon depending on the applied branding
 */
export function handleFaviconSelection(): void {
  const faviconEl = document.querySelector('link[rel="icon"]') as HTMLLinkElement;

  if (getCurrentAppliedBranding() === BrandingOptions.Orange) {
    faviconEl.href = '/orange-favicon.ico';
  }
}

/**
 * Determines if an object has properties or has a truthy value
 * @param {object} testingObject
 * @returns {boolean}
 */
export function objectHasTruthyValue(testingObject?: object): boolean {
  return (testingObject && testingObject.constructor === Object && Object.keys(testingObject).length > 0) as boolean;
}

/**
 * Determines if the active environment is local or a cloud environment
 * @returns {boolean}
 */
export function isCurrentEnvironmentLocal(): boolean {
  return window.location.hostname.indexOf('localhost') >= 0;
}

/**
 * TODO: This will be useless after revamping the cart system
 * Returns the quote property name of the model, based on the name of the product
 * @param {ProductId} productName
 * @returns {string}
 */
export function getQuoteName(productName: ProductId | unknown): string {
  switch (productName) {
    case ProductId.Commerce:
      return QuoteResponseType.Commerce;
    
    case ProductId.Accidents:
      return QuoteResponseType.Accidents;
  
    case ProductId.RcPro:
      return QuoteResponseType.RcPro;
      
    default:
      return '';
  }
}

/**
 * Capitalizes a given string
 * @param {string} value
 * @param {boolean} multipleWords
 * @param {boolean} applyLowerCase
 * @returns {string}
 */
export function capitalize(value: string, multipleWords?: boolean, applyLowerCase?: boolean): string {
  const baseValue = value && applyLowerCase
    ? value
      .split('')
      .map(character => character.toLocaleLowerCase())
      .join('')
    : value;

  if (multipleWords) {
    const sentenceWords = baseValue.split(' ');
    const capitalizedWordsList = sentenceWords
      .map(word => word.charAt(0).toUpperCase() + word.slice(1));

    return capitalizedWordsList.join(' ');
  }

  return (
    baseValue &&
    baseValue.length > 0 &&
    baseValue.charAt(0).toUpperCase() + baseValue.slice(1)
  ) || '';
}

/**
 * Replaces a determined string of characters with another given string of characters
 * @param {string} baseValue
 * @param {string} charsToReplace
 * @param {string} charsToReplaceWith
 * @returns {string}
 */
export function replaceCharacter(baseValue: string, charsToReplace: string, charsToReplaceWith: string): string {
  return (
    baseValue &&
    baseValue.length > 0 &&
    baseValue.replace(charsToReplace, charsToReplaceWith)
  ) || '';
}

/**
 * Handles a compatibility issue with the URLSearchParams for IE
 * @param {Location} windowLocation
 * @returns {URLSearchParams | string | number | null}
 */
export function urlSearchParamsAlt(windowLocation: Location): any {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE');
  const msie11 = ua.indexOf('Trident');

  if (msie > -1 || msie11 > -1) {
    const windowHref = windowLocation.href;
    const paramsPattern = /(apikey)/;
    const results = paramsPattern.exec(windowHref);

    const get = (name: string): RegExpExecArray | string| null => {
      const namePattern = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regexS = `[\\?&]${namePattern}=([^&#]*)`;
      const regex = new RegExp(regexS);
      const getResult = regex.exec(windowHref);
      return getResult === null ? null : getResult[1];
    };

    if (results === null) {
      return null;
    } else {
      return {
        get
      };
    }
  } else {
    const windowSearch = windowLocation.search;
    return new URLSearchParams(windowSearch);
  }
}

/**
 * Generates a random string, of a fixed length
 * @param {number} strLength
 * @returns {string}
 */
export function generateRandomString(strLength: number): string {
  let randStr = '';

  for (let index = 0; index <= strLength; index++) {
    randStr +=
      Math
        .random()
        .toString(36)
        .substring(2, 7);
  }

  return randStr;
}

/**
 * Determines if a passed parameter has an actual value, meaning it is not undefined, null or empty string.
 * 0 counts as a value for this method
 * @param {any} value
 * @returns {boolean}
 */
export function hasActualValue(value: any): boolean {
  return value !== undefined || value !== null || value !== '';
}

/**
 * Converts an enum to an object, and returns the result.
 * @param {any} enumToConvert
 * @returns {object}
 */
export function convertEnumToObject(enumToConvert: any): {[key: string]: any} {
  const enumKeys = Object.keys(enumToConvert);
  return enumKeys.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: enumToConvert[curr]
    };
  }, {});
}

/**
 * Requires and converts an image into a base64 string
 * @param {string} type
 * @param {string} src
 * @param {string} outputFormat
 * @param {Function} callback
 */
export function transformImageToBase64(type: string, src: string, outputFormat: string, callback?: Function): void {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  
  if (type === 'image') {
    img.src = require(`../../public/images/zurich/${src}`);
  } else if (type === 'icon') {
    img.src = require(`../assets/icons/global/${src}`);
  }
  
  img.onload = () => {
    const canvas = document.createElement('CANVAS') as any;
    const ctx = canvas.getContext('2d');
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL(outputFormat);
    
    if (callback) {
      callback(src, dataURL);
    }
  };
}

/**
 * Based on a given timeout for trigerring the recursiveness,
 * and using starting and current time references,
 * and a time limit for the difference in time references to execute a callback
 * it will trigger a callback method in a recursive fashion
 * @param {number} timeout
 * @param {number} timesReferenceLimit time in minutes
 * @param {object} timesReference
 * @param {Function} callbackFn
 */
export async function recursiveCallbackTimeout(
  timeout: number,
  timesReferenceLimit: number,
  timesReference: {startingTime: Date | null; currentTime: Date | null},
  callbackFn: Function
): Promise<void> {
  timesReference.currentTime = moment().toDate();

  if (timesReference.startingTime && timesReference.currentTime) {
    const startingMomentReference = moment(timesReference.startingTime);
    const currentMomentReference = moment(timesReference.currentTime);

    const differenceDuration = moment.duration(currentMomentReference.diff(startingMomentReference));
    const differenceInMinutes = differenceDuration.asMinutes();

    if (differenceInMinutes >= timesReferenceLimit) {
      callbackFn();
    }
  }

  setTimeout(() => {
    recursiveCallbackTimeout(
      timeout,
      timesReferenceLimit,
      timesReference,
      callbackFn
    );
  }, timeout);
}
