import {
  EASDKCallback,
  EASDKCallbackConfig,
  EAIntegrationConfig,
  EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';

/**
 * Application SDK services configuration
 */
export class EAApplicationSDKConfig {

  /**
   * Configure interceptors for application sdk services
   * 
   * @param {EAIntegrationConfig | undefined} integration
   */
  public static configure(integration: EAIntegrationConfig | undefined): void {

    if (integration) {

      // Verify if default configuration is defined in config.js
      const defaultEndpoint = integration.default;
      if (defaultEndpoint) {

        // Configure default sdk endpoints
        const defaultConfig: EASDKCallbackConfig = {
          endpoint: defaultEndpoint,
          requestInterceptors: [
            'requestTimeTracesInterceptor',
            'requestSecurityInterceptor',
            'requestHeaderInterceptor',
            'requestApiKeyInterceptor',
          ],
          responseInterceptors: [
            'responseTimeTracesInterceptor'
          ],
          errorInterceptor: 'errorInterceptor'
        };

        EASDKCallback.addDefaultConfiguration(defaultConfig);
      } else {
        throw new EAApplicationError('ARC00012', ['Application SDK configuration']);
      }
    } else {
      throw new EAApplicationError('ARC00012', ['Application SDK configuration']);
    }

  }

}
