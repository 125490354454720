import {
  Product
} from '@/utils/interfaces';
import {
  ProductCode
} from '@/utils/enums';
import {
  FeatureControl
} from '@/helpers/feature-control/features/featureControl';

/**
 * Returns a products settings list of all products available or just specific products, based on a list given
 * @param {Product[]} productsSettingsList
 * @param {ProductCode[]} productsCodes
 * @returns {Product[] | undefined}
 */
function findProductSettings(
  productsSettingsList: Product[] | undefined,
  productsCodes?: ProductCode[]
): Product[] | undefined {
  if (productsSettingsList && productsSettingsList.length > 0) {
    return productsSettingsList
      .filter(productSettings => (
        productsCodes
        && productsCodes.some(code => code === productSettings.code)
      )
      || (!productsCodes && productSettings.code));
  }

  return undefined;
}

/**
 * Retrieves the settings for all the products or just one product in particular
 * @param {ProductCode[]} productsCodes
 * @returns {Product[] | undefined}
 */
export default function getProductSettings(productsCodes?: ProductCode[]): Product[] | undefined {
  const productsSettings = FeatureControl.getBrandingSettings
    .bind(FeatureControl)
    .call(FeatureControl, 'products') as Product[] | undefined;

  return findProductSettings(productsSettings, productsCodes);
}
