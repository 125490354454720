export type EnumerableObject<E> = {
  [K in keyof E]: E[K]
}

/**
 * Converts an enum to an object, and returns the result.
 * @param {any} enumToConvert
 * @returns {object}
 */
export function convertEnumToObject<E>(enumToConvert: E): EnumerableObject<E> {
  const enumKeys = Object.keys(enumToConvert) as (keyof E)[];
  return enumKeys.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: enumToConvert[curr]
    };
  }, {} as unknown as EnumerableObject<E>);
}
