import {
  EARouteConfig
} from '@zurich-es-npm/ea-front-web-core';

import DefaultPublicLayout from '../layout/default-public-layout.vue';

/**
 * Architecture: Security route entries
 * Don't modify or delete
 */
export const securityRouter: EARouteConfig[] = [
  {
    path: '/sec',
    name: 'Security',
    redirect: '/sec/logout',
    component: DefaultPublicLayout,
    children: [
      {
        path: 'logout',
        name: 'EALogoutFlow',
        component: () => import('../flows/ea/security/ea-logout-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
      {
        path: 'login',
        name: 'EALoginFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-login-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
      {
        path: 'do-login',
        name: 'EADoLoginAction',
        meta: {
          skipMultiOperation: true
        }
      },
      {
        path: 'session-expired',
        name: 'EASessionExpiredFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-session-expired-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
      {
        path: 'unauthorized',
        name: 'EAUnauthorizedFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-unauthorized-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      }
    ]
  }
];

/**
 * Architecture: Error route entries
 * Don't modify or delete
 */
export const errorRouter: EARouteConfig[] = [
  {
    path: '/error',
    name: 'Error',
    redirect: '/error/404',
    component: DefaultPublicLayout,
    children: [
      {
        path: '404',
        component: () => import('../flows/ea/error/ea-not-found-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
    ]
  },
  {
    path: '*',
    redirect: '/error/404',
    meta: {
      skipMultiOperation: true,
      isPublic: true
    }
  }
];

/** 
 * Flow route entries
 */
export const appRouter: EARouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    redirect: '/get-price',
    component: DefaultPublicLayout,
    meta: {
      isPublic: true
    },
    children: [
      {
        path: 'get-price',
        name: 'GetPriceFlow',
        component: () => import('../flows/flows-views/get-price/get-price-flow.vue'),
        props: true,
        meta: {
          skipMultiOperation: false,
          isPublic: true
        }
      }
    ],
  },
];

/** 
 * Export route entries
 */
export const appRoutes: EARouteConfig[] = [
  ...securityRouter,
  ...errorRouter,
  ...appRouter
];
