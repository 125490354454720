import {
  FeatureControl,
} from '@/helpers/feature-control/features/featureControl';
import {
  FeatureName
} from '@/utils/enums';

/**
 * Determines if the web identifier should be added to the request.
 * And returns the identifier if it should and undefined if not
 * @param {object} model
 * @returns {boolean}
 */
export default function getWebIdentifier(model: {[key: string]: unknown}): string | undefined {
  if (
    FeatureControl.isFeatureEnabled(FeatureName.webIdentifier)
    && model
    && 'webIdentifier' in model
    && typeof model.webIdentifier === 'string'
    && model.webIdentifier.length > 0
  ) {
    return model.webIdentifier;
  }
  return undefined;
}
