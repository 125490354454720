import {
  getCurrentAppliedBranding
} from '@/locales/branding';

import setObjectProperty from '@/utils/methods/setObjectProperty';

export const CALLCENTER_TELEPHONE_NUMBERS: {[key: string]: string} = {};

/**
 * Sets the value of the current branding phone number
 * @param {string} value
 */
export function setCurrentPhoneNumber(value?: string): void {
  if (value) {
    setObjectProperty(
      CALLCENTER_TELEPHONE_NUMBERS,
      getCurrentAppliedBranding(),
      value
    );
  }
}

/**
 * Exposes the data of the current seller (branding owner)
 */
export class CurrentSellerData {
  public phoneNumberExtension: string = '';

  public phoneNumber: string = '';
}
