import {
  InAppBrandingConfig
} from '@/utils/interfaces';
import {
  defaultZurichBrandingConfig,
  prodZurichBrandingConfig
} from './zurich';
import {
  defaultOrangeBrandingConfig,
} from './orange';
import {
  defaultPimecBrandingConfig,
  prodPimecBrandingConfig,
} from './pimec';
import {
  defaultAsegasaBrandingConfig
} from './asegasa';
import {
  defaultSegurosolBrandingConfig
} from './segurosol';
import {
  defaultEstancosBrandingConfig
} from './estancos';
import {
  defaultNaturalOpticsBrandingConfig
} from './naturaloptics';
import {
  defaultCOFBBrandingConfig,
  prodCofbBrandingConfig
} from './cofb';

/**
 * Declaration of available brandings and basic settings
 * - Naming of the branding
 * - Urls
 * - Cookies
 * - Products
 * - Links
 * - Phone numbers
 */
export const brandingConfig: InAppBrandingConfig = {
  'default': {
    zurich: defaultZurichBrandingConfig,
    orange: defaultOrangeBrandingConfig,
    pimec: defaultPimecBrandingConfig,
    asegasa: defaultAsegasaBrandingConfig,
    estancos: defaultEstancosBrandingConfig,
    naturaloptics: defaultNaturalOpticsBrandingConfig,
    cofb: defaultCOFBBrandingConfig,
    segurosol: defaultSegurosolBrandingConfig,
  },
  local: {
    zurich: {
      ...defaultZurichBrandingConfig,
      cookies: ''
    },
    orange: {
      ...defaultOrangeBrandingConfig,
      cookies: ''
    },
    pimec: {
      ...defaultPimecBrandingConfig,
      cookies: ''
    },
    asegasa: {
      ...defaultAsegasaBrandingConfig,
      cookies: ''
    },
    estancos: {
      ...defaultEstancosBrandingConfig,
      cookies: ''
    },
    naturaloptics: {
      ...defaultNaturalOpticsBrandingConfig,
      cookies: ''
    },
    cofb: {
      ...defaultCOFBBrandingConfig,
      cookies: ''
    },
    segurosol: {
      ...defaultSegurosolBrandingConfig,
      cookies: ''
    },
  },
  prod: {
    zurich: prodZurichBrandingConfig,
    pimec: prodPimecBrandingConfig,
    cofb: prodCofbBrandingConfig,
    segurosol: defaultSegurosolBrandingConfig
  }
};
