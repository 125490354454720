/**
 * Install business component flows as Vue component
 * 
 * @param {any} Vue - Vue instance
 */
const install = function(): void {
  // TODO: install necessary flows here
};

export default install;
