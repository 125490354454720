import {
  GetPriceModelProperties,
  OptionalCRMProperties
} from '@/flows/flows-views/get-price/get-price-model';
import {
  FlowAssistanceType,
  ProductCode,
  ProductId,
  ProductOptionConditionType,
  UnderlineAssistanceType,
  ZeVersion,
  ZeProductCardVisibility,
  ZeViews,
  ZeButtonSizes,
  ZeImgExt,
  ZeImgSrcPath,
  ZeImgSrcType,
  ZeImgType
} from '@/utils/enums';
import {
  BrandingConfig
} from '@/utils/interfaces';

export const defaultAsegasaBrandingConfig: BrandingConfig = {
  cookies: '',
  phoneNumbers: {
    contactCenter: '954 65 17 11'
  },
  logos:{
    primary:{
      src: require('../../../assets/images/asegasa/logo-desktop-secondary.svg'),
      className:'',
      removeDivider: false
    },
    secondary:{
      src: require('../../../assets/images/asegasa/logo-desktop.svg'),
      className:'',
      removeDivider: true
    }
  },
  links: {
    mainSite: 'https://asegasa.com/',
    cookiesPolicy: 'https://zurichempresas.es/politica-de-cookies',
    privacyPolicy: 'https://zurichempresas.es/politica-de-privacidad',
    hiringPolicy: 'https://zurichempresas.es/politica-de-contratacion',
    informativeNote: ''
  },
  coverageDefaultInitialDateOffset: 0,
  flow: {
    assistanceTypeFlow: {
      type: FlowAssistanceType.NoAssistanceType,
      underlineDefaultAssistanceType: UnderlineAssistanceType.NeedAdvice
    },
    views: {
      productSelection:{
        viewVersion: ZeVersion.Default,
      },
      selectedProducts: {
        sections: {
          heroSection: {
            enabled: true,
            version: ZeVersion.Alpha,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          selectedProductsCoverages: {
            enabled: false
          },
          tempImgSection: {
            enabled: false
          },
          modifyCapitals: {
            enabled: false
          },
          additionalProducts: {
            enabled: false
          },
          faqs: {
            enabled: true
          },
          feedback: {
            enabled: false
          },
          meetinglawyersPromotion: {
            enabled: false
          },
        },
      },
      emailAndPhone: {
        sections: {
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          header: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
            components: {
              customHeader: {
                features: {
                  showReturnButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
                  }
                }
              }
            }
          }
        },
        features: {
          isPhoneEditable: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          showLoadingSpinner: {
            enabled: true
          },
          doCreateModificationsArray: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          doCRMRequest: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          waitForCompleteQueue: {
            enabled: false
          }
        }
      },
      coversSummary: {
        sections: {
          priceInfo: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              },
              selectedCoverageCardsSection: {
                constants: {
                  forceShowPriceWrapper: false,
                  forceShowProductsTitle: false
                }
              }
            }
          },
          coveragesTable: {
            enabled: false
          },
          productsCards: {
            enabled: false
          },
          documentationCard: {
            enabled: false,
          },
        },
        features: {
          showNotaMediador: {
            enabled: false,
          },
        },
        constants: {
          checkItemDocumentation: false
        }
      },
      onlinePaymentUserData: {
        viewVersion: ZeVersion.Alpha,
        sections: {
          personTypeSelection: {
            enabled: false
          },
          userDataForm: {
            enabled: true,
            components: {
              companyDataCapture: {
                constants: {
                  showManagerFirstName: true,
                  showManagerSurname: true
                }
              },
              freelanceDataCapture: {
                constants: {
                  showSecondSurname: true
                }
              }
            }
          },
          budgetAlert: {
            enabled: true,
            enabledByProduct: [ProductId.RC, ProductId.RcPro]
          },
          documentationRowSection: {
            enabled: true,
            enabledByProduct: [ProductId.RC, ProductId.RcPro]
          },
        },
        constants:{
          buttonSize: ZeButtonSizes.Small,
          preventRequotePriceOnTransition: true,
          hideButtonIcon: true,
          showSubtitle: true
        },
        features:{
          updateContactData: {
            enabled: true
          }
        }
      },
      holderAndRiskResume:{
        constants:{
          valuesToPersistInModel: [
            GetPriceModelProperties.Name,
            GetPriceModelProperties.Email
          ]
        }
      },
      finishedOnlinePayment: {
        sections: {
          emissionMainMessage: {
            enabled: true,
            version:  ZeVersion.Alpha
          },
          policyNumberCopy: {
            enabled: true,
            version: ZeVersion.Default
          },
          hiredProducts: {
            enabled: false,
            version: ZeVersion.Default,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: false,
                  },
                  showProductDetailsButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce],
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          productPromotion: {
            enabled: true,
            version: ZeVersion.Default
          },
          buttonsSection: {
            enabled: true,
            version:  ZeVersion.Alpha
          }
        },
        constants: {
          transitionTo: ZeViews.AsegasaFullForm,
          optionalCRMProperties:[
            OptionalCRMProperties.Holder
          ],
          generateUrlLocationParams: false
        }
      },
      businessActivitySelection:{
        viewVersion: {
          version: ZeVersion.Default,
        },
        sections: {
          cardsSelector: {
            enabled: false,
            version: ZeVersion.Default,
          }
        }
      },
      telephonePaymentConfirmation: {
        constants: {
          hideAvatar: true,
          showGoToHomesiteButton: true
        },
        features: {
          redirectWithParams: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          }
        }
      },
      preparingPrice: {
        constants: {
          hideAvatar: true
        },
        features: {
          checkCompetenceManagement: {
            enabled: false
          }
        }
      }
    },
    paymentType: {
      telephone: {
        enabled: true
      },
      online: {
        enabled: false
      }
    },
    settings: {
      callMeBack: {
        dialog: {
          customAvatar: {
            id: 'asegasa_tractor',
            name: 'asegasa_tractor',
            alt: 'Avatar de Asegasa',
            main: true,
            extension: ZeImgExt.Svg,
            imgType: ZeImgType.Icon,
            srcPath: ZeImgSrcPath.Assets,
            srcType: ZeImgSrcType.Dir
          }
        },
        buttons: {
          hideButtonsContainer: true
        }
      }
    }
  },
  //Products that can exist in the app
  products: [
    {
      id: ProductId.RC,
      code: ProductCode.RC,
      options: {
        isRecommended: {
          condition: [
            {
              conditionType: ProductOptionConditionType.Default,
            }
          ]
        },
        productCard: {
          partVisible: ZeProductCardVisibility.AddonsWrapper
        },
        showPrice: {
          condition: [
            {
              conditionType: ProductOptionConditionType.CompetenceManagement,
              activityCode: '0202450' //Activity Code
            }
          ]
        }
      }
    }
  ],
};
