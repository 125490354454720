import {
  cloneDeep, isNil
} from 'lodash';
import moment from 'moment';
import {
  CardChoice, GetPriceModel, GuideMeLetMeMode, ProductId
} from '@/flows/flows-views/get-price/get-price-model';
import getProductSettings from '@/helpers/feature-control/methods/getProductSettings';
import getCoverageInitialDefaultDateOffset from '@/helpers/feature-control/methods/getCoverageInitialDefaultDateOffset';
import {
  ProductCode, ZeDateTypes
} from '../enums';
import {
  CurrentProductStatus,
  Product
} from '../interfaces';
import isCurrentProductConditionsMet from '@/helpers/feature-control/methods/isCurrentProductConditionsMet';
import {
  Item,
  ItemCapitalAmounts,
  ItemConfig,
  ItemPricing,
  ItemTechnicalData,
  ListFilterOptions,
  RemoveItemConfig
} from './interfaces';
import getDefaultValue from '../methods/getDefaultValue';

export enum PaymentRecurrentType {
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export enum CartListType {
  Available = 'available',
  Cart = 'cart'
}

export enum CartSorting {
  ByPrice = 'byPrice',
  ByIdAlphabetically = 'byIdAlphabetically'
}

/**
 * Returns the current status of a product
 * @param {Item} item
 * @param {GetPriceModel} model #Optional
 * @returns {CurrentProductStatus}
 */
export function getProductStatus(item: Item, model?: GetPriceModel): CurrentProductStatus {
  const {
    technicalData,
    policyInformation,
    additionalInformation
  } = item;

  const productCode = technicalData.optionCode as ProductCode;
  const activityCode = getDefaultValue(additionalInformation?.activityCode, '');
  const competenceManagement = !!policyInformation?.competenceManagement;
  const guideMe = model?.guideMeLetMeMode === GuideMeLetMeMode.GuideMe;
  const letMe = model?.guideMeLetMeMode === GuideMeLetMeMode.LetMe;

  return {
    productCode,
    activityCode,
    options: {
      competenceManagement,
      guideMe,
      letMe
    }
  };
}

/**
 * Cart class for directly handling everything related to the products and their current state in the flow
 */
export default class FlowCart {
  //CRM user identifier
  private _userIdentifier: string = '';

  //List of the currently available products in the app
  private _availableItems: Item[] = [];

  //List of the items in the cart
  private _items: Item[] = [];
  
  //Main product that determines the flow steps
  public mainProduct?: ProductId;

  //Main product code that determines the flow steps
  public mainProductCode?: ProductCode;

  //General date for all items
  public coverageDate: string = moment()
    .add(getCoverageInitialDefaultDateOffset(), ZeDateTypes.Days)
    .format('DD/MM/YYYY');

  //General amounts for the ensured capitals
  public ensuredCapitals: ItemCapitalAmounts = {
    content: undefined,
    continent: undefined
  };

  /**
   * Returns the crm's user identifier
   */
  public get userIdentifier(): string {
    return this._userIdentifier || '';
  }

  /**
   * Sets the value of the crm's user identifier
   * @param {string} value
   */
  public set userIdentifier(value: string) {
    this._userIdentifier = value;
  }

  /**
   * Returns the available items in the cart
   */
  public get availableItems(): Item[] {
    return this._availableItems;
  }

  /**
   * Sets the value of the available items in the cart
   * @param {Item[]} value
   */
  public set availableItems(value: Item[]) {
    this._availableItems = value;
  }

  /**
   * Returns the items in the cart
   */
  public get items(): Item[] {
    return this._items;
  }

  /**
   * Sets the value of the items in the cart
   * @param {Item[]} value
   */
  public set items(value: Item[]) {
    this._items = value;
  }

  /**
   * Filters a passed list from the cart, based on passed options
   * @param {Item[]} itemsList
   * @param {ListFilterOptions} filterOptions
   * @returns {Item[]} filtered list
   */
  private filterList(itemsList: Item[], filterOptions: ListFilterOptions): Item[] {
    return itemsList
      .filter(item => filterOptions.onlyWithPrice
      && !item.enforce?.hidePrice
      && !!item.policyInformation?.pricing?.totalReceiptAmount
      );
  }
 
  /**
   * Returns the target list from the cart
   * @param {CartListType} listType
   * @param {ListFilterOptions} filterOptions
   * @returns {CartListType}
   */
  private getConfigList(
    listType?: CartListType,
    filterOptions?: ListFilterOptions
  ): [Item[], 'items' | 'availableItems'] {
    const currentListType = !listType || listType === CartListType.Cart ? 'items' : 'availableItems';
    const currentListCopy = cloneDeep(this[currentListType]);

    //Apply filter
    if (filterOptions) {
      const filteredList = this.filterList(currentListCopy, filterOptions);
      return [filteredList, currentListType];
    }
    return [currentListCopy, currentListType];
  }

  /**
   * Recursively sets values of the referenced object
   * @param {object} config
   * @param {object} objectReference
   */
  private recursiveConfigSet(config: {[key: string]: any}, objectReference: {[key: string]: any}): void {
    for (const confKey in config) {
      if (confKey in config) {
        const configPropertyValue = config[confKey];
        const objectReferenceConfPropValue = objectReference[confKey];
  
        // If the tested key value is an object with properties, execute the recursive set again
        if (
          typeof objectReferenceConfPropValue === 'object'
          && !Array.isArray(objectReference[confKey])
          && !isNil(objectReferenceConfPropValue)
        ) {
          this.recursiveConfigSet(configPropertyValue, objectReferenceConfPropValue);
        } else if (Array.isArray(objectReference[confKey])) {
          // If the property is an array, replace the existing elements with the new ones
          objectReference[confKey] = [...configPropertyValue];
        } else {
          // Assign the config property value to the reference of the object
          objectReference[confKey] = configPropertyValue;
        }
      }
    }
  }

  /**
   * Update Product enforceable settings
   * @param {Item} item
   */
  private updateEnforceableSettings(item: Item): void {
    const productSettings = item.productSettings as Product;
    const productStatus = getProductStatus(item);

    if (productStatus) {
      //Determines if a product's price should be shown
      const shouldHidePrice = isCurrentProductConditionsMet(
        [productSettings],
        productStatus,
        'showPrice'
      );

      item.enforce = {
        hidePrice: shouldHidePrice,
        isRecommended: false
      };
    }
  }

  /**
   * Returns the payment type of the cart items
   * @returns {string}
   */
  public getCartItemsGeneralPricingInfo(): ItemPricing | undefined {
    const cartItems = [
      this.getItemById(ProductId.Commerce, CartListType.Cart),
      this.getItemById(ProductId.Accidents, CartListType.Cart),
      this.getItemById(ProductId.RcPro, CartListType.Cart),
      this.getItemById(ProductId.RC, CartListType.Cart)
    ];

    for (const item of cartItems) {
      if (item?.policyInformation?.pricing) {
        return item.policyInformation.pricing;
      }
    }

    return undefined;
  }

  /**
   * Determines if an item present in any list, and if so in which list is present
   * @param {ProductId} id
   * @returns {CartListType | undefined}
   */
  public isItemPresentInAnyList(id: ProductId): CartListType | undefined {
    const isPresentInCartList = this.items.some(item => item.id === id);
    const isPresentInAvailableList = this.availableItems.some(item => item.id === id);
    if (isPresentInCartList) {
      return CartListType.Cart;
    } else if (isPresentInAvailableList) {
      return CartListType.Available;
    } else {
      return undefined;
    }
  }

  /**
   * Returns the total amount of the items in the cart
   * @returns {number}
   */
  public itemsTotalAmount(): number {
    return this.items.reduce((accumulated, current) => {
      if (!current.enforce?.hidePrice) {
        accumulated += getDefaultValue(current.policyInformation?.pricing?.totalReceiptAmount, 0);
      }

      return accumulated;
    }, 0);
  }

  /**
   * Returns the list of items from a list sorted by the given option
   * @param {CartSorting} sortType
   * @param {CartListType} listType
   * @param {ListFilterOptions} filterOptions
   * @returns {Item[]}
   */
  public sortedItemsList(sortType: CartSorting, listType?: CartListType, filterOptions?: ListFilterOptions): Item[] {
    const configListResult = this.getConfigList(listType, filterOptions);
    const configList = configListResult[0];

    if (sortType === CartSorting.ByPrice) {
      return configList.sort((itemA, itemB) => {
        const itemATotal = getDefaultValue(itemA.policyInformation?.pricing?.totalReceiptAmount, 0);
        const itemBTotal =getDefaultValue(itemB.policyInformation?.pricing?.totalReceiptAmount, 0);
        return itemBTotal - itemATotal;
      });
    } else if (sortType === CartSorting.ByIdAlphabetically) {
      return configList.sort((itemA, itemB) => {
        const itemAId = itemA.id;
        const itemBId = itemB.id;

        if (itemAId < itemBId) {
          return -1;
        } else if (itemAId > itemBId) {
          return 1;
        }

        return 0;
      });
    }

    return [];
  }

  /**
   * Returns an item from the cart if found, by item id.
   * If required only specific properties of the object might be returned
   * @param {string} id
   * @param {CartListType} listType
   * @param {ItemPropertyQuery} propertyQuery
   * @returns {Item}
   */
  public getItemById(id: ProductId, listType?: CartListType): Item | undefined {
    const configListResult = this.getConfigList(listType);
    const foundItem = configListResult[0].find(item => item.id === id);

    return foundItem;
  }

  /**
   * Generates the most basic form of a cart item, with default values and based on the data given
   * if one of the required properties is missing it will return false
   * @param {CardChoice} product
   * @param {string} activityCode
   * @returns {Item | false}
   */
  public generateItem(product: CardChoice, activityCode: string): Item | false {
    const technicalData = {
      optionCode: product.optionCode as string,
      ...product.productData
    };
    const productSettings = getProductSettings([product.optionCode as ProductCode]);
    const currentProduct = productSettings?.find(currProd => currProd.id === product.id);

    if (product.id && product.text && technicalData && currentProduct) {

      return {
        id: product.id as ProductId,
        name: product.text,
        additionalInformation: {
          activityCode,
          effectiveDate: ''
        },
        technicalData: technicalData as ItemTechnicalData,
        productSettings: currentProduct
      };
    }

    return false;
  }

  /**
   * Adds one item to the cart, it can add a new generated item or an already existing by id
   * @param {Item} item
   * @param {CartListType} listType
   */
  public add(item: Item | ProductId, listType?: CartListType): void {
    const configListResult = this.getConfigList(listType);
    const configListName = configListResult[1];

    if (typeof item === 'string') {
      const itemListPresence = this.isItemPresentInAnyList(item);
      const cartItem = this.getItemById(item, itemListPresence);

      if (cartItem && listType !== itemListPresence) {
        this[configListName].push(cartItem);
      }
    } else if (typeof item === 'object' && Object.keys(item).length > 0 && 'id' in item) {
      const itemListPresence = this.isItemPresentInAnyList(item.id);

      if (listType !== itemListPresence) {
        this[configListName].push(item);
      }
    }
  }

  /**
   * Removes one item from the cart, based on the config parameters given
   * @param {RemoveItemConfig} config
   * @param {CartListType} listType
   */
  public remove(config: RemoveItemConfig, listType?: CartListType): void {
    const {
      id,
      name
    } = config;

    if (id || name) {
      const configKey = Object.keys(config)[0];
      const configListResult = this.getConfigList(listType);
      const configList = configListResult[0];
      const configListName = configListResult[1];
      const itemIndex = configList.findIndex((item: {[key: string]: any}) => config[configKey] === item[configKey]);

      if (itemIndex > -1) {
        configList.splice(itemIndex, 1);
        this[configListName] = configList;
      }
    }
  }

  /**
   * Updates one item of the cart
   * @param {ItemConfig} config
   * @param {CartListType} listType
   * @param {string} path
   */
  public update(config: ItemConfig, listType?: CartListType): void {
    const {
      id
    } = config;

    if (id) {
      const configListResult = this.getConfigList(listType);
      const configList = configListResult[0];
      const configListName = configListResult[1];
      const itemIndex = configList.findIndex(item => id === item.id);

      if (itemIndex > -1) {
        const itemReference = configList[itemIndex];
        const itemCopy = cloneDeep(itemReference);

        //Id and name cannot be modified
        delete config.id;
        delete config.name;
        delete config.enforce;

        //Recursive call to read and set values of the config
        this.recursiveConfigSet(config, itemCopy);
        //Update enforceable settings of the product
        this.updateEnforceableSettings(itemCopy);
        //Set the item in the proper list
        configList.splice(itemIndex, 1, itemCopy);
        this[configListName] = configList;
      }
    }
  }

  /**
   * Reset cart to default values
   */
  public reset():void {
    this._items = [];
    this._availableItems = this.availableItems;
  }

  /**
   * Move items between different cart list types
   * @param {ProductId} itemId
   * @param {CartListType} listType of the cart list
   */
  public moveItemToList(itemId: ProductId, listType: CartListType):void {
    this.add(itemId, listType);
    this.remove({
      id:itemId
    },
    listType === CartListType.Available ? CartListType.Cart : CartListType.Available
    );
  }
}
