import {
  camelCase,
  toLower,
  uniqBy
} from 'lodash';

import {
  EnumerableObject
} from '@/utils/methods/convertEnumToObject';

import {
  StepMethods,
  StepTransition,
  steps,
  CurrentStepControl,
  Steps,
} from './stepsConfig';
import {
  InstanceOptions
} from '../base';
import {
  BrandingControl
} from '../branding/branding';
import {
  FeatureControlNavigation
} from '@/utils/interfaces';
import {
  ZeInputFields
} from '@/utils/enums';

/**
 * Class that controls the steps of a flow
 */
export class StepsControl extends BrandingControl {
  private static _firstTransitionFlag: boolean = false;

  private static _methods: StepMethods = {};

  private static _navigation: FeatureControlNavigation = {
    previousStep: 0,
    step: 0
  };

  private static isTransitionLocked: {value: boolean} = {
    value: false
  };

  /**
   * Returns the transition methods
   */
  protected static get transitionMethods(): StepMethods {
    return this._methods;
  }
  
  /**
   * Sets the value of the transition methods
   * @param {StepMethods} value
   */
  protected static set transitionMethods(value: StepMethods) {
    this._methods = value;
  }

  /**
   * Returns the first transition flag
   */
  public static get firstTransitionFlag(): boolean {
    return this._firstTransitionFlag;
  }

  /**
   * Sets the first transition flag value
   * @param {boolean} value
   */
  public static set firstTransitionFlag(value: boolean) {
    this._firstTransitionFlag = value;
  }

  /**
   * Returns the navigation object state
   */
  protected static get navigation(): FeatureControlNavigation {
    return this._navigation;
  }

  /**
   * Sets the value of the navigation object state
   * @param {object} value
   */
  protected static set navigation(value: FeatureControlNavigation) {
    this._navigation = value;
  }

  /**
   * Returns the current transitions on the current branding and current environment
   */
  protected static get currentTransitions(): StepTransition<typeof steps>[] | undefined {
    const currentTransitions = this.getCurrentOption<StepTransition<typeof steps>[]>(InstanceOptions.Transitions);
    if (currentTransitions && currentTransitions.length > 0) {
      return currentTransitions[0];
    }
    return undefined;
  }

  /**
   * Returns the current step relevant information and methods
   * @param {boolean} isPrevious
   * @returns {CurrentStepControl}
   */
  public static currentStepControl(): CurrentStepControl {
    return Object.entries(this.transitionMethods)
      .filter(method => method[1].step === this.navigation.step)
      .reduce((acc, curr): CurrentStepControl => {
        return {
          step: this.navigation.step,
          methods: {
            ...acc.methods,
            [curr[0]]: curr[1].method
          }
        };
      }, {} as unknown as CurrentStepControl);
  }

  /**
   * Executes the current step method
   * @param {number} option
   */
  public static executeTransition(option?: number): void {
    const currentStepControl = this.currentStepControl();

    if (currentStepControl?.methods && !this.isTransitionLocked.value) {
      this.isTransitionLocked.value = true;
      const methodsNamesList = Object.keys(currentStepControl.methods);
      const foundMethod = methodsNamesList
        .find(methodName => methodName.toLocaleLowerCase().indexOf(`option${option || 1}`) > -1);
  
      if (foundMethod) {
        currentStepControl.methods[foundMethod]();
      } else {
        const defaultMethod = methodsNamesList
          .find(methodName => methodName.toLocaleLowerCase().indexOf(`option${1}`) > -1) as string;
        currentStepControl.methods[defaultMethod]();
      }
    }
  }

  /**
   * Generates and returns a transition method
   * @param {object} variation
   * @param {boolean} isPrevious
   * @returns {Function}
   */
  private static generateTransitionMethod(variation: StepTransition<EnumerableObject<typeof steps>>): () => void {
    return () => {
      const {
        to: nextVariationName,
      } = variation;

      if (this.stepsList) {
        //Change the current step to the next variation step
        this.navigation.previousStep = this.navigation.step;
        this.navigation.step = (nextVariationName !== 'previous' && this.stepsList[nextVariationName]) || 0;
        this.firstTransitionFlag = true;
      }
    };
  }

  /**
   * Executes an explicit transition in which the target step is passsed to the function
   * @param {string} transition
   * @param {number} option
   */
  public static doExplicitTransition(transition: keyof typeof steps, option?: number): void {
    if (this.currentTransitions && this.stepsList && !this.isTransitionLocked.value) {
      this.isTransitionLocked.value = true;
      const foundTransitionsList = this.currentTransitions.filter(current => current.name === transition);
      if (foundTransitionsList && foundTransitionsList.length > 0) {
        const {
          name: expectedTransitionName
        } = foundTransitionsList.find(current => current.option === option) || foundTransitionsList[0];
        this.navigation.previousStep = this.navigation.step;
        this.navigation.step = this.stepsList[expectedTransitionName];
        this.firstTransitionFlag = true;
      }
    }
  }

  /**
   * Executes a transition to the previous step of the current step
   */
  public static doPreviousTransition(): void {
    if (this.firstTransitionFlag && !this.isTransitionLocked.value) {
      this.isTransitionLocked.value = true;
      this.navigation.step = this.navigation.previousStep;
    }
  }

  /**
   * Initializes the navigation with the first step of the flow
   * @param {string} previousName
   * @param {string} variationName
   */
  private static initNavigation(previousName: string, variationName: keyof typeof steps): void {
    if (previousName === 'init' && this.stepsList) {
      this.navigation.step = this.stepsList[variationName];
    }
  }

  /**
   * Executes the creation of the transition methods
   * @param {string} currentStepName
   * @param {string} variationName
   * @param {object} variation
   * @param {number} option
   */
  private static initMethodsGeneration(
    currentStepName: keyof typeof steps,
    variationName: keyof typeof steps,
    variation: StepTransition<EnumerableObject<typeof Steps>>,
    option: number | undefined
  ): void {
    if (currentStepName === variationName) {
      const methodName = camelCase(toLower(`${currentStepName} option ${option || 1}`));
      const method = this.generateTransitionMethod(variation);

      //Current transition method
      this.transitionMethods = {
        ...this.transitionMethods,
        [methodName]: {
          step: (this.stepsList && this.stepsList[currentStepName]) || 0,
          method
        }
      };
    }
  }

  /**
   * Initializes the methods to execute the transitions
   */
  private static initMethods(): void {
    if (this.currentTransitions) {
      for (const transition of uniqBy(this.currentTransitions, ZeInputFields.Name)) {
        const {
          name: currentStepName,
        } = transition;
  
        //Check for the possible variations of a step
        for (const variation of this.currentTransitions) {
          const {
            name: variationName,
            from: previousName,
            option
          } = variation;
  
          //Select the first step of the flow
          this.initNavigation(previousName as string, variationName);
          //Generate the transition methods and assign them to the transition methods map
          this.initMethodsGeneration(currentStepName, variationName, variation, option);
        }
      }
    }
  }

  /**
   * Loads the ea flow navigation instance into the step control instance
   * @param {object} navigation
   * @param {object} isTransitionLocked
   */
  public static loadNavigation(navigation: FeatureControlNavigation, isTransitionLocked: {value: boolean}): void {
    this.navigation = navigation;
    this.isTransitionLocked = isTransitionLocked;
    this.initMethods();
  }

  /**
   * Updates the transitions and refreshes the Transition Methods
   * @param {object} transitions
   */
  public static updateTransitions(transitions: StepTransition<typeof steps>[]): void {
    this.transitions = {
      [this.currentEnvironment]: {
        [this.currentBranding]: transitions
      }
    };
    this.initMethods();
  }
}
