/* eslint-disable max-lines */
import {
  EAApplicationLogger,
  EACorporateTableService,
  EAError,
  EALocaleManager
} from '@zurich-es-npm/ea-front-web-core';
import {
  QuoteAndBuyResponseDatosEconomicosValoresPorOpcion
} from '@/services/V1/getPrice/quoteAndBuyOperation/post/api';
import {
  GetPriceModel,
  ProductId
} from '../../get-price-model';
import {
  CoversAccidentsStructure,
  CoversDataItem,
  CoversDataStructure,
  CoversShopsStructure,
  CoversShopsFranchisesStructure,
  CoverStructureType,
  CoverSummaryBlock,
  CoverSummaryItem,
  CorpTableRelationships,
  FixedCoverElements,
  CoverElement,
  CoverValues,
  CoverValueTypes,
  VisibilityCondition,
  CoverElementCondition,
  ReturnedValueSource
} from './covers-summary-data-structure';
import {
  getCurrentAppliedBranding,
  BrandingOptions
} from '@/locales/branding';
import {
  replaceCharacter
} from '../../../../../utils/functions';
import Cart, {
  CartListType,
  ValuesPerOption
} from '@/utils/cart';

interface TableDocument {
  [key: string]: string;
}
interface CorporateTableData {
  data: {
    tableCode: string;
    tableDocuments: TableDocument[];
  } | undefined;
}

/**
 * Covers summary utilities
 */
export default class CoversSummaryUtils {

  public static corpTablesData: { [key: string]: TableDocument[] } = {};

  /**
   * Returns if the given coverage (by its code or codes) is in the ws response and must be included or not.
   * 
   * If codes is an array, it will be treated as OR coincidences, so if at least one code is found
   * in the coverage, cover will be returned as found and first found cover will be returned.
   * 
   * @param {ValuesPerOption[]} coverage coverages received by ws
   * @param {string | string[]} code code or codes to match
   * @returns {QuoteAndBuyResponseDatosEconomicosValoresPorOpcion} found coverage or undefined
   */
  public static findCover(
    coverage: ValuesPerOption[],
    code: string | string[]
  ): ValuesPerOption | undefined {
    if (Array.isArray(code) && code.length === 0) {
      return undefined;
    }
    if (Array.isArray(code)) {
      return coverage.find(cover => code.indexOf(cover.codeMCT || '') !== -1);
    } else {
      return coverage.find(cover => cover.codeMCT === code);
    }
  }

  /**
   * Formats an amount.
   * Input example: '000000000018326'
   * Output example: '18.326€'
   * 
   * @param {string} amount integer quantity
   * @returns {string} formatted amount
   */
  public static formatIntegerAmount(amount: string): string {
    if (amount) {
      return `${parseInt(amount).toLocaleString(EALocaleManager.i18n.locale, {
        maximumFractionDigits: 0
      })} €`;
    } else {
      return '';
    }
  }

  /**
   * Returns if a given risk factor has been selected by the user previously in the flux.
   * 
   * @param {string} riskCode risk to evaluate
   * @param {GetPriceModel} model
   * @returns {boolean} if the risk has been selected by the user or not
   */
  public static isRiskFactorSelected(riskCode: string, model: GetPriceModel): boolean {
    const selectedRisks = Object.entries(model.selectedFactorsData).filter(entry => entry[1] === 'S')
      .map(entry => entry[0]);

    const riskFactor = Object.entries(model.businessActivity.options
      ? model.businessActivity.options.factorActivityRelationData || {}
      : {}
    )
      .filter(relation => relation[0].indexOf('CDFACTO') !== -1)
      .find(relation => relation[1] === riskCode);

    if (riskFactor === undefined) {
      return false;
    } else {
      const riskPosition = riskFactor[0];
      return selectedRisks.indexOf(riskPosition) !== -1;
    }
  }

  /**
   * Determine if a cover must be shown or not and return the value to show
   * 
   * @param {CoversDataItem} coverStruct cover structure to evaluate
   * @param {GetPriceModel} model
   * @param {QuoteAndBuyResponseDatosEconomicosValoresPorOpcion} wsCover (optional) web service cover matched
   * @returns {Object} if cover must be shown and the value to show
   */
  public static showCoverByExplicitCondition(
    coverStruct: CoversDataItem,
    model: GetPriceModel,
    wsCover?: QuoteAndBuyResponseDatosEconomicosValoresPorOpcion
  ): { show: boolean; valueToShow: string | CoverValues } {
    const HOME_WORK_RISK_FACTOR = '0009';
    const HOME_DELIVERY_RISK_FACTOR = '0037';
    const PHARMACY_ACTIVITY = 751740;
    const DEFAULT_RECEIPT_DAMAGE_AMOUNT = '3000';

    if (coverStruct.type === CoverStructureType.ConditionFixed) {
      switch (coverStruct.id) {
        case 'shops-trabajo-casa':
          if (this.isRiskFactorSelected(HOME_WORK_RISK_FACTOR, model)) {
            return {
              show: true,
              valueToShow: coverStruct.value
            };
          } else {
            return {
              show: false,
              valueToShow: ''
            };
          }
        case 'shops-entrega-domicilio':
          if (this.isRiskFactorSelected(HOME_DELIVERY_RISK_FACTOR, model)) {
            return {
              show: true,
              valueToShow: coverStruct.value
            };
          } else {
            return {
              show: false,
              valueToShow: ''
            };
          }
        case 'shops-danyos-recetas':
          if (parseInt(model.businessActivity.value) === PHARMACY_ACTIVITY) {
            return {
              show: true,
              valueToShow: this.formatIntegerAmount(
                wsCover && wsCover.datosElemento
                  ? (parseInt(wsCover.datosElemento.CPPRECTI as unknown as string)
                    || DEFAULT_RECEIPT_DAMAGE_AMOUNT).toString()
                  : DEFAULT_RECEIPT_DAMAGE_AMOUNT
              )
            };
          } else {
            return {
              show: false,
              valueToShow: ''
            };
          }
        default:
          return {
            show: false, valueToShow: ''
          };
      }
    } else {
      return {
        show: false, valueToShow: ''
      };
    }
  }

  /**
   * Get the list of all needed corporate tables
   * @returns {string[]} array of corporate table codes
   */
  public static getListOfCorpTables(): string[] {
    const listOfCorpTables: string[] = [];
    // Covers capitals
    [...CoversShopsStructure, ...CoversAccidentsStructure].forEach(block => {
      block.covers.forEach(cover => {
        if (cover.corporateTable && listOfCorpTables.indexOf(cover.corporateTable) === -1) {
          listOfCorpTables.push(cover.corporateTable);
        }

        if (
          cover.fixedElements &&
          cover.fixedElements.length > 0 &&
          cover.fixedElements
            .some(
              element => !!(element.conditionDependant &&
              element.conditionDependant === CoverElementCondition.CorporateTableValue)
            )
        ) {
          cover.fixedElements.forEach(element => {
            const {
              returnedValue
            } = element;

            if (
              returnedValue &&
              returnedValue[0] &&
              listOfCorpTables.indexOf(returnedValue[0]) === -1 &&
              returnedValue[2] === ReturnedValueSource.corpTable
            ) {
              listOfCorpTables.push(returnedValue[0]);
            }
          });
        }
      });
    });

    // Covers franchises
    CoversShopsFranchisesStructure.forEach(franch => {
      if (franch.corporateTable && listOfCorpTables.indexOf(franch.corporateTable) === -1) {
        listOfCorpTables.push(franch.corporateTable);
      }
    });

    return listOfCorpTables;
  }

  /**
   * Fetch all corporate tables
   * 
   * @returns {Promise} 
   */
  public static async fetchAllNeededCorpTables(): Promise<void> {
    const tablesFetchArray: CorporateTableData[] = [];
    this.getListOfCorpTables().forEach(code => {
      tablesFetchArray.push(new EACorporateTableService(code).fetchCorporateTable() as unknown as CorporateTableData);
    });
    try {
      const tableResults = await Promise.all(tablesFetchArray);
      const temporalTableData: { [key: string]: TableDocument[] } = {};
      tableResults.forEach(table => {
        if (table && table.data) {
          temporalTableData[table.data.tableCode] = table.data.tableDocuments;
        }
      });
      CoversSummaryUtils.corpTablesData = temporalTableData;
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `CoversSummaryUtils::fetchAllNeededCorpTables:: fetch corporate table :: ${eaError.message}`
      );
    }
  }

  /**
   * Get value for the given cover using corresponding corporate table.
   * 
   * @param {CoversDataItem} coverStruct
   * @param {QuoteAndBuyResponseDatosEconomicosValoresPorOpcion} wsCover
   * @returns {string} the value to render in the UI
   */
  public static getCoverValueFromCorpTable(
    coverStruct: CoversDataItem,
    wsCover: ValuesPerOption
  ): string {
    if (
      coverStruct &&
      coverStruct.value &&
      coverStruct.corporateTable &&
      wsCover &&
      wsCover.elementsData &&
      wsCover.elementsData[coverStruct.value as string]
    ) {
      const tableRelationship = CorpTableRelationships[coverStruct.corporateTable];
      const foundTableDocument = (CoversSummaryUtils.corpTablesData[coverStruct.corporateTable] || [])
        .find(doc => {
          if (wsCover.elementsData) {
            return doc[tableRelationship.codeField] === wsCover.elementsData[coverStruct.value as string]
              && doc.CDDIVISA === tableRelationship.CDDIVISA;
          } else {
            return false;
          }
        });
      return foundTableDocument ? foundTableDocument[tableRelationship.valueField] : '';
    } else {
      return '';
    }
  }

  /**
   * Remove dots from the amount in order to be used by formatIntegerAmount function if value is a number.
   * Otherwise, return the value itself without any transformation.
   * 
   * @param {string} corpTableAmount amount from corporate table
   * @returns {string} amount formatted
   */
  public static convertCorpTableAmountToAmount(corpTableAmount: string): string {
    if (isNaN(parseInt(corpTableAmount))) {
      return corpTableAmount;
    } else {
      return this.formatIntegerAmount(corpTableAmount.replace('.', ''));
    }
  }

  /**
   * Returns the value for specially recovered franchises
   * @param {CoversDataItem} coverStruct
   * @param {QuoteAndBuyResponseDatosEconomicosValoresPorOpcion} cover
   * @returns {string | undefined}
   */
  public static getSpecificFranchiseValue(
    coverStruct: CoversDataItem,
    cover: ValuesPerOption
  ): string | undefined {
    const franchiseRelations = coverStruct.specialFranchiseValue;
    const coverElements = cover.elementsData;

    if (franchiseRelations && franchiseRelations.length > 0 && coverElements) {
      const isEnabledIdentifier = franchiseRelations[0];
      const franchiseValueHolder = franchiseRelations[1];
      const intrfuloValue = coverElements[isEnabledIdentifier] as string;

      if (intrfuloValue === 'S') {
        const cdfrqtrfValue = coverElements[franchiseValueHolder] as string;
        return cdfrqtrfValue;
      } else {
        return undefined;
      }
    }

    return undefined;
  }

  /**
   * Processes the values for the elements of a cover
   * @param {FixedCoverElements} element
   * @param {Cart} cart
   * @param {ValuesPerOption[]} coverages
   * @returns {object}
   */
  public static processElementValues(
    element: FixedCoverElements,
    cart: Cart,
    coverages: ValuesPerOption[],
  ): {content: number | string; continent: number | string} {
    const capitalAmounts = cart.ensuredCapitals;
    const {
      conditionDependant,
      returnedValue,
      fixedContentValue,
      fixedContinentValue,
      fixedValue,
      WSPropName
    } = element;
    const elementWSReference = returnedValue as [string, string, ReturnedValueSource];
    const contentCapital = capitalAmounts.content;
    const containerCapital = capitalAmounts.continent;

    let result: {content: number | string; continent: number | string} = {
      content: 0,
      continent: 0
    };

    if (conditionDependant) {
      switch (conditionDependant) {
        case CoverElementCondition.ContentCapital: {
          result = {
            content: (fixedContentValue && contentCapital) || 0,
            continent: (fixedContinentValue && containerCapital) || 0
          };
        }
          break;

        case CoverElementCondition.ContinentCapital: {
          result = {
            content: (fixedContentValue && contentCapital) || 0,
            continent: (fixedContinentValue && containerCapital) || 0
          };
        }
          break;

        case CoverElementCondition.WSValue: {
          const WSFoundMatch = coverages && coverages.find(cover => cover.codeMCT === elementWSReference[0]);
          const elements = WSFoundMatch && WSFoundMatch.elementsData;
          
          if (elementWSReference[2] === ReturnedValueSource.revealExistance) {
            if (elements && elements[elementWSReference[1]] && elements[elementWSReference[1]] !== 'N') {
              if (fixedValue) {
                result = {
                  content: (fixedContentValue && contentCapital && fixedValue) || '',
                  continent: (fixedContinentValue && containerCapital && fixedValue) || ''
                };
              } else {
                result = {
                  content: (fixedContentValue && contentCapital) || 0,
                  continent: (fixedContinentValue && containerCapital) || 0
                };
              }
            }
            //Default Val
          } else if (elementWSReference[2] === ReturnedValueSource.directWS) {
            if (elements && elements[elementWSReference[1]]) {
              result = {
                content: (fixedContentValue && contentCapital && Number(elements[elementWSReference[1]])) || 0,
                continent: (fixedContinentValue && containerCapital && Number(elements[elementWSReference[1]])) || 0
              };
            }
          }
        }
          break;

        case CoverElementCondition.CorporateTableValue: {
          if (WSPropName && WSPropName.length > 0 && returnedValue && returnedValue.length > 0) {
            const WSFoundMatch = coverages && coverages.find(cover => cover.codeMCT === WSPropName[0]);
            const elements = WSFoundMatch && WSFoundMatch.elementsData;
            if (elements) {
              const neededWSPropValue = elements[WSPropName[1]];
              const neededCorpTable = CoversSummaryUtils.corpTablesData[returnedValue[0]];
              const neededDocument =
                neededCorpTable &&
                neededCorpTable.find(doc => doc[returnedValue[1]] === neededWSPropValue);

              if (neededDocument) {
                if (returnedValue[0] === 'KT5F31S') {
                  result = {
                    content:
                      (
                        fixedContentValue
                        && contentCapital
                        && `${replaceCharacter(neededDocument.DSDESCR1, ',00', ' €')} por siniestro y año`
                      ) || '',
                    continent:
                      (
                        fixedContinentValue
                        && containerCapital
                        && `${replaceCharacter(neededDocument.DSDESCR1, ',00', ' €')} por siniestro y año`
                      ) || ''
                  };
                } else if (returnedValue[0] === 'KT0106S') {
                  result = {
                    content:
                    (
                      fixedContentValue
                      && neededDocument.DSDESCR2
                    ) || '',
                    continent:
                    (
                      fixedContinentValue
                      && neededDocument.DSDESCR2
                    ) || ''
                  };
                }
              }
            }
          }
        }
          break;
      
        default:
          break;
      }
      //This case is for when there is a fixed valule that doesnt depend on ws or corp table
    } else if (fixedValue) {
      result = {
        content: (fixedContentValue && contentCapital && fixedValue) || 0,
        continent: (fixedContinentValue && containerCapital && fixedValue) || 0
      };
    } else {
      result = {
        content: (fixedContentValue && contentCapital) || 0,
        continent: (fixedContinentValue && containerCapital) || 0
      };
    }

    return result;
  }

  /**
   * Returns the title of the coverage
   * @param {FixedCoverElements} fixedElement
   * @param {ValuesPerOption[]} coverages
   * @returns {string}
   */
  private static getCoverTitle(
    fixedElement: FixedCoverElements,
    coverages: ValuesPerOption[]
  ): string {
    if (fixedElement && fixedElement.name) {
      if (typeof fixedElement.name === 'string') {
        return fixedElement.name;
      } else {
        const {
          mctCode,
          propertyName,
          possibleValues
        } = fixedElement.name;

        const WSFoundMatch = coverages && coverages.find(cover => cover.codeMCT === mctCode);
        const elements = WSFoundMatch && WSFoundMatch.elementsData;
        const foundElement = elements && elements[propertyName];
        const currentValue = possibleValues.find(val => val[0] === foundElement);
        return (currentValue && currentValue[1]) || '';
      }
    }

    return '';
  }

  /**
   * Generates a list of elements with their values
   * @param {Cart} cart
   * @param {CoversDataItem} coverStruct
   * @param {[]} coverages
   * @returns {any[]}
   */
  public static generateFixedElementsValues(
    cart: Cart,
    coverStruct: CoversDataItem,
    coverages: ValuesPerOption[]
  ): CoverElement[] {
    const fixedElements = coverStruct && coverStruct.fixedElements as FixedCoverElements[];
    return fixedElements
      .filter(fixedElement => {
        if (fixedElement.conditionDependant) {
          const {
            content,
            continent
          } = this.processElementValues(fixedElement, cart, coverages);
          return !!content || !!continent;
        }
        return true;
      }).map(fixedElement => {
        const {
          content,
          continent
        } = this.processElementValues(fixedElement, cart, coverages);
        const styleMetadata = {
          containedInTwoColumns: fixedElement.containedInTwoColumns
        };
        const coverTitle = this.getCoverTitle(fixedElement, coverages);

        return {
          name: coverTitle,
          contentValue: (fixedElement.fixedContentValue && content) || 0,
          continentValue: (fixedElement.fixedContinentValue && continent) || 0,
          styleMetadata: Object.keys(styleMetadata).length > 0 ? styleMetadata : undefined,
          hintText: `getPrice.coversSummaryStep.coversSummary.elementsTooltip.${coverTitle}`
        };
      });
  }

  /**
   * Returns the names of the elements of a cover
   * @param {Cart} cart
   * @param {CoversDataItem} coverStruct
   * @param {ValuesPerOption[]} coverages
   * @returns {string[]}
   */
  public static getCoverElements(
    cart: Cart,
    coverStruct: CoversDataItem,
    coverages: ValuesPerOption[],
  ): CoverElement[] {
    if (coverStruct.fixedElements && coverStruct.fixedElements.length > 0) {
      return this.generateFixedElementsValues(cart, coverStruct, coverages);
    }
    return [];
  }

  /**
   * Gets the fixed values of a cover
   * @param {Cart} cart
   * @param {CoversDataItem} coversTruct
   * @returns {object | string}
   */
  public static getCoverFixedValues(
    cart: Cart,
    coversTruct: CoversDataItem
  ): {content: string; continent: string} | string {
    const commerceCartItem = cart.getItemById(ProductId.Commerce, CartListType.Cart);
    const commercePolicyInfo = commerceCartItem && commerceCartItem.policyInformation;
    const capitalAmounts = cart.ensuredCapitals;
    const {
      value,
      code,
      WSProperty
    } = coversTruct;

    if (typeof value === 'string') {
      return value;
    } else if (value && 'content' in value && 'continent' in value) {
      if (
        value.continent === CoverValueTypes.FixedContinentCapitalValue ||
        value.content === CoverValueTypes.FixedContentCapitalValue
      ) {
        const contentCapital = capitalAmounts.content;
        const containerCapital = capitalAmounts.continent;

        return {
          content: (value.content && contentCapital && contentCapital.toString()) || '',
          continent: (value.continent && containerCapital && containerCapital.toString()) || ''
        };
      } else if (
        value.continent === CoverValueTypes.IncludedAddon ||
        value.content === CoverValueTypes.IncludedAddon
      ) {
        return {
          continent: 'Incluido (ver cláusula)',
          content: ''
        };
      } else if (
        value.content === CoverValueTypes.WSPropertyValue ||
        value.continent === CoverValueTypes.WSPropertyValue
      ) {
        const WSPropertyByMCTCode =
          commercePolicyInfo
          && commercePolicyInfo.valuesPerOption
          && commercePolicyInfo.valuesPerOption.find(prop => prop.codeMCT === code);

        if (WSPropertyByMCTCode && WSProperty) {
          const WSPropValue =
            WSPropertyByMCTCode.elementsData &&
            WSPropertyByMCTCode.elementsData[WSProperty] as string;

          return {
            continent: (value.continent && Number(WSPropValue).toString()) || '',
            content: (value.content && Number(WSPropValue).toString()) || ''
          };
        }
      } else {
        return {
          continent: value.continent as string,
          content: value.content as string
        };
      }
    }

    return '';
  }

  /**
   * Returns the information of a specific cover, based on certain conditions that may apply
   * @param {GetPriceModel} model
   * @param {Cart} cart
   * @param {CoversDataItem} coverStruct
   * @param {ValuesPerOption[]} coverage
   * @returns {CoverSummaryItem}
   */
  public static getCoverInformation(
    model: GetPriceModel,
    cart: Cart,
    coverStruct: CoversDataItem,
    coverage: ValuesPerOption[]
  ): CoverSummaryItem | undefined {
    //To get the text and hintText we use the same process for every type of cover structure
    const text = `getPrice.coversSummaryStep.coversSummary.covers.${coverStruct.id}`;
    const hintText = `getPrice.coversSummaryStep.coversSummary.coversTooltip.${coverStruct.id}`;
    const coverStructValue = coverStruct.value;
    const specialFormatting = coverStruct.specialFormatting;
    const styleMetadata = {
      containedInTwoColumns: coverStruct.containedInTwoColumns
    };
    const coverElements = this.getCoverElements(cart, coverStruct, coverage);
    const coverData = {
      text,
      hintText,
      coverElements,
      styleMetadata: Object.keys(styleMetadata).length > 0 ? styleMetadata : undefined
    };

    if (coverStruct.type === CoverStructureType.AlwaysFixed) {
      return {
        ...coverData,
        value: this.getCoverFixedValues(cart, coverStruct)
      };
    } else if (coverStruct.type === CoverStructureType.ConditionFixed) {
      const evaluatedCondition = this.showCoverByExplicitCondition(coverStruct, model);
      if (evaluatedCondition.show) {
        return {
          ...coverData,
          value: coverStructValue
        };
      }
    } else if (coverage) {
      const foundCover = this.findCover(coverage, coverStruct.code);

      if (foundCover) {
        switch (coverStruct.type) {
          // Given amount by ws (web service)
          case CoverStructureType.Amount: {
            return {
              ...coverData,
              value: foundCover.elementsData
                ? this.formatIntegerAmount(foundCover.elementsData[coverStructValue as string])
                : '',
            };
          }
          // 'Included' fixed value if given code
          case CoverStructureType.Included: {
            return {
              ...coverData,
              value: EALocaleManager.i18n.t('getPrice.coversSummaryStep.coversSummary.included').toString(),
            };
          }
          // Fixed value if given code
          case CoverStructureType.Fixed: {
            return {
              ...coverData,
              value: this.getCoverFixedValues(cart, coverStruct) //CoverStructValue
            };
          }
          // Fully conditioned cover
          case CoverStructureType.Condition: {
            const evaluatedCondition = this.showCoverByExplicitCondition(coverStruct, model);
            if (evaluatedCondition.show) {
              return {
                ...coverData,
                value: evaluatedCondition.valueToShow,
              };
            }
            break;
          }
          case CoverStructureType.CorpTableAmount: {
            const valueToRender = this.getCoverValueFromCorpTable(coverStruct, foundCover);
            const convertedAmount = CoversSummaryUtils.convertCorpTableAmountToAmount(valueToRender);
            return {
              ...coverData,
              value: valueToRender
                ? `${convertedAmount}${specialFormatting ? ` ${specialFormatting}` : ''}`
                : '-',
            };
          }
          case CoverStructureType.CorpTableValue: {
            const valueToRender = this.getCoverValueFromCorpTable(coverStruct, foundCover);
            return {
              ...coverData,
              value: valueToRender || '-',
            };
          }

          case CoverStructureType.SpecificFranchiseValue: {
            const franchiseValue = this.getSpecificFranchiseValue(coverStruct, foundCover);

            if (franchiseValue) {
              return {
                ...coverData,
                value: {
                  content: '',
                  continent: ''
                },
                franchiseValue
              };
            }

            return undefined;
          }

          default:
            break;
        }
      }
    }

    return undefined;
  }

  /**
   * @param {ValuesPerOption[]} coverage received coverages by tarification
   * @param {CoversDataStructure[]} dataStructure data structure to use to as basis
   * @param {GetPriceModel} model
   * @param {Cart} cart
   * @returns {CoverSummaryBlock[]} blocks to render
   */
  public static mountCoversUsingDataStructure(
    coverage: ValuesPerOption[],
    dataStructure: CoversDataStructure[],
    model: GetPriceModel,
    cart: Cart
  ): CoverSummaryBlock[] {
    const returnedBlocks: CoverSummaryBlock[] = dataStructure
      .filter(block => {
        if (block.visibilityCondition) {
          switch (block.visibilityCondition) {
            case VisibilityCondition.AddonDuo:
              return model.addonsModel.duo.isActive;
            
            case VisibilityCondition.AddonDelivery:
              return model.addonsModel.delivery.isActive;

            case VisibilityCondition.AddonWorkFromHome:
              return model.addonsModel.workFromHome.isActive;
          
            default:
              break;
          }
        }

        return true;
      })
      .map(block => {
        return {
          title: block.title,
          covers: CoversSummaryUtils.filterCoversStructureByBranding(block.covers)
            .filter(coverStruct => {
              const visibilityCondition = coverStruct.visibilityCondition;

              if (visibilityCondition) {
                switch (visibilityCondition) {
                  case VisibilityCondition.AddonDuo: {
                    return model.addonsModel.duo.isActive;
                  }
                  
                  case VisibilityCondition.AddonDelivery: {
                    return model.addonsModel.delivery.isActive;
                  }
          
                  case VisibilityCondition.AddonWorkFromHome: {
                    return model.addonsModel.workFromHome.isActive;
                  }
                
                  default:
                    break;
                }
              }
              return true;
            })
            .map(coverStruct => {
              const coverData = this.getCoverInformation(model, cart, coverStruct, coverage);

              if (coverData) {
                return coverData;
              }
              return undefined;
            })
            .filter(cover => cover !== undefined) as CoverSummaryItem[]
        };
      });
    return this.translateNeededValues(returnedBlocks);
  }

  /**
   * Translate values that need to be translated (starts with `$t:`).
   * @param {CoverSummaryBlock[]} coversData
   * @returns {CoverSummaryBlock[]} same data with translated values
   */
  public static translateNeededValues(coversData: CoverSummaryBlock[]): CoverSummaryBlock[] {
    coversData.forEach(block => {
      block.covers.forEach(cover => {
        if (cover.value && typeof cover.value === 'string' && cover.value.indexOf('$t:') === 0) {
          cover.value = EALocaleManager.i18n.t(cover.value.split('$t:')[1]).toString();
        }
      });
    });
    return coversData;
  }

  /**
   * Filter covers by current branding.
   * @param {CoversDataItem[]} covers
   * @returns {CoversDataItem[]} filtered covers
   */
  public static filterCoversStructureByBranding(covers: CoversDataItem[]): CoversDataItem[] {
    const currBrandingStr = getCurrentAppliedBranding();
    const currBrandingEnumValue
      = Object.values(BrandingOptions).find(brOpt => brOpt === currBrandingStr) || BrandingOptions.Zurich;
    return covers.filter(cov => !cov.brandings
        || (Array.isArray(cov.brandings) && cov.brandings.length && cov.brandings.indexOf(currBrandingEnumValue) !== -1)
    );
  }

}
