import {
  IdTypeEnum
} from '@/utils/validators/id-validation';

export enum ClientTypeEnum {
  Company = 'Company',
  Freelance = 'Freelance'
}


export interface CompanyDataModel {
  companyNif: string;
  documentType: string;
  name: string;
  surname: string;
  legalName: string;
  businessName: string;
  email: string;
  contactPhone: string;
}

export interface FreelanceDataModel {
  idDocument: string;
  documentType: {
    value: string;
    key: string;
  };
  originCountry: {
    value: string;
    key: string;
  };
  name: string;
  surname: string;
  surname2?: string;
  businessName: string;
  email: string;
  contactPhone: string;
}

export interface OnlinePaymentUserDataModel {
  clientType?: ClientTypeEnum;
  companyData?: CompanyDataModel;
  freelanceData?: FreelanceDataModel;
}

export const emptyCompanyData: CompanyDataModel = {
  companyNif: '',
  documentType: '',
  name: '',
  surname: '',
  legalName: '',
  businessName: '',
  email: '',
  contactPhone: ''
};

export const emptyFreelanceData: FreelanceDataModel = {
  idDocument: '',
  name: '',
  surname: '',
  surname2: '',
  businessName: '',
  documentType: {
    key: 'N',
    value: IdTypeEnum.DNI,
  },
  originCountry: {
    key:'ESP',
    value:'España'
  },
  email: '',
  contactPhone: ''
};

export const emptyOnlinePaymentUserData = {};
