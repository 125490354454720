import {
  FeatureControl
} from '../features/featureControl';

/**
 * Returns the cookies id for the current branding
 * @returns {string | undefined}
 */
export default function getCookiesId(): string | undefined {
  return FeatureControl.getBrandingSettings
    .bind(FeatureControl)
    .call(FeatureControl, 'cookies') as string | undefined;
}
