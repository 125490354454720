import {
  Links
} from '@/utils/interfaces';
import {
  FeatureControl
} from '../features/featureControl';

/**
 * Returns a given link for the current branding
 * @param {string} linkType
 * @returns {string}
 */
export default function getSiteLink(linkType: keyof Links): Links[keyof Links] | string {
  const currentLinks = FeatureControl.getBrandingSettings
    .bind(FeatureControl)
    .call(FeatureControl, 'links') as Links | undefined;

  if (currentLinks) {
    return currentLinks[linkType];
  }

  return '';
}
