export interface SetPropertyValueOptions {
  isPropertyExistanceRequired?: boolean; //By default required
  undefinedOrNullAllowed?: boolean; //By default allowed
}

/**
 * Sets the value of an object property
 * @param {O} reference
 * @param {string} propertyName
 * @param {unknown} value
 * @param {SetPropertyValueOptions} options
 */
export default function setObjectProperty<O>(
  reference: O,
  propertyName: keyof O,
  value: O[keyof O],
  options?: SetPropertyValueOptions
): void {
  if (options) {
    //Is property existance required option
    if ('isPropertyExistanceRequired' in options && options.isPropertyExistanceRequired && propertyName in reference) {
      reference[propertyName] = value;
    }
    //Is undefined or null allowed
    if ('undefinedOrNullAllowed' in options) {
      if (options.undefinedOrNullAllowed) {
        reference[propertyName] = value;
      } else if (value) {
        reference[propertyName] = value;
      }
    }
  } else if (propertyName in reference) {
    //Default functionality
    reference[propertyName] = value;
  }
}
