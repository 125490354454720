<template>
  <div>
    <ea-web-app-layout
     :class="[
      'default-public-layout'
      ]"
      :showHeader="false"
      :showFooter="true"
    >
      <template slot="footerLeftLayout">
        <div class="d-display-flex d-align-items-center footer-container">
          <p><a :href="privacyPolicyLink" target="_blank">{{ $t('layout.footer.privacyPolicy') }}</a></p>
          <p><a @click="goToAppPolicies" target="_blank">{{ $t('layout.footer.cookiesPolicy') }}</a></p>
          <p><a :href="hiringPolicyLink" target="_blank">{{ $t('layout.footer.hiringPolicy') }}</a></p>
          <p v-if="currentAppliedBranding === 'orange'">
            <a :href="informativeNoteLink" target="_blank">{{ $t('layout.footer.informativeNote') }}</a>
          </p>
          <p>
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{ $t('layout.footer.cookiesSettings') }}</button>
          </p>
        </div>
      </template>
    </ea-web-app-layout>
  </div>
</template>

<script lang='ts'>
import {
  Vue,
  Component
} from 'vue-property-decorator';

import {
  vApplication as vApp
} from '../config/config';

import {
  EaWebAppLayout,
} from '@zurich-es-npm/ea-front-web-core';
import {
  getCurrentAppliedBranding
} from '@/locales/branding';
import {
  SiteLinksTypes
} from '@/utils/enums';
import getSiteLink from '@/helpers/feature-control/methods/getSiteLink';

import {
  doExplicitTransition
} from '@/helpers/feature-control/features/featureControl';

@Component({
  components: {
    'ea-web-app-layout': EaWebAppLayout
  }
})

/**
 * Application default public layout
 */
export default class DefaultPublicLayout extends Vue {

  private vApplication: string = vApp.version;

  currentAppliedBranding = getCurrentAppliedBranding();

  privacyPolicyLink = getSiteLink(SiteLinksTypes.PrivacyPolicy);

  hiringPolicyLink = getSiteLink(SiteLinksTypes.HiringPolicy);

  cookiesPolicyLink = getSiteLink(SiteLinksTypes.CookiesPolicy);

  informativeNoteLink = getSiteLink(SiteLinksTypes.InformativeNote);

  doExplicitTransition = doExplicitTransition;

  /**
   * Executes the explicit transition to the app policies page
   */
  goToAppPolicies() {
    this.doExplicitTransition('AppPolicies');
  }

}
</script>

<style lang="scss" scoped>

::v-deep footer {
  margin-top: 70px;
  background-color: #f2f5f8;
  height: 40px;
  padding: 14px 48px 14px 48px;

  
  p {
    color: #000066;
    font-size: 12px;
    padding: 0px 23px 0px 23px;
    line-height: 12px;
    border-right: 1px solid #000766;
    a {
      color: #000066;
      font-weight: normal;
      cursor: pointer;
    }
  }

  div {
    background-color: #f2f5f8;
  }

  div :first-child {
    padding-left: 0px;
  }

  div :last-child {
    border-right: 0px;
    padding-right: 0;
  }

  .ea-footer {
    padding: 0px;
  }
}

@media only screen and (max-width: 767px) {
    
  ::v-deep footer {
    display: none;
  }
}

</style>
