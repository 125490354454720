import {
  PictureAssetsMap
} from '@/utils/interfaces';

/** 
 * Model img-loader
 */
class ImgLoaderModel {

  public picturesMap: PictureAssetsMap = {};
}

export default ImgLoaderModel;
