import {
  EALocaleManager,
  EABrandingManager
} from '@zurich-es-npm/ea-front-web-core';

import loadLocaleMessages from './i18n';

// @ts-ignore avoid false-positive error about not finding the module
import zurichStyles from '../assets/scss/zurich-global/base/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import orangeStyles from '../assets/scss/orange/base/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import pimecStyles from '../assets/scss/brokers/pimec/base/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import asegasaStyles from '../assets/scss/brokers/asegasa/base/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import brokersStyles from '../assets/scss/brokers/brokers-global/base/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import segurosolStyles from '../assets/scss/brokers/segurosol/base/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import estancosStyles from '../assets/scss/brokers/estancos/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import naturalOpticsStyles from '../assets/scss/brokers/natural-optics/base.lazy.scss';
// @ts-ignore avoid false-positive error about not finding the module
import COFBStyles from '../assets/scss/brokers/cofb/base.lazy.scss';

interface StyleType {
  use: Function;
  unuse: Function;
}

interface BrandingStyleType {
  [key: string]: StyleType;
}

export enum BrandingLines {
  Zurich = 'zurich',
  Orange = 'orange',
  Brokers = 'brokers'
}

export enum BrandingOptions {
  Zurich = 'zurich',
  Orange = 'orange',
  Pimec = 'pimec',
  Asegasa = 'asegasa',
  Segurosol = 'segurosol',
  Estancos = 'estancos',
  NaturalOptics = 'naturaloptics',
  COFB = 'cofb'
}

export enum BrandingPhoneNumbers {
  Zurich = '912 73 42 80',
  Orange = '900 90 90 60',
  Estancos = '911 35 41 77',
  Asegasa = '954 65 17 11'
}

const brandingStyles: BrandingStyleType = {
  global: zurichStyles,
  orange: orangeStyles,
  pimec: pimecStyles,
  asegasa: asegasaStyles,
  brokers: brokersStyles,
  segurosol: segurosolStyles,
  estancos: estancosStyles,
  naturaloptics: naturalOpticsStyles,
  cofb: COFBStyles
};

const HOSTNAME_BRANDING_MAPPING: { [key: string]: BrandingOptions } = {
  // UAT

  // 'zurichempresas.uat.zurmsv.com': BrandingOptions.Zurich,
  'contratar-zurichempresas.uat-zurich.es': BrandingOptions.Zurich,
  'orangeseguros-zurichempresas.uat-zurich.es': BrandingOptions.Orange,
  'pimec-zurichempresas.uat-zurich.es': BrandingOptions.Pimec,
  'cmbge-zurichempresas.uat-zurich.es': BrandingOptions.Zurich,
  'segurosol-zurichempresas.uat-zurich.es': BrandingOptions.Segurosol,
  'asegasa-zurichempresas.uat-zurich.es': BrandingOptions.Asegasa,
  'estancos-zurichempresas.uat-zurich.es': BrandingOptions.Estancos,
  'naturaloptics-zurichempresas.uat-zurich.es': BrandingOptions.NaturalOptics,
  'cofb-zurichempresas.uat-zurich.es': BrandingOptions.COFB,

  // PROD
  'contratar-zurichempresas.zurich.es': BrandingOptions.Zurich,
  'contratar.zurichempresas.es': BrandingOptions.Zurich,
  'orangeseguros-zurichempresas.zurich.es': BrandingOptions.Orange,
  'orangeseguros.zurichempresas.es': BrandingOptions.Orange,
  'pimec-zurichempresas.zurich.es': BrandingOptions.Pimec,
  'pimec.zurichempresas.es': BrandingOptions.Pimec,
  'cmbge.zurichempresas.es': BrandingOptions.Zurich,
  'asegasa-zurichempresas.zurich.es': BrandingOptions.Asegasa,
  'asegasa.zurichempresas.es': BrandingOptions.Asegasa,
  'estancos.zurichempresas.es': BrandingOptions.Estancos,
  'estancos-zurichempresas.zurich.es': BrandingOptions.Estancos,
  'naturaloptics.zurichempresas.es': BrandingOptions.NaturalOptics,
  'naturaloptics-zurichempresas.zurich.es': BrandingOptions.NaturalOptics,
  'cofb.zurichempresas.es': BrandingOptions.COFB,
  'cofb-zurichempresas.zurich.es': BrandingOptions.COFB,
};

const BRANDING_LINES_MAP: {[key: string]: BrandingLines} = {
  zurich: BrandingLines.Zurich,
  orange: BrandingLines.Orange,
  pimec: BrandingLines.Brokers,
  asegasa: BrandingLines.Brokers,
  segurosol: BrandingLines.Brokers,
  estancos: BrandingLines.Brokers,
  naturaloptics: BrandingLines.Brokers,
  cofb: BrandingLines.Brokers
};

export const BASE_BRANDING = 'zurich';
let currentAppliedBranding = BrandingOptions.Zurich;

/**
 * Calculate which branding to use based on default branding and origin
 *
 * @param {string} defaultBranding
 * @export
 * @returns {string}
 */
export function calcBranding(defaultBranding: string): string {
  if (HOSTNAME_BRANDING_MAPPING[window.location.hostname]) {
    return HOSTNAME_BRANDING_MAPPING[window.location.hostname];
  }
  return defaultBranding;
}

/**
 * Get currently applied branding name
 * @returns {string}
 */
export function getCurrentAppliedBranding(): BrandingOptions {
  return currentAppliedBranding;
}

/**
 * Returns the membership of the current branding
 * @returns {BrandingLines}
 */
export function getCurrentBrandingLineMembership(): BrandingLines {
  const currentBrandingLine = BRANDING_LINES_MAP[currentAppliedBranding];

  if (currentAppliedBranding) {
    return currentBrandingLine;
  }
  return BrandingLines.Zurich;
}

/**
 * Sets up the locales for the current branding
 * @param {string} newBranding
 */
function setBrandingLocales(newBranding: string): void {
  const availableLocales = EALocaleManager.availableLocales;
  const messages = loadLocaleMessages(newBranding, availableLocales);
  
  EABrandingManager.currentBranding = newBranding;
  EALocaleManager.messages = messages;
}

/**
 * Change branding styles
 * @param {string} currentBranding
 * @param {string[]} languages 
 */
export default function changeBrandingStyles(currentBranding: BrandingOptions): void {
  let newBranding = currentBranding;
  // Check branding for hostname
  if (HOSTNAME_BRANDING_MAPPING[window.location.hostname]) {
    newBranding = HOSTNAME_BRANDING_MAPPING[window.location.hostname];
  }
  currentAppliedBranding = newBranding;

  // Disable all branding styles
  Object.values(brandingStyles).forEach((style: StyleType) => {
    style.unuse();
  });
  
  //Default styles for Zurich branding
  if (newBranding === BASE_BRANDING) {
    const styles = brandingStyles.global;
    styles.use();
    return;
  }
  
  //Styles for other brandings
  if (brandingStyles[newBranding]) {
    const styles = brandingStyles[newBranding];
    //Brokers styles
    if (getCurrentBrandingLineMembership() === BrandingLines.Brokers) {
      //Load brokers global styles
      const brokersGlobalStyles = brandingStyles[BrandingLines.Brokers];
      brokersGlobalStyles.use();
    }
    styles.use();
  }

  setBrandingLocales(newBranding);
}
