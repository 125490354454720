interface TableDocumentValue {
  CDELEMEN: string;
  DSELEMEN: string;
}

/**
 * Maps autocomplete model
 */
export class FullAddressModel {
  public fullAddress: string = '';

  public streetType: TableDocumentValue = {
    DSELEMEN: '',
    CDELEMEN: '',
  };

  // Street name
  public streetName: string = '';

  // Street number
  public streetNumber: string = '';

  // City
  public city: string = '';

  //Province
  public province: string = '';

  //Province code
  public provinceCode: string = '';

  // Postal Code
  public zipCode: string = '';

  // Floor and door
  public floorDoor?: string;
}

