import {
  CoversAccidentsStructure,
  CoversShopsStructure
} from '@/flows/flows-views/get-price/components/covers-summary-list/covers-summary-data-structure';
import CoversSummaryUtils from '@/flows/flows-views/get-price/components/covers-summary-list/covers-summary-utils';
import {
  GetPriceModel, ProductId, ReformTypes, TelephonePaymentZurichCallsDay, WindowsType
} from '@/flows/flows-views/get-price/get-price-model';
import {
  ClientTypeEnum
} from '@/flows/flows-views/get-price/views/payment/online-payment-user-data-step-view/online-payment-user-data-model';
import {
  getCurrentAppliedBranding
} from '@/locales/branding';
import {
  QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum,
  QuoteAndBuyRequestElementosObjetoTipoEdificioEnum,
  QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum,
  QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum
} from '@/services/V1/getPrice/quoteAndBuyOperation/post';
import {
  AnalyticsApi, EALocaleManager
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAAnalyticsConfiguration
} from '@zurich-es-npm/ea-front-web-core/lib/vue-app/app/ea-vue-app-parameters';
import sha256 from 'crypto-js/sha256';
import Cart, {
  CartListType, Item
} from '@/utils/cart';
import getWebIdentifier from './methods/getWebIdentifier';
import {
  FeatureControl
} from '@/helpers/feature-control/features/featureControl';
import {
  PaymentTypes,
  FeatureName
} from './enums';
import {
  Steps as FlowViewsStepsModel
} from '@/helpers/feature-control/navigation/stepsConfig';

/**
 * Check if the premises of the company are in a mall
 *
 * @param {GetPriceModel} model
 * @returns {('yes' | 'no' | undefined)}
 */
function getPremisesIsMall(model: GetPriceModel): 'yes' | 'no' | undefined {
  if (model.buildingType) {
    return model.buildingType === QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.CentroComercialAbierto ||
      model.buildingType === QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.CentroComercialCerrado
      ? 'yes'
      : 'no';
  }

  return undefined;
}

/**
 * Check if the premises of the company have a terrace
 * @param {GetPriceModel} model
 * @returns {('yes' | 'no' | undefined)}
 */
function getPremisesHasTerrace(model: GetPriceModel): 'yes' | 'no' | undefined {
  if (model.selectedFactorsDescriptions && model.selectedFactorsDescriptions.length > 0) {
    return model.selectedFactorsDescriptions.find(factor => factor === 'Terraza exterior')
      ? 'yes'
      : 'no';
  }

  return undefined;
}

/**
 * Return yes if premises is home of office home, no if it's not, undefined if it is not informed
 *
 * @param {GetPriceModel} model
 * @returns {('yes' | 'no' | undefined)}
 */
function getPremisesIsHome(model: GetPriceModel): 'yes' | 'no' | undefined {
  if (model.buildingType) {
    return model.buildingType === QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.Vivienda ||
      model.buildingType === QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.ViviendaOficina ?
      'yes' : 'no';
  }
  return undefined;
}

/**
 * Convert floor emplacement attribute for analytics
 *
 * @param {QuoteAndBuyRequestElementosObjeto.UbicacionRiesgoEnum} floorEmplacement
 * @returns {('upper floors' | 'street level' | 'basement')}
 */
export function getFloor(
  floorEmplacement: QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum
): 'upper floors' | 'street level' | 'basement' | undefined {
  switch (floorEmplacement) {
    case QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum.Altura:
      return 'upper floors';
    case QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum.PlantaBaja:
      return 'street level';
    case QuoteAndBuyRequestElementosObjetoUbicacionRiesgoEnum.Sotano:
      return 'basement';
    default:
      return undefined;
  }
}

/**
 * Convert premises type to send to analytics
 *
 * @param {QuoteAndBuyRequestElementosObjeto.TipoEdificioEnum} premisesType
 * @returns {('industrial' | 'home' | 'market' | 'office')}
 */
export function getPremisesType(
  premisesType: QuoteAndBuyRequestElementosObjetoTipoEdificioEnum
): 'industrial' | 'home' | 'market' | 'office' | undefined {
  switch (premisesType) {
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.Industrial:
      return 'industrial';
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.Vivienda:
      return 'home';
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.MercadoPublico:
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.CentroComercialAbierto:
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.CentroComercialCerrado:
      return 'market';
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.Oficina:
    case QuoteAndBuyRequestElementosObjetoTipoEdificioEnum.ViviendaOficina:
      return 'office';
    default:
      return undefined;
  }
}

/**
 * Get whether the user has renovations that wants no insure, doesn't want to insure or has no renovations
 *
 * @param {GetPriceModel} model
 * @returns {('insure' | 'no insure' | 'no renovation')}
 */
function getPremisesRenovations(model: GetPriceModel): 'insure' | 'no insure' | 'no renovation' | undefined {
  if (model.propertyReform === ReformTypes.insure) {
    return 'insure';
  } else if (model.propertyReform === ReformTypes.noInsure) {
    return 'no insure';
  } else if (model.propertyReform === ReformTypes.noRenovation) {
    return 'no renovation';
  }
  return undefined;
}

/**
 * Get parameter that the user indicates when it wants to be called
 *
 * @param {GetPriceModel} model
 * @returns {('now' | '1 day' | '2 days' | undefined)}
 */
function getUserCall(model: GetPriceModel): 'now' | '1 day' | '2 days' | undefined {
  switch (model.telephonePaymentZurichCallsDay) {
    case TelephonePaymentZurichCallsDay.AsSoonAsPossible:
      return 'now';
    case TelephonePaymentZurichCallsDay.OneWorkingDay:
      return '1 day';
    case TelephonePaymentZurichCallsDay.TwoWorkingDays:
      return '2 days';
    default:
      return undefined;
  }
}

/**
 * Convert premise parts to value required by salesforce
 *
 * @param {GetPriceModel} model
 * @returns {(string | undefined)}
 */
function parsePremisesParts(model: GetPriceModel): string | undefined {
  if (model.partInsured) {
    if (model.partInsured.value === QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum.Continente) {
      return 'property';
    } else if (model.partInsured.value === QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum.Contenido) {
      return 'furniture';
    } else if (model.partInsured.value === QuoteAndBuyRequestElementosObjetoTipoGarantiasAseguradasEnum.Ambos) {
      return 'both';
    }
  }
  return undefined;
}

/**
 * Convert windows type for analytics
 *
 * @param {WindowsType} windowsType
 * @returns {('normal' | 'two' | 'three or more' | undefined)}
 */
function getPremisesWindows(windowsType: WindowsType): 'normal' | 'two' | 'three or more' | undefined {
  switch (windowsType) {
    case WindowsType.Normal:
      return 'normal';
    case WindowsType.DoubleLayer:
      return 'two';
    case WindowsType.TripleLayer:
      return 'three or more';
    default:
      return undefined;
  }
}

/**
 * Convert current flow step to GA page title
 *
 * @param {FlowViewsStepsModel} step
 * @param {GetPriceModel} model
 * @param {object} additionalData
 * @returns {string}
 */
export function flowStepToGAPageTitle(
  step: FlowViewsStepsModel | string,
  model: GetPriceModel,
  additionalData?: {callMeBackInfo?: string}
): string {
  let currentStep: FlowViewsStepsModel | string = '';

  if (additionalData && additionalData.callMeBackInfo) {
    if (additionalData.callMeBackInfo === 'quote call') {
      currentStep = 'CALLCENTER_PHONE_EVENT';
    } else if (additionalData.callMeBackInfo === 'quote call back') {
      currentStep = model.FlowViewsSteps.TelephonePaymentZurichCalls;
    } else if (additionalData.callMeBackInfo === 'quote call confirmation') {
      currentStep = model.FlowViewsSteps.TelephonePaymentConfirmation;
    }
  } else {
    currentStep = step;
  }
  
  switch (currentStep) {
    case model.FlowViewsSteps.Welcome:
      return 'quote welcome';
    case model.FlowViewsSteps.DataCapture:
      return 'quote start';
    case model.FlowViewsSteps.AskForAdvice:
      return 'quote advise';
    case model.FlowViewsSteps.BusinessActivity:
      return 'quote business activity';
    case model.FlowViewsSteps.ProductSelection:
      return 'quote products';
    case model.FlowViewsSteps.BillingAndEmployees:
      return 'quote turnover';
    case model.FlowViewsSteps.PostalAddress:
      return 'quote premises zip code';
    case model.FlowViewsSteps.FullPostallAddress:
      return 'quote premises address';
    case model.FlowViewsSteps.BuildingDetails:
      return 'quote premises data';
    case model.FlowViewsSteps.LocalOwnership:
      return 'quote premises ownership';
    case model.FlowViewsSteps.PropertyReform:
      return 'quote premises ownership rented';
    case model.FlowViewsSteps.BuildingType:
      return 'quote premises building';
    case model.FlowViewsSteps.FloorEmplacement:
      return 'quote premises floor';
    case model.FlowViewsSteps.PartInsured:
      return 'quote premises insure';
    case model.FlowViewsSteps.Factors:
      return 'quote premises features';
    case model.FlowViewsSteps.BusinessWindows:
      return 'quote premises windows';
    case model.FlowViewsSteps.SafetyMeasures:
      return 'quote premises protection';
    case model.FlowViewsSteps.HolderAndRisksResume:
      return 'quote confirm data';
    case model.FlowViewsSteps.EmailAndPhone:
      return 'quote user data';
    case model.FlowViewsSteps.PreparingPrice:
      return 'quote calculating price';
    case model.FlowViewsSteps.SelectedProducts:
      return 'quote offer';
    case model.FlowViewsSteps.CoversSummary:
      return 'quote checkout';
    case model.FlowViewsSteps.OnlinePaymentUserData:
      return 'quote business type';
    case model.FlowViewsSteps.FinishedOnlinePayment:
      return 'quote confirmation';
    case model.FlowViewsSteps.TelephonePaymentChoose:
      return 'quote phone payment';
    case model.FlowViewsSteps.TelephonePaymentClientCalls:
      return 'quote call';
    case model.FlowViewsSteps.TelephonePaymentZurichCalls:
      return 'quote call back';
    case model.FlowViewsSteps.TelephonePaymentConfirmation:
      return 'quote call confirmation';
    case model.FlowViewsSteps.ConfirmationOfPaymentData:
      return 'quote confirm user data';
    case model.FlowViewsSteps.LocalMaxCapacity:
      return 'quote premises capacity';
    case model.FlowViewsSteps.BankingData:
      return 'quote bank data';
    case model.FlowViewsSteps.PaymentProcessing:
      return 'quote loading enigma';
    case 'CALLCENTER_PHONE_EVENT':
      return 'quote call';
    case ClientTypeEnum.Freelance:
      return 'quote self employed data';
    case ClientTypeEnum.Company:
      return 'quote company data';
    case 'START_AGAIN':
      return 'quote start over';
    case 'NO_SAFETY_MESURES':
      return 'quote premises no protection';
    case 'ERROR_PRODUCTS':
      return 'quote offer call';
    case 'ERROR_EMISSION':
      return 'quote offer revision call';
    default:
      return '';
  }
}

/**
 * Determines if the given step is before or after the products step view
 * @param {FlowViewsStepsModel | string} step
 * @returns {'after' | 'before'}
 */
export function isBeforeOrAfterProductsView(step: FlowViewsStepsModel | string): 'after' | 'before' {
  switch (step) {
    case FlowViewsStepsModel.EmailAndPhone:
    case FlowViewsStepsModel.CoversSummary:
    case FlowViewsStepsModel.OnlinePaymentUserData:
    case FlowViewsStepsModel.ConfirmationOfPaymentData:
    case FlowViewsStepsModel.FullPostallAddress:
    case FlowViewsStepsModel.BankingData:
    case FlowViewsStepsModel.PaymentProcessing:
    case FlowViewsStepsModel.FinishedOnlinePayment:
    case FlowViewsStepsModel.TelephonePaymentChoose:
    case FlowViewsStepsModel.TelephonePaymentClientCalls:
    case FlowViewsStepsModel.TelephonePaymentZurichCalls:
    case FlowViewsStepsModel.TelephonePaymentConfirmation:
    case 'Company':
    case 'Freelance':
      return 'after';
    default:
      return 'before';
  }
}

/**
 * Convert current flow step to GA page type
 * @param {FlowViewsStepsModel | string} step
 * @returns {string}
 */
function flowStepToGAPageType(step: FlowViewsStepsModel | string): 'quote and buy' | 'quick quote' | undefined {
  const stepPosition = isBeforeOrAfterProductsView(step);

  if (stepPosition === 'before') {
    return 'quick quote';
  }

  if (stepPosition === 'after') {
    return 'quote and buy';
  }
}

/**
 * Get ownership value for analytics from model
 *
 * @param {GetPriceModel} model
 * @returns {('owned' | 'rented' | undefined)}
 */
function getPremisesOwnership(model: GetPriceModel): 'owned' | 'rented' | undefined {
  if (model.ownerRent) {
    switch (model.ownerRent) {
      case QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum.Inquilino:
        return 'rented';
      case QuoteAndBuyRequestElementosObjetoPropiedadLocalEnum.Propietario:
        return 'owned';
      default:
        return undefined;
    }
  }
  return undefined;
}

/**
 * Get digital asset according to current applied branding
 *
 * @returns {('zurich empresas' | 'orange empresas')}
 */
function getDigitalAsset(): 'zurich empresas' | 'orange empresas' {
  if (getCurrentAppliedBranding() === 'orange') {
    return 'orange empresas';
  }
  return 'zurich empresas';
}

/**
 * Convert attribute user asked for advice for analytics
 *
 * @param {boolean} [askedForAdvice]
 * @returns {('yes' | 'no' | undefined)}
 */
function getUserAskedForAdvice(askedForAdvice?: boolean): 'yes' | 'no' | undefined {
  if (askedForAdvice !== undefined) {
    return askedForAdvice ? 'yes' : 'no';
  }
  return undefined;
}

/**
 * Checks the value passed and if it is a falsy value return undefined otherwise returns a string
 * @param {string | undefined} value
 * @returns {string | undefined}
 */
function isStringOrUndefined(value: string | undefined): string | undefined {
  return value ? value : undefined;
}

/**
 * Send page view event to analytics data layer
 *
 * @export
 * @param {AnalyticsApi} api
 * @param {string} step
 * @param {GetPriceModel} model
 * @param {Cart} cart
 * @param {Object} [additionalData]
 */
export function pageView(
  api: AnalyticsApi,
  step: FlowViewsStepsModel | string,
  model: GetPriceModel,
  cart: Cart,
  additionalData?: {
    coveragesTotalAmount?: string;
    coverageDate?: string;
    callMeBackInfo?: string;
  }
): void {
  if (api) {
    api.event('virtualPageview', {
      webCustomerID: getWebIdentifier(model),
      digitalAsset: getDigitalAsset(),
      funnelName: 'tarificador empresas',
      funnelStep: model.sequentialActiveStep.formattedValue,
      funnelType: flowStepToGAPageType(step),
      pageCountry: 'spain',
      pageLanguage: 'es',
      pageTitle: flowStepToGAPageTitle(step, model, additionalData),
      pageType: flowStepToGAPageType(step),
      acceptAdvice: getUserAskedForAdvice(model.userAskedForAdvice),
      businessActivity: isStringOrUndefined(model.businessActivity.activityValue),
      businessEmployees: model.employees,
      businessTurnover: model.billing,
      businessType: isStringOrUndefined(model.onlinePaymentUserData.clientType),
      insurancePremium: additionalData && isStringOrUndefined(additionalData.coveragesTotalAmount),
      insuranceQuoteDate: new Date().toISOString()
        .split('T')[0],
      insuranceStartDate: additionalData && isStringOrUndefined(additionalData.coverageDate),
      premisesArea: model.buildingArea,
      premisesFloor: model.floorEmplacement ? getFloor(model.floorEmplacement) : undefined,
      premisesIsHome: getPremisesIsHome(model),
      premisesIsMall: getPremisesIsMall(model),
      premisesIsRemote: undefined, // TODO: Which factor is that?
      premisesIsTerrace: getPremisesHasTerrace(model),
      premisesOwnership: getPremisesOwnership(model),
      premisesParts: parsePremisesParts(model),
      premisesProtectionMeasures: isStringOrUndefined(model.safetyMeasuresDescription.join(', ')),
      premisesRenovations: getPremisesRenovations(model),
      premisesRenovationsCapital: model.propertyReformValue,
      premisesTown: isStringOrUndefined(model.address.city),
      premisesType: model.buildingType ? getPremisesType(model.buildingType) : undefined,
      premisesWindows: model.windowsType ? getPremisesWindows(model.windowsType) : undefined,
      premisesYear: model.buildingYear,
      premisesZipCode: isStringOrUndefined(model.address.zipCode),
      premisesCapacity: model.maxCapacity,
      products: cart.items.length > 0 ? cart.items.map(item => item.id).join(', ') : undefined,
      productsNum: cart.items.length > 0 ? cart.items.length : undefined,
      userCall: getUserCall(model),
      userEmail: model.email ? sha256(model.email).toString() : undefined
    });
  }
}

/**
 * Send google analytics basic event
 * @param {AnalyticsApi} api 
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export function sendGAEvent(api: AnalyticsApi, category: string, action: string, label: string): void {
  if (FeatureControl.isFeatureEnabled(FeatureName.analytics) && api) {
    api.event('ga_event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  }
}

/**
 * Send enhanced ecommerce analytics event
 * @param {AnalyticsApi} api 
 * @param {string} action 
 * @param {string} label 
 * @param {Object} ecommerceData 
 * @param {string} currencyCode
 */
export function sendEnhancedECommerce(
  api: AnalyticsApi,
  action: string,
  label: string | undefined,
  ecommerceData: unknown,
  currencyCode?: string
): void {
  if (FeatureControl.isFeatureEnabled(FeatureName.analytics) && api) {
    api.event('enhanced ecommerce', {
      ecommerceCategory: 'enhanced ecommerce',
      ecommerceAction: action,
      ecommerceLabel: label,
      currencyCode: currencyCode,
      ecommerce: ecommerceData
    });
  }
}

interface EnhancedCommerceProductsDetailType {
  name: string;
  price: number;
  category: string;
  variant: 'annual' | 'biannual' | 'quarterly' | 'monthly';
  quantity: number;
  dimension9?: number; // Franquicia en %
  dimension15?: string; // Capital asegurado por la cobertura
  dimension127: string; // Insurance quote date
  dimension129?: string; // Insurance discount
  dimension142: 'included' | 'optional'; // Si el producto ya aparece al cargar la página o lo ha añadido el usuario
  dimension144: string; // Número de oferta
  dimension145?: string; // Coberturas: p.ej: Comercios:incendio,hurto,inundación
  dimension146?: 'online' | 'pending'; // Pending si el producto tiene valor 0, online si el precio es > 0
}

/**
 * Convert payment type for analytics
 *
 * @param {PaymentTypes} paymentType
 * @returns {('annual' | 'biannual' | 'quarterly' | 'monthly')}
 */
function mapPaymentTypeToVariant(paymentType?: PaymentTypes): 'annual' | 'biannual' | 'quarterly' | 'monthly' {
  switch (paymentType) {
    case PaymentTypes.Biannual:
      return 'biannual';
    case PaymentTypes.Quarterly:
      return 'quarterly';
    case PaymentTypes.Monthly:
      return 'monthly';
    case PaymentTypes.Yearly:
    default:
      return 'annual';
  }
}

/**
 * Build covers string for product for analytics
 * @param {ProductId} product
 * @param {GetPriceModel} model
 * @param {Cart} cart
 * @returns {string}
 */
function buildCoversForProduct(product: ProductId, model: GetPriceModel, cart: Cart): string {
  let result = `${product}:`;
  if (product === ProductId.Commerce) {
    const commerceCartItem = cart.getItemById(product, CartListType.Cart);
    if (commerceCartItem && commerceCartItem.policyInformation) {
      const structure = CoversSummaryUtils.mountCoversUsingDataStructure(
        commerceCartItem.policyInformation.valuesPerOption
          ? commerceCartItem.policyInformation.valuesPerOption
          : [],
        CoversShopsStructure,
        model,
        cart
      );
      for (const block of structure) {
        for (const cover of block.covers) {
          result += `${EALocaleManager.i18n.t(cover.text)}`;
        }
      }
      result = result.slice(0, result.length - 1);
    }
  } else if (product === ProductId.Accidents) {
    const accidentsCartItem = cart.getItemById(product, CartListType.Cart);
    if (accidentsCartItem && accidentsCartItem.policyInformation) {
      const structure = CoversSummaryUtils.mountCoversUsingDataStructure(
        accidentsCartItem.policyInformation.valuesPerOption
          ? accidentsCartItem.policyInformation.valuesPerOption
          : [],
        CoversAccidentsStructure,
        model,
        cart
      );
      for (const block of structure) {
        for (const cover of block.covers) {
          result += `${EALocaleManager.i18n.t(cover.text)}`;
        }
      }
      result = result.slice(0, result.length - 1);
    }
  }
  return result;
}

/**
 * Concatenates the reform values, if there are two reform values present it will concatenate them.
 * If there is only one, it will return only one
 * @param {number} containerCapital
 * @param {number} contentCapital
 * @returns {string}
 */
function concatCapitalValues(containerCapital: number, contentCapital: number): string {
  const capitalValuesList: [number, number] = [containerCapital, contentCapital];
  return capitalValuesList.join('_');
}

/**
 * Generates a enhanced ecommerce product details object, based on the given coverage
 * @param {GetPriceModel} model
 * @param {Cart} cart
 * @param {Item} item
 * @returns {EnhancedCommerceProductsDetailType}
 */
function generateEnhancedEcommerceProductDetails(
  model: GetPriceModel,
  cart: Cart,
  item: Item
  // Coverage: SelectedCoverage
): EnhancedCommerceProductsDetailType {
  const {
    id,
    name,
    policyInformation
  } = item;
  const pricing = policyInformation && policyInformation.pricing;
  const policyCode = policyInformation && policyInformation.policyCode;
  const capitalAmounts = cart.ensuredCapitals;
  const totalPrice = pricing && pricing.totalReceiptAmount;
  const effectiveDate = cart.coverageDate;

  return {
    name,
    price: (pricing && pricing.totalReceiptAmount) || 0,
    category: model.businessActivity.activityValue,
    variant: mapPaymentTypeToVariant(model.selectedDiscountOption.value),
    quantity: 1,
    dimension9: undefined, // TODO: Refactor cuando se añada RC con el porcentaje de la franquicia 
    dimension15:
      concatCapitalValues(
        capitalAmounts.continent || 0,
        capitalAmounts.content || 0
      ),
    dimension127: effectiveDate || new Date().toISOString()
      .split('T')[0],
    dimension129: model.selectedDiscountOption.discountAmount || '0',
    dimension142: id === ProductId.Commerce || id === ProductId.Accidents
      ? 'included' : 'optional', // TODO: Refactor when new products are added
    dimension144: policyCode || '',
    dimension145: buildCoversForProduct(id, model, cart),
    dimension146: totalPrice === undefined || totalPrice <= 0 ? 'pending' : 'online'
  };
}

/**
 * Get product details for enhanced products event
 *
 * @export
 * @param {GetPriceModel} model
 * @param {Cart} cart
 * @param {ProductId} id
 * @returns {EnhancedCommerceProductsDetailType[]}
 */
export function enhancedCommerceProductsDetail(
  model: GetPriceModel,
  cart: Cart,
  id?: ProductId
): EnhancedCommerceProductsDetailType[] {
  const result: EnhancedCommerceProductsDetailType[] = [];

  if (id) {
    const cartItem = cart.getItemById(id, CartListType.Cart);
    if (cartItem) {
      result.push(generateEnhancedEcommerceProductDetails(model, cart, cartItem));
    }
  } else {
    const cartItems = cart.items;
    for (const item of cartItems) {
      result.push(generateEnhancedEcommerceProductDetails(model, cart, item));
    }
  }

  return result;
}

/**
 * Check which analytics configuration to use based on branding
 *
 * @export
 * @param {*} parameters
 * @param {string} branding
 * @returns {EAAnalyticsConfiguration}
 */
export function parseGTMConfigByBranding(parameters: any, branding: string): EAAnalyticsConfiguration {
  if (branding !== 'zurich' && Object.prototype.hasOwnProperty.call(parameters, `${branding}Analytics`)) {
    return parameters[`${branding}Analytics`] as EAAnalyticsConfiguration;
  }
  return parameters.analytics as EAAnalyticsConfiguration;
}
