import {
  QuoteAndBuyResponse,
} from '@/services/V1/getPrice/quoteAndBuyOperation/post';
import {
  GetPriceModel,
  ProductId
} from '@/flows/flows-views/get-price/get-price-model';
import Cart, {
  ItemConfig,
  ValuesPerOption
} from '@/utils/cart';
import moment from 'moment';
import {
  QuoteAndBuyMultiProductResponse
} from '../interfaces';

import getCoverageInitialDefaultDateOffset from '@/helpers/feature-control/methods/getCoverageInitialDefaultDateOffset';
import {
  ZeDateTypes
} from '../enums';
import {
  PolicyHolderStatements,
  PolicyHolderStatementClause
} from '@/utils/interfaces/policies';
import {
  FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum
} from '@/services/V1/getPrice/flowInfoOperation/post';

export enum PolicyHolderStatementsExtraInformation {
  Independent = 'independent',
  ForceShow = 'forceShow'
}

/**
 * Parses the policy holder statements
 * @param {object} policyHolderStatements
 * @returns {PolicyHolderStatements}
 */
export function parsePolicyHolderStatements(
  policyHolderStatements: { codigoElemento?: string; descripcionElemento?: PolicyHolderStatementsExtraInformation; }[]):
  PolicyHolderStatements {
  const clauses: PolicyHolderStatementClause[] = policyHolderStatements?.map(clause => {
    const {
      codigoElemento,
      descripcionElemento
    } = clause;

    return {
      id: codigoElemento ?? '',
      statement: false,
      forceShow: descripcionElemento === PolicyHolderStatementsExtraInformation.ForceShow,
      isAnsweredIndependently:
        (descripcionElemento && descripcionElemento === PolicyHolderStatementsExtraInformation.Independent) ?? false
    };
  }) ?? [];

  return {
    clauses
  };
}

/**
 * Returns the base data for updating the current cart item
 * @param {T} quoteResult
 * @param {Cart} cart
 * @param {GetPriceModel} model
 * @returns {object}
 */
function getBaseUpdateConfig<
  T extends QuoteAndBuyMultiProductResponse
>(
  quoteResult: T,
  cart: Cart,
  model?: GetPriceModel
): { [key: string]: unknown } {
  const {
    codigoPoliza,
    datosEconomicos,
    gestionDeCompetencias,
    datosDocumentacion
  } = quoteResult;

  let formaPago;
  let importePrimerRecibo;
  let importeRecibosSucesivos;
  let importeTotalRecibo;
  let primaTecnica;
  let declaracionesTomador;

  if (datosEconomicos) {
    const {
      resultadosTarificacion: quoteEconomicResults,
      declaracionesTomador: policyHolderStatements
    } = datosEconomicos as any;

    const {
      formaPago: paymentType,
      importePrimerRecibo: firstReceipt,
      importeRecibosSucesivos: succesiveReceipts,
      importeTotalRecibo: total,
      primaTecnica: techPrime
    } = quoteEconomicResults;

    formaPago = paymentType;
    importePrimerRecibo = firstReceipt;
    importeRecibosSucesivos = succesiveReceipts;
    importeTotalRecibo = total;
    primaTecnica = techPrime;
    declaracionesTomador = policyHolderStatements;
  }


  const paymentRecurrentType = formaPago || '';
  const firstReceiptAmount = importePrimerRecibo || 0;
  const recurrentReceiptsAmount = importeRecibosSucesivos || 0;
  const totalReceiptAmount = importeTotalRecibo || 0;
  const techincalPrime = primaTecnica || 0;

  const initialDateOffset = getCoverageInitialDefaultDateOffset();
  const defaultEffectiveDate = moment()
    .add(!!initialDateOffset && initialDateOffset >= 2 ? initialDateOffset - 2 : initialDateOffset, ZeDateTypes.Days)
    .format('DD/MM/YYYY');

  const effectiveDate = cart.coverageDate || defaultEffectiveDate;

  const valuePerOptionList = datosEconomicos?.valoresPorOpcion as any;
  const valuesPerOption: ValuesPerOption[] =
    (valuePerOptionList
      && valuePerOptionList.length > 0
      && valuePerOptionList.map((option: any) => {
        return {
          codeMCT: option.codigoMCT,
          elementsData: option.datosElemento
        };
      })) || [];

  let policyHolderStatements: PolicyHolderStatements | undefined;

  if (
    declaracionesTomador
    && model?.quoteOperationCode === FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum.T1
  ) {
    policyHolderStatements = parsePolicyHolderStatements(declaracionesTomador) ?? undefined;
  }

  return {
    policyInformation: {
      policyCode: codigoPoliza,
      pricing: {
        paymentRecurrentType,
        firstReceiptAmount,
        recurrentReceiptsAmount,
        totalReceiptAmount,
        techincalPrime
      },
      valuesPerOption,
      policyHolderStatements,
      documentation: datosDocumentacion,
      competenceManagement: !!gestionDeCompetencias
    },
    additionalInformation: {
      effectiveDate,
    }
  };
}

/**
 * 
 * @param {T} quoteResult
 * @param {Cart} cart
 * @returns {object}
 */
function getNonDiscountQuoteResults<
  T extends QuoteAndBuyMultiProductResponse
>(
  quoteResult: T,
): { [key: string]: unknown } {
  const commerceQuoteResult = quoteResult as QuoteAndBuyResponse;
  const nonDiscountResults = commerceQuoteResult.resultadosTarificacionSinDescuento;
  if (nonDiscountResults) {
    const nonDiscountInformation = {
      nonDiscountPolicyCode: nonDiscountResults.codigoPoliza,
      nonDiscountFirstReceiptAmount: nonDiscountResults.importePrimerRecibo,
      nonDiscountRecurrentReceiptsAmount: nonDiscountResults.importeRecibosSucesivos,
      nonDiscountTechincalPrime: nonDiscountResults.primaTecnica,
      nonDiscountTotalReceiptAmount: nonDiscountResults.importeTotalRecibo,
      nonDiscountPaymentRecurrentType: nonDiscountResults.formaPago,
    };

    return {
      nonDiscountPricing: nonDiscountInformation
    };
  }

  return {};
}

/**
 * 
 * @param {any} quoteError
 * @param {any} quoteWarning
 * @returns {object}
 */
function getErrorsAndWarningsInResults(
  quoteError: any,
  quoteWarning: any
): { [key: string]: unknown } {
  const warning = quoteWarning
    ? {
      code: quoteWarning.codigoAdvertencia as string,
      severity: quoteWarning.severidadAdvertencia as number,
      childErrors: quoteWarning.childErrors
    }
    : undefined;
  const error = quoteError
    ? {
      code: quoteError.code as string,
      severity: quoteError.severity as number,
      childErrors: quoteError.childErrors
    }
    : undefined;

  return {
    warning,
    error
  };
}

/**
 * Prepares the cart item after a succesfull quoting
 * @param {object} quoteResult
 * @param {ProductId} id
 * @param {Cart} cart
 * @param {GetPriceModel} model
 * @returns {void}
 */
export function prepareCartItemData<
  T extends QuoteAndBuyMultiProductResponse
>(
  quoteResult: T,
  id: ProductId,
  cart: Cart,
  model?: GetPriceModel
): void {
  const cartListPresence = cart.isItemPresentInAnyList(id);
  const quoteWarning = quoteResult && quoteResult.advertencia;
  const quoteError = quoteResult?.errors?.[0];

  const updateConfig: ItemConfig = {
    id,
    ...getBaseUpdateConfig(quoteResult, cart, model)
  };

  if ('resultadosTarificacionSinDescuento' in quoteResult) {
    updateConfig.policyInformation = {
      ...updateConfig.policyInformation,
      ...getNonDiscountQuoteResults(quoteResult)
    };
  }

  if (quoteWarning || quoteError) {
    updateConfig.policyInformation = {
      ...updateConfig.policyInformation,
      ...getErrorsAndWarningsInResults(quoteError, quoteWarning)
    };
  }

  if (cartListPresence) {
    cart.update(updateConfig, cartListPresence);
  }
}
