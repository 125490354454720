/* eslint-disable */

/**
 * zurich-empresas
 * Web cotización para empresas
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface FlowInfoRequest
 */
export interface FlowInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    partnerBranding?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    identificadorUsuario?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    emailContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    telefonoContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    pasoFlujo: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    nombreTitular?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    codigoActividad?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    estadoActividadUsuario?: FlowInfoRequestEstadoActividadUsuarioEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FlowInfoRequest
     */
    autorizacionLegal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    nombreComercial?: string;
    /**
     * 
     * @type {FlowInfoRequestRangoTiempoLlamada}
     * @memberof FlowInfoRequest
     */
    rangoTiempoLlamada?: FlowInfoRequestRangoTiempoLlamada;
    /**
     * 
     * @type {Array<FlowInfoRequestProductos>}
     * @memberof FlowInfoRequest
     */
    productos?: Array<FlowInfoRequestProductos>;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequest
     */
    facturacionAnual?: number;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequest
     */
    numeroEmpleados?: string;
    /**
     * 
     * @type {FlowInfoRequestTomador}
     * @memberof FlowInfoRequest
     */
    tomador?: FlowInfoRequestTomador;
}

/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestEstadoActividadUsuarioEnum {
    Completado = 'Completado',
    Telefonico = 'Telefonico',
    Callmeback = 'Callmeback',
    Activo = 'Activo',
    Abandono = 'Abandono'
}
/**
 * 
 * @export
 * @interface FlowInfoRequestEmision
 */
export interface FlowInfoRequestEmision {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestEmision
     */
    codigoPoliza?: string;
}/**
 * 
 * @export
 * @interface FlowInfoRequestInformacionRiesgo
 */
export interface FlowInfoRequestInformacionRiesgo {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    tipoViaSituacionDeRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    calleSituacionDeRiesgo?: string;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    numeroDireccionSituacionDeRiesgo?: number;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    informacionAdicionalDireccionSituacionDeRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    codigoPostalRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    localidadRiesgo?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    provinciaSituacionDeRiesgo?: string;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    anoConstruccion?: number;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    propiedadLocal?: FlowInfoRequestInformacionRiesgoPropiedadLocalEnum;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    tipoContinente?: FlowInfoRequestInformacionRiesgoTipoContinenteEnum;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    tipoEdificio?: FlowInfoRequestInformacionRiesgoTipoEdificioEnum;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    ubicacionRiesgo?: FlowInfoRequestInformacionRiesgoUbicacionRiesgoEnum;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    superficieLocal?: number;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    aforo?: number;
    /**
     * 
     * @type {Array<FlowInfoRequestInformacionRiesgoMedidasProteccion>}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    medidasProteccion?: Array<FlowInfoRequestInformacionRiesgoMedidasProteccion>;
    /**
     * 
     * @type {Array<FlowInfoRequestInformacionRiesgoFactoresRiesgo>}
     * @memberof FlowInfoRequestInformacionRiesgo
     */
    factoresRiesgo?: Array<FlowInfoRequestInformacionRiesgoFactoresRiesgo>;
}

/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestInformacionRiesgoPropiedadLocalEnum {
    Propietario = 'Propietario',
    Inquilino = 'Inquilino'
}
/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestInformacionRiesgoTipoContinenteEnum {
    Immueble = 'Immueble',
    Obras = 'Obras'
}
/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestInformacionRiesgoTipoEdificioEnum {
    Industrial = 'Industrial',
    Vivienda = 'Vivienda',
    MercadoPublico = 'MercadoPublico',
    Oficina = 'Oficina',
    ViviendaOficina = 'ViviendaOficina',
    CentroComercialCerrado = 'CentroComercialCerrado',
    CentroComercialAbierto = 'CentroComercialAbierto',
    CentroComercial = 'CentroComercial',
    Quiosco = 'Quiosco'
}
/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestInformacionRiesgoUbicacionRiesgoEnum {
    Altura = 'Altura',
    PlantaBaja = 'PlantaBaja',
    Sotano = 'Sotano'
}
/**
 * 
 * @export
 * @interface FlowInfoRequestInformacionRiesgoFactoresRiesgo
 */
export interface FlowInfoRequestInformacionRiesgoFactoresRiesgo {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgoFactoresRiesgo
     */
    numeroFactor?: string;
}/**
 * 
 * @export
 * @interface FlowInfoRequestInformacionRiesgoMedidasProteccion
 */
export interface FlowInfoRequestInformacionRiesgoMedidasProteccion {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestInformacionRiesgoMedidasProteccion
     */
    nombreMedidaProteccion?: string;
}/**
 * 
 * @export
 * @interface FlowInfoRequestProductos
 */
export interface FlowInfoRequestProductos {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {FlowInfoRequestEmision}
     * @memberof FlowInfoRequestProductos
     */
    emision?: FlowInfoRequestEmision;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    codigoOperacionQuoteAndBuy?: FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    tipoProducto?: FlowInfoRequestProductosTipoProductoEnum;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    formaPago?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    formaPagoSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    tipoCanalCobro1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    tipoCanalCobroSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestProductos
     */
    fechaEmision?: string;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestProductos
     */
    capitalContinentePoliza?: number;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestProductos
     */
    capitalContenidoPoliza?: number;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestProductos
     */
    primaTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestProductos
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestProductos
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof FlowInfoRequestProductos
     */
    limiteResponsabilidadCivil?: number;
    /**
     * 
     * @type {FlowInfoRequestInformacionRiesgo}
     * @memberof FlowInfoRequestProductos
     */
    informacionRiesgo?: FlowInfoRequestInformacionRiesgo;
}

/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestProductosCodigoOperacionQuoteAndBuyEnum {
    T1 = 'T1',
    T2 = 'T2',
    PI = 'PI'
}
/**
    * @export
    * @enum {string}
    */
export enum FlowInfoRequestProductosTipoProductoEnum {
    Comercios = 'Comercios',
    RC = 'RC',
    RCProfesional = 'RCProfesional',
    Accidentes = 'Accidentes',
    RCDO = 'RCD&O',
    Cyber = 'Cyber'
}
/**
 * 
 * @export
 * @interface FlowInfoRequestRangoTiempoLlamada
 */
export interface FlowInfoRequestRangoTiempoLlamada {
    /**
     * 
     * @type {Date}
     * @memberof FlowInfoRequestRangoTiempoLlamada
     */
    primeraFechaRangoLlamada?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FlowInfoRequestRangoTiempoLlamada
     */
    ultimaFechaRangoLlamada?: Date;
}/**
 * 
 * @export
 * @interface FlowInfoRequestTomador
 */
export interface FlowInfoRequestTomador {
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestTomador
     */
    nombreTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestTomador
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestTomador
     */
    emailContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowInfoRequestTomador
     */
    telefonoTomador?: string;
}/**
 * 
 * @export
 * @interface FlowInfoResponse
 */
export interface FlowInfoResponse {
    /**
     * 
     * @type {Array<Error>}
     * @memberof FlowInfoResponse
     */
    errors?: Array<Error>;
}

type flowInfoOperationParams = {
  flowInfoRequest: FlowInfoRequest,
};

/**
 * FlowInfoApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const FlowInfoApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Update CRM on the state of the information introduced by the user in the quote&buy flow
     * @param {FlowInfoRequest} flowInfoRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    flowInfoOperation(params: flowInfoOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'flowInfoRequest' is not null or undefined
      if (params.flowInfoRequest === null || params.flowInfoRequest === undefined) {
        throw new RequiredError('flowInfoRequest', 'Required parameter flowInfoRequest was null or undefined when calling flowInfoOperation.');
      }
      const localVarPath = `/V1/getPrice/flowInfoOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('FlowInfoRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.flowInfoRequest || {}) : params.flowInfoRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FlowInfoApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const FlowInfoApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Update CRM on the state of the information introduced by the user in the quote&buy flow
     * @param {FlowInfoRequest} flowInfoRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    flowInfoOperation(params: flowInfoOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowInfoResponse> {
      const localVarAxiosArgs = FlowInfoApiAxiosParamCreator(configuration).flowInfoOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FlowInfoApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const FlowInfoApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Update CRM on the state of the information introduced by the user in the quote&buy flow
     * @param {FlowInfoRequest} flowInfoRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    flowInfoOperation(params: flowInfoOperationParams, options?: any): any {
      return FlowInfoApiFp(configuration).flowInfoOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * FlowInfoApi - object-oriented interface
 * @export
 * @class FlowInfoApi
 * @extends {BaseAPI}
 */
export class FlowInfoApi extends BaseAPI {
  
  /**
   * Update CRM on the state of the information introduced by the user in the quote&buy flow
   * @param {FlowInfoRequest} flowInfoRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof FlowInfoApi
   */
  public flowInfoOperation(params: flowInfoOperationParams, options?: any): any {
    return FlowInfoApiFp(this.configuration).flowInfoOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAflowInfoOperationInputMessage
 */
export class EAflowInfoOperationInputMessage {
  
  /**
   * 
   * @type {FlowInfoRequest}
   * @memberof EAflowInfoOperationInputMessage
   */
  public flowInfoRequest: FlowInfoRequest;

  public constructor(flowInfoRequest: FlowInfoRequest, ){
  
  this.flowInfoRequest=flowInfoRequest;
  } 
}



/** 
 * EAFlowInfoApi - Architecture client for flowInfoOperation
 * @export
 * @class EAFlowInfoApi
 */
export class EAFlowInfoApi {

  /**
   * 
   * @param {FlowInfoRequest} flowInfoRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<FlowInfoResponse | null>}
  */ 
  public async flowInfoOperation(params: flowInfoOperationParams): Promise<FlowInfoResponse | null> {
    return this.flowInfoOperationPromise(params);
  }

  /**
   * 
   * @param {FlowInfoRequest} flowInfoRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async flowInfoOperationSteps(params: flowInfoOperationParams, callback: (res: AxiosResponse<FlowInfoResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/V1/getPrice/flowInfoOperation', 'flowInfo', 'flowInfoOperation');
    const config: Configuration = {};
    const api = new FlowInfoApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.flowInfoOperation(params, { headers }) as AxiosResponse<FlowInfoResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {FlowInfoRequest} flowInfoRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<FlowInfoResponse | null>}
   */
  public async flowInfoOperationPromise(params: flowInfoOperationParams): Promise<FlowInfoResponse | null> {
    return new Promise((resolve, reject) => {
      this.flowInfoOperationSteps(params, (response: AxiosResponse<FlowInfoResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


