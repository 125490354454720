import merge from 'lodash/merge';
import {
  EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  getCurrentBrandingLineMembership,
  BrandingLines
} from './branding';

interface LocaleMessages {
  [key: string]: object;
}

const DEFAULT_BRANDING = 'zurich';

/**
 * Get branding locales
 * @param {string} branding
 * @param {string[]} supportedLocales
 * @return {*} devuelve todos las traduciones del showcase
 */
export default function loadLocaleMessages(branding: string, supportedLocales: string[]): object {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  supportedLocales.forEach(async locale => {
    try {
      const defaultLocaleKey = `./${locale}/${DEFAULT_BRANDING}.json`;
      let localeMessages = locales(defaultLocaleKey);

      if (branding !== DEFAULT_BRANDING) {
        const brandingLocaleKey = `./${locale}/${branding}.json`;
        const currentLineMembership = getCurrentBrandingLineMembership();
        //Map of base brand lines
        const BASE_BRAND_LINES_LIST: string[] = [BrandingLines.Brokers];

        /*
         * If the current branding is member of a branding line that has a base translation file
         * merge the branding line file with the branding file
         */
        if (BASE_BRAND_LINES_LIST.some(baseBrandingLine => baseBrandingLine === currentLineMembership)) {
          const brandingBaseLineKey = `./${locale}/${currentLineMembership}.json`;
          const baseLocaleMessage = merge({}, localeMessages, locales(brandingBaseLineKey));
          localeMessages = merge({}, baseLocaleMessage, locales(brandingLocaleKey));
        } else {
          localeMessages = merge({}, localeMessages, locales(brandingLocaleKey));
        }
      }
      messages[locale] = localeMessages;
    } catch (err) {

      // FIXME: 'Error get locales and branding locales - i18n loadLocaleMessages'
      throw new EAApplicationError('ARC00001');
    }
  });
  return messages;
}
