import {
  IdDocumentTypeCodes
} from '@/utils/enums';

const DNI_REGEX = /^(\d{8})([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;


export enum IdTypeEnum {
  DNI = 'dni',
  CIF = 'cif',
  NIE = 'nie'
}

export const idTypesGenericValuesMap = {
  dni: IdDocumentTypeCodes.DNI,
  cif: IdDocumentTypeCodes.CIF,
  nie: IdDocumentTypeCodes.NIE
};
export type IdTypesGenericValuesMap = typeof idTypesGenericValuesMap;

interface IdValidationResultModel {
  isValid: boolean;
  type?: string;
}

/**
 * Sanitize string value
 * @param {string} value
 * @returns {string}
 */
function sanitize(value: string): string {
  return value
    .toUpperCase()
    .replace(/\s/g, '')
    .replace(/-/g, '');
}

/**
 * Obtains the nie prefix numeric value
 * @param {string} prefix
 * @returns {string}
 */
function obtainNieNumericPrefix(prefix: string): string {
  if (prefix === 'X') {
    return '0';
  } else if (prefix === 'Y') {
    return '1';
  } else if (prefix === 'Z') {
    return '2';
  } else {
    return '';
  }
}

/**
 * Determines the type of the id passed
 * @param {string} value
 * @returns {IdTypeEnum | null}
 */
function getIdType(value: string): IdTypeEnum | undefined {
  if (value.match(DNI_REGEX)) {
    return IdTypeEnum.DNI;
  } else if (value.match(CIF_REGEX)) {
    return IdTypeEnum.CIF;
  } else if (value.match(NIE_REGEX)) {
    return IdTypeEnum.NIE;
  } else {
    return undefined;
  }
}

/**
 * Determines if a string is a valid dni
 * @param {string} value
 * @returns {boolean}
 */
function isValidDNI(value: string): boolean {
  const dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const letter = dniLetters.charAt(parseInt(value, 10) % 23);
  return letter === value.charAt(8);
}

/**
 * Determines if a string is a valid nie
 * @param {string} value
 * @returns {boolean}
 */
function isValidNie(value: string): boolean {
  const niePrefix = value.charAt(0);
  const nieNumericPrefix = obtainNieNumericPrefix(niePrefix);
  const valueWithNumericValue = nieNumericPrefix + value.substring(1);
  return isValidDNI(valueWithNumericValue);
}

/**
 * Calculates the digits of a cif
 * @param {string} digits
 * @returns {object}
 */
function calculateDigits(digits: string): {digitResult: number; sumResult: number} {
  return digits.split('').reduce((accumulated, current, index) => {
    let digit = parseInt(current);
    let sum = 0;

    if (index % 2 === 0) {
      digit *= 2;

      if (digit > 9) {
        digit = Math.floor(digit / 10) + (digit % 10);
      }

      sum += digit;
    } else {
      sum += digit;
    }

    accumulated.digitResult = digit;
    accumulated.sumResult += sum;

    return accumulated;
  },
  {
    digitResult: 0,
    sumResult: 0
  } as unknown as {digitResult: number; sumResult: number});
}

/**
 * Determines if a string is a valid cif
 * @param {string} value
 * @returns {boolean}
 */
function isValidCif(value: string): boolean {
  if (value.length === 9) {
    const letterPatternRange = /[A-Z]/;
    const letterSpecificMatch1 = /[BEH]/;
    const letterSpecificMatch2 = /[NPQRSW]/;
    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = value.substring(1, value.length - 1);
    const letter = value.substring(0, 1);
    const control = value.substring(value.length - 1);
    const areDigitsValid = !digits.split('').some(digit => isNaN(parseInt(digit)));

    if (!letter.match(letterPatternRange) || !areDigitsValid) {
      return false;
    }

    let {
      digitResult,
      sumResult
    } = calculateDigits(digits);

    sumResult %= 10;

    if (sumResult === 0) {
      digitResult = sumResult;
    } else {
      digitResult = 10 - sumResult;
    }

    if (letter.match(letterSpecificMatch1)) {
      return String(digitResult) === control;
    } else if (letter.match(letterSpecificMatch2)) {
      return letters[digitResult] === control;
    } else {
      return String(digitResult) === control || letters[digitResult] === control;
    }
  }
  return false;
}

/**
 * Validates an id and determines if it is valid, can also return it's type if the argument is passed
 * @param {string} value
 * @param {boolean} getType
 * @returns {boolean}
 */
export function validateId(value: string, getType?: boolean): IdValidationResultModel {
  const result: IdValidationResultModel = {
    isValid: false
  };
  const idValue = sanitize(value);
  const idType = getIdType(idValue);

  if (getType) {
    result.type = idType;
  }

  if (idType === IdTypeEnum.DNI) {
    result.isValid = isValidDNI(idValue);
  } else if (idType === IdTypeEnum.NIE) {
    result.isValid = isValidNie(idValue);
  } else if (idType === IdTypeEnum.CIF) {
    result.isValid = isValidCif(idValue);
  } else {
    result.isValid = false;
    result.type = 'N/A';
  }

  return result;
}
