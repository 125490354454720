import {
  Product,
  ProductOptions,
  CurrentProductStatus,
  ConditionalOption
} from '@/utils/interfaces';
import {
  ProductOptionConditionType
} from '@/utils/enums';

/**
 * Processes a product option condition, then returns a boolean that represents if the condition is met
 * @param {ProductOptions} currentProductSettingsOption
 * @param {CurrentProductStatus} productStatus
 * @returns {boolean}
 */
function processProductOptionCondition(
  currentProductSettingsOption: ConditionalOption,
  productStatus: CurrentProductStatus,
): boolean {
  const {
    activityCode,
    options: productStatusOptions
  } = productStatus;
  const accumulatedConditionsResults: boolean[] = [];
  const productsConditionsList = currentProductSettingsOption.condition || [];
  let result = false;

  /*
   * If the current options have conditions declared, then proceed to process the conditions
   * Obtain the condition of the option that needs to be processed
   */
  for (const currentOptionCondition of productsConditionsList) {
    const {
      conditionType,
      activityCode: conditionActivityCode,
      isConditionNegation
    } = currentOptionCondition;
    const currentStatus = conditionType === ProductOptionConditionType.Default || !!productStatusOptions[conditionType];
    const isCurrentActivity = !!(
      !conditionActivityCode
      || (conditionActivityCode && conditionActivityCode === activityCode)
    );
    const joinedConditions = currentStatus && isCurrentActivity;
    const currentConditionResult = isConditionNegation ? !joinedConditions : joinedConditions;

    accumulatedConditionsResults.push(currentConditionResult);
  }

  if (accumulatedConditionsResults.length > 0) {
    result = accumulatedConditionsResults.every(conditionResult => conditionResult);
  }

  return result;
}

/**
 * Determines if the current product conditions are met. Can also determine if an specific condition is met
 * @param {Product[]} productsSettings
 * @param {CurrentProductStatus} productStatus
 * @param {string} productOption
 * @returns {boolean}
 */
export default function isCurrentProductConditionsMet(
  productsSettings: (Product | undefined)[],
  productStatus: CurrentProductStatus,
  productOption: keyof ProductOptions,
): boolean {
  //Get the current products settings
  const currentProductSettings = productsSettings
    .find(product => product && product.code === productStatus.productCode);
  
  if (currentProductSettings) {
    const {
      options: currentProductOptions
    } = currentProductSettings;

    //Get the wanted product option from the product settings
    if (currentProductOptions) {
      const currentProductSettingsOption = currentProductOptions[productOption];

      //If it is boolean or undefined, just return the boolean value of the product settings option
      if (currentProductSettingsOption) {
        if (typeof currentProductSettingsOption === 'boolean') {
          return currentProductSettingsOption;
        } else if (Object.keys(currentProductOptions).length > 0 && 'condition' in currentProductSettingsOption) {
          return processProductOptionCondition(
            currentProductSettingsOption,
            productStatus,
          );
        }
      }
    }
  }

  return false;
}
