import {
  steps, StepTransition
} from './stepsConfig';


const asegasaNavigationTransitions: StepTransition<typeof steps>[] = [
  {
    name: 'AsegasaFullForm',
    from: 'init',
    to: 'PreparingPrice'
  },
  //Preparing price step
  {
    name: 'PreparingPrice',
    from: 'AsegasaFullForm',
    to: 'SelectedProducts'
  },
  //Selected products Step
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'OnlinePaymentUserData',
    option: 1,
  },
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'OnlinePaymentUserData',
    option: 2,
  },
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'OnlinePaymentUserData',
    option: 3,
  },
  {
    name: 'SelectedProducts',
    from: 'PreparingPrice',
    to: 'PolicyholderStatements',
    option: 4,
  },
  //Policyholder Statements
  {
    name: 'PolicyholderStatements',
    from: 'SelectedProducts',
    to: 'OnlinePaymentUserData',
    option: 1
  },
  {
    name: 'PolicyholderStatements',
    from: 'SelectedProducts',
    to: 'SelectedProducts',
    option: 2
  },
  //User Payment Data Step
  {
    name: 'OnlinePaymentUserData',
    from: 'PolicyholderStatements',
    to: 'PaymentProcessing',
    option: 1
  },
  {
    name: 'OnlinePaymentUserData',
    from: 'OnlinePaymentUserData',
    to: 'SelectedProducts',
    option: 2
  },
  //Payment Processing Step
  {
    name: 'PaymentProcessing',
    from: 'BankingData',
    to: 'FinishedOnlinePayment',
    option: 1
  },
  {
    name: 'PaymentProcessing',
    from: 'BankingData',
    to: 'OnlinePaymentUserData',
    option: 2
  },
  //Telephone Payment Choose Step
  {
    name: 'TelephonePaymentChoose',
    from: 'CoversSummary',
    to: 'TelephonePaymentClientCalls',
    option: 1
  },
  {
    name: 'TelephonePaymentChoose',
    from: 'CoversSummary',
    to: 'TelephonePaymentZurichCalls',
    option: 2
  },
  {
    name: 'TelephonePaymentChoose',
    from: 'CoversSummary',
    to: 'CoversSummary',
    option: 3
  },
  //Telephone Payment Client Calls Step
  {
    name: 'TelephonePaymentClientCalls',
    from: 'TelephonePaymentChoose',
    to: 'TelephonePaymentChoose'
  },
  //Telephone Payment Zurich calls Step
  {
    name: 'TelephonePaymentZurichCalls',
    from: 'TelephonePaymentChoose',
    to: 'TelephonePaymentConfirmation',
    option: 1
  },
  {
    name: 'TelephonePaymentZurichCalls',
    from: 'TelephonePaymentChoose',
    to: 'previous',
    option: 2
  },

  /*
   * ////////////////////////////
   * Components
   * ////////////////////////////
   */
  {
    name: 'CallmebackComponent',
    from: '*',
    to: 'TelephonePaymentZurichCalls'
  }
];

export default asegasaNavigationTransitions;
